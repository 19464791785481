import React, { Component } from "react";
import {
  Button,
  Grid,
  Header,
  Icon,
  Pagination,
  Segment,
  Sidebar,
} from "semantic-ui-react";
import queryString from "query-string";
import SearchFacets from "./SearchFacets";
import SearchResults from "./SearchResults";
import MetaTags from "../SEO/MetaTags";
import SearchPlaceholder from "./SearchPlaceholder";
import GlobalBreadcrumb from "../BreadcrumbHeader/GlobalBreadcrumb";

import { connect } from "react-redux";
import { search, checkFacets } from "../../actions/searchActions";
import { linkToSearch } from "../../utils/InternalLinks";
import { staticTimeFilters, staticMediaFilters } from "./SearchFilterLabels";
import get from "lodash/get";
import { StripHtmlTags } from "../../utils/HelperFunctions";
import { isHomepageDisabled } from "../../utils/EnvironmentFunctions"
import sanitizeHtml from 'sanitize-html';

import "./search.scss";
export class SearchPage extends Component {
  constructor(props) {
    super(props);

    let params = queryString.parse(this.props.location.search);

    this.state = {
      searchTerm: this.clean(params.searchTerm),
      activePage: 1,
      pageLength: 20,
      urlParams: params,
      visibleDrawer: false,
    };

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  searchTitle(searchTerm) {
    const siteName = this.props.siteName;
    if (searchTerm !== undefined
        && searchTerm !== '') {
      searchTerm = StripHtmlTags(searchTerm);
      return `${siteName} Search for ${searchTerm}`;
    }
    return `${siteName}`;
  }

  searchDescription(searchTerm) {
    const siteName = this.props.siteName;
    if (searchTerm !== undefined
        && searchTerm !== '') {
      return `Search for ${searchTerm} on ${siteName}`;
    }
    return `Search ${siteName} for resources on your entrepreneurial journey`;
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);

    this.props.dispatch(checkFacets(params));
    this.fetchData();
  }

  handleToggle() {
    this.setState({ visibleDrawer: !this.state.visibleDrawer });
  }

  clean(text) {
    return text !== undefined && text.length > 0 ? text.trim() : "";
  }

  hasSearchChanged(params) {
    const { urlParams } = this.state;
    return !(
      urlParams &&
      urlParams.searchTerm === params.searchTerm &&
      JSON.stringify(urlParams.media_types) ===
        JSON.stringify(params.media_types) &&
      JSON.stringify(urlParams.time) === JSON.stringify(params.time)
    );
  }

  componentDidUpdate() {
    let params = queryString.parse(this.props.location.search);

    if (params && this.hasSearchChanged(params)) {
      this.setState(
        { urlParams: params, searchTerm: this.clean(params.searchTerm) },
        this.fetchData
      );
      this.props.dispatch(checkFacets(params));
    }
  }

  fetchData() {
    const { searchTerm, media_types, time } = get(this.state, "urlParams", {});

    const searchTermObj = searchTerm ? { searchTerm: searchTerm } : {};
    const mediaTypesObj = media_types
      ? { media_types: this.parseArrayParam(media_types) }
      : {};
    const timeObj = time ? { time: this.parseArrayParam(time) } : {};

    this.props.dispatch(
      search({
        ...searchTermObj,
        ...mediaTypesObj,
        ...timeObj,
        per_page: this.state.pageLength,
        page: this.state.activePage,
      })
    );
  }

  parseArrayParam(param) {
    return Array.isArray(param) ? param.join(",") : param;
  }

  getPaginationCount() {
    const pagination = get(this.props, "searchMetadata.pagination", null);
    return pagination ? `(${pagination.total_count})` : "";
  }

  searchText() {
    const { searchTerm } = this.state;
    const totalCount = get(
      this.props,
      "searchMetadata.pagination.total_count",
      0
    );

    if (this.props.searchLoading) {
      return "Searching...";
    }
    if (searchTerm !== undefined && searchTerm !== "") {
      if (totalCount > 0) {
        return totalCount === 1
          ? `1 Search Result for "${searchTerm}"`
          : `${totalCount} Search Results for "${searchTerm}"`;
      }
      return `No Search Results were found for "${sanitizeHtml(searchTerm)}"`;
    }
    return `All Results ${this.getPaginationCount()}`;
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ activePage }, () => {
      this.fetchData();
      window.scrollTo(0, 0);
    });
  }

  getPagination() {
    const pagination = get(this.props, "searchMetadata.pagination", {});
    const totalPages = get(pagination, "total_pages", 0);
    const currentPage = get(pagination, "current_page", 0);

    if (totalPages > 1) {
      return (
        <Pagination
          boundaryRange={0}
          ellipsisItem={null}
          prevItem={null}
          nextItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={this.handlePaginationChange}
          activePage={currentPage}
          siblingRange={1}
          totalPages={totalPages}
        />
      );
    }
  }

  filterButton(isMobile, visibleDrawer) {
    const filtersVisibleClass = visibleDrawer ? "filters-visible" : "";
    if (isMobile) {
      return (
        <Button
          icon="sliders horizontal"
          type="submit"
          id="add_filters"
          primary={!visibleDrawer}
          className={filtersVisibleClass}
          onClick={this.handleToggle}
        />
      );
    } else {
      return (
        <Button
          icon
          labelPosition="left"
          type="submit"
          id="add_filters"
          primary={!visibleDrawer}
          onClick={this.handleToggle}
          className={filtersVisibleClass}
        >
          <Icon name="sliders" />
          <span>Add Filters</span>
        </Button>
      );
    }
  }

  removeFilter(propName, propValue) {
    return () => {
      var newParams = Object.assign({}, this.state.urlParams);
      if (newParams[propName].forEach) {
        newParams[propName] = newParams[propName].filter(
          (x) => x !== propValue
        );
      } else {
        delete newParams[propName];
      }

      let query = {
        searchTerm: newParams.searchTerm,
        media_types: newParams.media_types,
        time: newParams.time,
        subject: newParams.subjectFilter,
      };
      this.props.history.push("/search?" + queryString.stringify(query));
    };
  }

  removeFilterByKey(prop, value) {
    return (e) => {
      if (e.keyCode === 32 || e.keyCode === 13) {
        this.removeFilter(prop, value);
      }
    };
  }

  makeFilterButton(prop, value) {
    const filters =
      prop === "media_types" ? staticMediaFilters() : staticTimeFilters;
    const filter = filters.find((x) => x.key === value);
    const text = filter ? filter.text : value;
    return (
      <Button
        key={prop + "-" + value}
        className="applied-filter"
        onClick={this.removeFilter(prop, value)}
        onKeyDown={this.removeFilterByKey(prop, value)}
      >
        {text}
        <Icon link name="close" size="small" />
      </Button>
    );
  }

  getAppliedFilters(params) {
    const keys = Object.keys(params);
    const filters = keys.filter((prop) => prop !== "searchTerm");
    var buttons = [];
    filters.forEach((prop) => {
      if (params[prop].forEach) {
        params[prop].forEach((value) => {
          buttons.push(this.makeFilterButton(prop, value));
        });
      } else {
        buttons.push(this.makeFilterButton(prop, params[prop]));
      }
    });
    return buttons;
  }

  showFiltersRowIf(filters, width) {
    if (filters.length === 0) {
      return null;
    }
    return (
      <Grid.Row centered className="search-page-header">
        <Grid.Column width={width} className="filters-column">
          <div id="applied-filters-container">{filters}</div>
        </Grid.Column>
      </Grid.Row>
    );
  }

  showBreadcrumb() {
    const { isMobile } = this.props;

    if (isHomepageDisabled() === true) {
      return (
        <div className="breadcrumb-row">
          <GlobalBreadcrumb
            pathName={"Search"}
            isMobile={isMobile}
          />
        </div>
      )
    }
  }

  render() {
    const { visibleDrawer, searchTerm, urlParams } = this.state;
    const { isMobile } = this.props;
    const width = isMobile ? 16 : 12;
    const filters = this.getAppliedFilters(urlParams);
    const filterButton = this.filterButton(isMobile, visibleDrawer)

    return (
      <div className="search-page-parent">
        <MetaTags
          metaTitle={this.searchTitle(searchTerm)}
          metaDescription={this.searchDescription(searchTerm)}
          canonicalUrl={`${process.env.REACT_APP_SITE_ROOT}${linkToSearch()}`}
        />
        <Grid className="search-page">
          <Grid.Row columns={2} centered className="search-page-header">
            <Grid.Column floated="left" width={11}>
              { this.showBreadcrumb() }
              <Header as="h1" className="search-header-text">{this.searchText()}</Header>
            </Grid.Column>
            <Grid.Column floated="right" width={3} textAlign="right">
              {filterButton}
            </Grid.Column>
          </Grid.Row>
          {this.showFiltersRowIf(filters, width)}
          {this.props.searchLoading ? (
            <Grid.Row
              centered
              id="search-results"
              tabIndex="-1"
              className="search-results"
            >
              <Grid.Column width={width}>
                <SearchPlaceholder count={3} />
              </Grid.Column>
            </Grid.Row>
          ) : (
            [
              <Grid.Row
                centered
                id="search-results"
                className="search-results"
                key="search-results"
              >
                <Grid.Column width={width}>
                  <Sidebar.Pushable>
                    <Sidebar
                      as={Segment}
                      animation="overlay"
                      direction="top"
                      visible={visibleDrawer}
                    >
                      <SearchFacets
                        urlParams={urlParams}
                        handleToggle={this.handleToggle}
                      />
                    </Sidebar>
                    <Sidebar.Pusher>
                      <SearchResults
                        isMobile={isMobile}
                        searchResults={this.props.searchResults}
                      />
                    </Sidebar.Pusher>
                  </Sidebar.Pushable>
                </Grid.Column>
              </Grid.Row>,
              <Grid.Row centered key="search-pagination">
                {this.getPagination()}
              </Grid.Row>,
            ]
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    searchResults: store.search.searchResults,
    searchMetadata: store.search.searchMetadata,
    searchLoading: store.search.searchLoading,
    isMobile: store.global.isMobile,
    siteName: store.global.siteName
  };
};

export default connect(mapStateToProps)(SearchPage);

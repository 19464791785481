function defaultState() {
  return {
    replies: [],
    reply_count: 0,
    discussionErrors: {},
    statusCodes: {},
    repliesLoading: false,
    posting: false,
    content_type: null,
    content_id: null,
    ...defaultFlagModalState
  };
}

const defaultFlagModalState = {
  flagModalOpen: false,
  flagDiscussionError: null,
  flagDiscussionResponse: {},
  flagDiscussionSuccess: false
};

export default function reducer(state = defaultState(), action) {
  switch (action.type) {
    case "RESET":
      return defaultState();
    case "GET_DISCUSSION_PENDING":
      return {
        ...state,
        replies: [],
        reply_count: 0,
        discussionErrors: {},
        statusCodes: {},
        repliesLoading: true,
        content_type: null,
        content_id: null
      };
    case "GET_DISCUSSION_FAILURE":
      return {
        ...state,
        replies: [],
        reply_count: 0,
        discussionErrors: {},
        statusCodes: {},
        repliesLoading: false,
        content_type: action.meta.content_type,
        content_id: action.meta.content_id
      };
    case "GET_DISCUSSION_FULFILLED":
      return {
        ...state,
        replies: action.payload.data.replies,
        reply_count: action.payload.data.post_count,
        discussionErrors: {},
        statusCodes: {},
        repliesLoading: false,
        content_type: action.meta.content_type,
        content_id: action.meta.content_id
      };
    case "POST_DISCUSSION_PENDING":
      return {
        ...state,
        discussionErrors: {},
        statusCodes: {},
        posting: true,
        content_type: null,
        content_id: null
      };
    case "FLAG_DISCUSSION_PENDING":
      return {
        ...state,
        flagDiscussionError: null,
        flagDiscussionResponse: {},
        flagDiscussionSuccess: false
      };
    case "FLAG_DISCUSSION_REJECTED":
      return {
        ...state,
        flagDiscussionError: action.payload.response,
        flagDiscussionResponse: {},
        flagDiscussionSuccess: false
      };
    case "FLAG_DISCUSSION_FULFILLED":
      return {
        ...state,
        flagDiscussionError: null,
        flagDiscussionResponse: action.payload.response,
        flagDiscussionSuccess: true
      };
    case "POST_DISCUSSION_REJECTED":
      return {
        ...state,
        discussionErrors: {
          ...state.discussionErrors,
          [action.payload.config.params.id]: action.payload.response.data.errors
        },
        statusCodes: {
          ...state.statusCodes,
          [action.payload.config.params.id]: action.payload.response.status
        },
        posting: false,
        content_type: action.meta.content_type,
        content_id: action.meta.content_id
      };
    case "POST_DISCUSSION_FAILURE":
      return {
        ...state,
        discussionErrors: {
          ...state.discussionErrors,
          [action.payload.config.params.id]: action.payload.response.data.errors
        },
        statusCodes: {
          ...state.statusCodes,
          [action.payload.config.params.id]: action.payload.response.status
        },
        posting: false,
        content_type: action.meta.content_type,
        content_id: action.meta.content_id
      };
    case "POST_DISCUSSION_FULFILLED":
      return {
        ...state,
        replies: action.payload.data.replies,
        reply_count: action.payload.data.post_count,
        discussionErrors: {
          ...state.discussionErrors,
          [action.payload.config.params.id]: null
        },
        statusCodes: {
          ...state.statusCodes,
          [action.payload.config.params.id]: 200
        },
        posting: false,
        content_type: action.meta.content_type,
        content_id: action.meta.content_id
      };

    case "OPEN_FLAG_MODAL":
      return {
        ...state,
        flagModalOpen: true,
        content_type: action.content_type,
        content_id: action.content_id
      };
    case "CLOSE_FLAG_MODAL":
      return {
        ...state,
        ...defaultFlagModalState
      };
    default:
      break;
  }
  return state;
}

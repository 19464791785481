import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Dropdown, Grid, Icon } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';
import NavigationJourneyIntro from "./SubMenu/NavigationJourneyIntro";
import NavigationLearningObjective from "./SubMenu/NavigationLearningObjective";
import { connect } from "react-redux";
import {
  ImagePlaceholder,
  DescriptionPlaceholder,
  getCustomData,
  getJourneyName,
  getTranslatedTimeLabel,
} from "../../../../utils/HelperFunctions";

import {
  resetMainNav,
  setPathNavActiveIndex,
} from "../../../../actions/accessibilityActions";

import {
  getJourneys,
  getProgressForJourneys,
} from "../../../../actions/journeyActions";
import "../../Navbar.scss";

export class NavigationJourney extends Component {
  constructor(props) {
    super(props);

    this.gridRefs = {};
    this.activeTopicRef = React.createRef();
    this.activeJourneyRef = React.createRef();
  }

  componentDidMount() {
    this.props.getJourneys();
    if (this.props.isUserLoggedIn) {
      this.props.getProgressForJourneys();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUserLoggedIn !== prevProps.isUserLoggedIn) {
      this.props.getProgressForJourneys();
      this.props.getJourneys();
    }

    const { mainNav } = this.props;
    const prevMainNav = prevProps.mainNav;

    if (mainNav.activeTopicId !== prevMainNav.activeTopicId) {
      if (mainNav.activeTopicId) {
        this.keyboardNavScrollToTopic(mainNav);
      } else {
        this.focusCurrentJourney();
      }
    }

    if (mainNav.activePathId !== prevMainNav.activePathId) {
      this.focusCurrentJourney();
    }
  }

  focusCurrentJourney() {
    if (this.activeJourneyRef && this.activeJourneyRef.current) {
      this.activeJourneyRef.current.focus();
    }
  }

  // This is to handle the scrolling of the inner topic nav menu when we navigate with the keyboard
  keyboardNavScrollToTopic(mainNav) {
    // This is the active .navigation-journey-item-menu grid ref which has the scroll bar
    const activeGridRef = this.gridRefs[mainNav.activePathId]
      ? this.gridRefs[mainNav.activePathId].current
      : null;

    // This is the active .navbar-topic-item we have passed the ref down to to tell us where we need to scroll to
    const activeTopicRef = this.activeTopicRef
      ? this.activeTopicRef.current
      : null;

    if (activeGridRef) {
      const gridElement = ReactDOM.findDOMNode(activeGridRef);
      if (activeTopicRef) {
        const topicRect = ReactDOM.findDOMNode(
          activeTopicRef
        ).getBoundingClientRect();

        // We set the scrollTop to the height times the active topic index
        gridElement.scrollTop = topicRect.height * mainNav.activeTopicIndex;
      } else {
        // If we have no active topic we set the inner nav scrollTop to 0
        gridElement.scrollTop = 0;
      }
    }
  }

  getTotalExcursions(excursions) {
    if (excursions) {
      const { t } = this.props;
      const term = t('excursion');

      return `${excursions.length} ${
        excursions.length === 1 ? term : term + "s"
      }`;
    } else {
      return "0 " + term + "s";
    }
  }

  getJourneyImage(journey) {
    return getCustomData(journey, "thumbnail_url", ImagePlaceholder());
  }

  getJourneyImageAltText(journey) {
    return getCustomData(journey, "thumbnail_url_alt", "");
  }

  getIntroText(journey) {
    return getCustomData(journey, "description", DescriptionPlaceholder());
  }

  getJourneyTimeLength(journey) {
    const { t } = this.props;
    return getTranslatedTimeLabel(journey, t);
  }

  dropdownItemIconName(dropdownOpen) {
    const isMobile = this.props.isMobile;

    if (isMobile) {
      return dropdownOpen ? "caret up" : "caret down";
    } else {
      return "caret right";
    }
  }

  dropdownItemClick(event, selected, index, journeyId) {
    event.preventDefault();
    event.stopPropagation();

    if (selected) {
      this.props.resetMainNav();
    } else {
      this.props.setActiveJourney(index, journeyId);
    }
  }

  dropdownItemContent(journey, selected) {
    const overrideClass = this.dropdownStyleOverride();

    return (
      <Dropdown.Menu
        className={`navigation-journey-dropdown-menu ${overrideClass}`}
        open={selected}
      >
        {this.journeyInfo(journey)}
      </Dropdown.Menu>
    );
  }

  dropdownItem(journey, selected, index) {
    const iconName = this.dropdownItemIconName(selected);

    return (
      <div
        className={`ui fluid dropdown navigation-journey`}
        aria-label={`${getJourneyName(journey)} Sub menu opens right`}
        ref={selected ? this.activeJourneyRef : null}
        tabIndex={-1}
        aria-haspopup={true}
        aria-expanded={selected}
        onMouseDown={(e) => this.dropDownMouseDown(e, this.props.isMobile)}
        onClick={(e) => {
          this.dropdownItemClick(e, selected, index, journey.id);
        }}
      >
        <div className="text">{getJourneyName(journey)}</div>
        <Icon name={iconName} />
        {this.dropdownItemContent(journey, selected)}
      </div>
    );
  }

  dropDownMouseDown(e, isMobile) {
    // The dropdown is closing on mouseUp events outside of the initial clicked element
    // We can stop propagation on mouseDown to prevent this behavior
    if (isMobile) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  // Detecting if the browser is safari to override a problem style to hotfix journey inner nav
  // not displaying on safari properly
  dropdownStyleOverride() {
    const isSafari = navigator.userAgent.indexOf("Safari") !== -1;

    if (isSafari) {
      return "override-position";
    } else {
      return "";
    }
  }

  // [Line 141] Difficulty getting a ref to the semantic component, used a div with semantic classes to workaround
  // [Line 144] Added inline style on the div for issues with IE11 and the order it was applying styles
  journeyInfo(journey) {
    this.gridRefs[journey.id] = React.createRef();

    return this.props.isMobile ? (
      <span>
        <NavigationLearningObjective
          journeyId={journey.id}
          excursions={journey.excursions}
        />
      </span>
    ) : (
      <div
        className="ui grid stackable navigation-journey-item-menu"
        ref={this.gridRefs[journey.id]}
        style={{ display: "inline-block", margin: "0" }}
        aria-label={`${getJourneyName(journey)} detail and excursions`}
      >
        <NavigationJourneyIntro
          id={journey.id}
          name={getJourneyName(journey)}
          image={this.getJourneyImage(journey)}
          imageAltText={this.getJourneyImageAltText(journey)}
          topicsNumber={this.getTotalExcursions(journey.excursions)}
          journeyTimeLength={this.getJourneyTimeLength(journey)}
          introText={this.getIntroText(journey)}
          aria-label={this.getIntroText(journey)}
        />
        <Grid.Row className="navigation-topics-section">
          <NavigationLearningObjective
            journeyId={journey.id}
            topicRef={this.activeTopicRef}
            excursions={journey.excursions}
          />
        </Grid.Row>
      </div>
    );
  }

  render() {
    const journeys = this.props.journeys || [];
    const { activePathNavIndex } = this.props.mainNav;

    return journeys.map((journey, index) => {
      const selected =
        journeys[activePathNavIndex] &&
        journeys[activePathNavIndex].id === journey.id;

      const selectedClass = selected ? " selected" : "";

      const journeyKey = `journey-item-${journey.id}`;
      return (
        <div
          key={journeyKey}
          className={`item journey-item${selectedClass}`}
          aria-selected={selected}
          role={"option"}
        >
          {this.dropdownItem(journey, selected, index)}
        </div>
      );
    });
  }
}

const mapStateToProps = (store) => {
  return {
    journeys: store.journey.journeys,
    isUserLoggedIn: store.login.isUserLoggedIn,
    mainNav: store.accessibility.mainNav,
    isMobile: store.global.isMobile,
    excursionTerm: store.json.excursionTerm
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJourneys: () => {
      dispatch(getJourneys());
    },
    getProgressForJourneys: () => {
      dispatch(getProgressForJourneys());
    },
    resetMainNav: () => {
      dispatch(resetMainNav());
    },
    setActiveJourney: (index, id) => {
      dispatch(setPathNavActiveIndex(index, id));
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NavigationJourney));

import React, {Component} from 'react'
import { connect } from "react-redux"
import {Grid, Header } from "semantic-ui-react";
import { ImagePlaceholder } from "../../utils/HelperFunctions";
import DynamicImage from "../HelperComponents/DynamicImage";
import { Link } from "react-router-dom";

export class SearchResults extends Component {

  constructUri(id, metadataObject) {
    if (metadataObject.permalink) {
     return (process.env.REACT_APP_REQUIRE_USER_TO_VIEW_CONTENT === "false" || this.props.isUserLoggedIn)
        ? metadataObject.permalink : '/login';
    }

    if (id && metadataObject) {
      let uri = '/'
      if (metadataObject.journey_id) {
        uri += metadataObject.journey_id
        if (metadataObject.learning_objective_id) {
          uri += `/` + metadataObject.learning_objective_id
          if (metadataObject.learning_event_id) {
            uri += `/` + metadataObject.learning_event_id
          }
        }
      }
      return uri
    }
    return ''
  }

  getSearchAssetType(asset_type) {
    if(process.env.REACT_APP_SITE_NAME === "Ascent"){
      return asset_type;
    } else {
      switch(asset_type) {
        case "Journey":
          return this.props.journeyTerm;
        case "Excursion":
          return this.props.excursionTerm;
        case "Path":
          return this.props.pathTerm;
        default:
          return asset_type;
      }
    }
  }

  searchResultImage(sr) {
    return(
      <Grid.Column width={2}>
        <Link to={this.constructUri(sr.id, sr.meta_data)}>
          <DynamicImage
            className="search-result-image"
            src={ImagePlaceholder(sr.thumbnail_url)}
            title={sr.name}
            alt={sr.thumbnail_url_alt_text}
            size="small"
          />
        </Link>
      </Grid.Column>
    )
  }

  render() {
    return this.props.searchResults.map((sr, index) => (
        <Grid stackable className="search-result-item" id={'search-result-' + index} key={'search-result-' + index}>
          <Grid.Row columns={this.props.isMobile ? 2 : 1} className="search-result-inner">
            { this.props.isMobile? "" : this.searchResultImage(sr) }
            <Grid.Column width={this.props.isMobile ? 16: 13}>
              <div className="search-result-content">
                <Header size="medium" className="search-result-title">
                  <a href={this.constructUri(sr.id, sr.meta_data)}>
                    {sr.name || this.constructUri(sr.id, sr.meta_data)}
                  </a>
                </Header>
                <span className="search-result-type">{this.getSearchAssetType(sr.content_type)}</span>
                <div tabIndex="0" className="search-result-description">{sr.description}</div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
    ));
  }
}

const mapStateToProps = store => {
  return {
    journeyTerm: store.json.journeyTerm,
    excursionTerm: store.json.excursionTerm,
    pathTerm: store.json.pathTerm,
    isUserLoggedIn: store.login.isUserLoggedIn
  };
};

export default connect(mapStateToProps)(SearchResults);

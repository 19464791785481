import { userClient, contentClient } from "../utils/axiosClients";

export function getJourney(id) {
  const url = `/journeys/${id}`;

  return (dispatch) => {
    return dispatch({
      type: "GET_JOURNEY",
      payload: contentClient().get(url),
    });
  };
}

export function getJourneys() {
  const url = "/journeys";
  return (dispatch) => {
    dispatch({
      type: "GET_JOURNEYS",
      payload: contentClient().get(url),
    });
  };
}

export function getProgressForJourney(id) {
  const url = `/journeys/${id}/progress`;

  return (dispatch, getState) => {
    dispatch({
      type: "GET_PROGRESS_FOR_JOURNEY",
      payload: userClient(getState()).get(url),
    });
  };
}

export function getProgressForJourneys() {
  const url = "/journeys/progress";

  return (dispatch, getState) => {
    dispatch({
      type: "GET_PROGRESS_FOR_JOURNEYS",
      payload: userClient(getState()).get(url),
    });
  };
}

export function getLatestUserProgress() {
  return (dispatch, getState) => {
    if (getState().login.isUserLoggedIn) {
      const url = "progress/latest";
      dispatch({
        type: "GET_LATEST_USER_PROGRESS",
        payload: userClient(getState()).get(url),
      }).then((res) => {
        if (res.value.data["journey"] === undefined) {
          return dispatch({ type: "USER_HAS_NOT_STARTED_JOURNEY" });
        } else {
          return dispatch({ type: "USER_HAS_STARTED_JOURNEY" });
        }
      });
    }
  };
}

function defaultState() {
  return {
    metadata: {},
    blocks: []
  }
}

export default function reducer(state = defaultState(), action) {
  switch (action.type) {
    case "GET_HOMEPAGE_CONTENT_FULFILLED":
      return {
        ...state,
        ...action.payload.data
      };
    default:
      break;
  }

  return state;
}
import { userClient, contentClient } from "../utils/axiosClients";

import { getProgressForExcursions } from "./excursionActions";
import { getProgressForJourney } from "./journeyActions";
import { isPathComplete } from "../utils/PathProgress";

export function getLearningEvent(course_id, module_id, event_id) {
  const url = `/paths/${event_id}`;

  return (dispatch) => {
    return dispatch({
      type: "GET_LEARNING_EVENT",
      payload: contentClient().get(url, {
        params: {
          course_id,
          module_id,
        },
      }),
    });
  };
}

export function getLearningEventsIfNeeded(course_id, module_id) {
  return (dispatch, getState) => {
    if (shouldGetLearningEvents(course_id, module_id, getState())) {
      return dispatch(getLearningEvents(course_id, module_id));
    } else {
      return Promise.resolve();
    }
  };
}

function shouldGetLearningEvents(course_id, module_id, state) {
  const learningEventsObj =
    state.learningEvent.learningEventsCollection[course_id];

  return !(learningEventsObj && learningEventsObj[module_id]);
}

export function getLearningEvents(journeyId, excursionId) {
  const url = `/excursions/${excursionId}`;

  return (dispatch) => {
    return dispatch({
      type: "GET_LEARNING_EVENTS",
      meta: {
        journeyId,
        excursionId,
      },
      payload: contentClient().get(url),
    });
  };
}

export function getFirstLearningEvent(course_id, module_id) {
  return (dispatch, getState) => {
    const response = dispatch(getLearningEventsIfNeeded(course_id, module_id));

    response.then(() => {
      const learningEvents = eventsFromCollectionForTopic(
        getState(),
        course_id,
        module_id
      );

      if (learningEvents[0]) {
        dispatch(getLearningEvent(course_id, module_id, learningEvents[0].id));
      }
    });
  };
}

function eventsFromCollectionForTopic(state, course_id, module_id) {
  const eventCollection = state.learningEvent.learningEventsCollection;
  return eventCollection[course_id]
    ? eventCollection[course_id][module_id]
    : [];
}

export function completeLearningEvent(journeyId, excursionId, pathId) {
  return (dispatch, getState) => {
    const pathProgress = getState().learningEvent.progress;
    const isComplete = isPathComplete(journeyId, pathProgress);

    if (!isComplete) {
      const url = `journeys/${journeyId}/excursions/${excursionId}/paths/${pathId}/complete`;

      return dispatch({
        type: "COMPLETE_LEARNING_EVENT",
        meta: { pathId: journeyId, objectiveId: excursionId },
        payload: userClient(getState()).get(url),
      }).then(() => {
        dispatch(getProgressForJourney(journeyId));
        dispatch(getProgressForExcursions(journeyId));
        dispatch(getProgressForPaths(journeyId, excursionId));
      });
    }
  };
}

export function visitLearningEvent(journeyId, excursionId, pathId) {
  return (dispatch, getState) => {
    if (getState().login.isUserLoggedIn) {
      const url = `journeys/${journeyId}/excursions/${excursionId}/paths/${pathId}/visit`;

      return dispatch({
        type: "VISIT_LEARNING_EVENT",
        meta: { pathId: journeyId, objectiveId: excursionId },
        payload: userClient(getState()).get(url),
      });
    } else {
      Promise.resolve();
    }
  };
}

export function setMediaTranscriptType(type) {
  return {
    type: "SET_TRANSCRIPT_CONTENT_TYPE",
    transcriptType: type,
  };
}

export function submitQuiz(event_id, quiz) {
  return (dispatch, getState) => {
    const url = `/learning_events/${event_id}/quizzes`;

    dispatch({
      type: "SUBMIT_QUIZ",
      payload: userClient(getState()).post(url, quiz),
    });
  };
}

export function clearQuizSubmission() {
  return {
    type: "CLEAR_SUBMIT_QUIZ",
  };
}

export function getProgressForPaths(journeyId, excursionId) {
  return (dispatch, getState) => {
    const url = `journeys/${journeyId}/excursions/${excursionId}/path_progress`;

    dispatch({
      type: "GET_PROGRESS_FOR_PATHS",
      payload: userClient(getState()).get(url),
    });
  };
}

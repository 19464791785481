import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import PathRegister from "./PathRegister";
import PathProgressBar from "./PathProgressBar";
import PathCompleted from "./PathCompleted";
import { PropTypes } from "prop-types";
import "./PathProgress.scss";

export default class PathProgress extends Component {
  renderForSessionState() {
    const { isUserLoggedIn } = this.props;
    const requireUser =
      process.env.REACT_APP_REQUIRE_USER_TO_TRACK_PROGRESS !== "false";

    const allowProgress = isUserLoggedIn || !requireUser;

    if (allowProgress) {
      const { journeyLoading, pathsComplete, pathsTotal } = this.props;

      const pathCountValidation =
        !Number.isInteger(pathsComplete) && !Number.isInteger(pathsTotal);
      if (journeyLoading || pathCountValidation) {
        return null;
      } else if (pathsComplete === pathsTotal) {
        return <PathCompleted />;
      } else {
        return (
          <PathProgressBar
            pathsComplete={pathsComplete}
            pathsTotal={pathsTotal}
            pathsProgressPercent={this.progressPercentage(
              pathsComplete,
              pathsTotal
            )}
          />
        );
      }
    } else {
      return <PathRegister isMobile={this.props.isMobile} />;
    }
  }

  progressPercentage(complete, total) {
    return (complete / total) * 100;
  }

  render() {
    const rowContent = this.renderForSessionState();
    if (rowContent) {
      return (
        <Grid.Row className="path-progress">
          {this.renderForSessionState()}
        </Grid.Row>
      );
    } else {
      return null;
    }
  }
}

PathProgress.propTypes = {
  pathsComplete: PropTypes.number,
  pathsTotal: PropTypes.number,
  isUserLoggedIn: PropTypes.bool,
};

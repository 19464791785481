import React, { Component } from "react";
import { Item } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getLearningEvents } from "../../actions/learningEventActions";
import { getCustomData, rewriteUrlWithCDN, getTranslation } from "../../utils/HelperFunctions";
import CheckmarkCompleted from '../CheckmarkCompleted/CheckmarkCompleted';
import { isPathComplete } from '../../utils/PathProgress';
import { withTranslation } from 'react-i18next';

export class PathList extends Component {
  componentDidMount() {
    this.eventsList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUserLoggedIn !== prevProps.isUserLoggedIn) {
      this.eventsList();
    }
  }

  eventsList() {
    this.props.dispatch(
      getLearningEvents(this.props.course_id, this.props.module_id)
    );
  }

  eventThumbnail(imageUrl, event) {
    return (
      <Item.Image
        src={rewriteUrlWithCDN(imageUrl)}
        alt={getCustomData(event, "thumbnail_url_alt", "")}
        size="tiny"
      />
    );
  }

  pathTypeClass(type) {
    return type
      ? type
          .trim()
          .toLowerCase()
          .replace(" ", "-")
      : "";
  }

  showThumbnailImage(pathType) {
    const typesToHide = ["Key Takeaway"];

    return !typesToHide.find(type => type === pathType);
  }

  renderEventsList(events = []) {
    const { course_id, module_id, event_id, t } = this.props;
    const url = `/${course_id}/${module_id}/`;

    if (events.length) {
      return events.map((event, index) => {
        const eventType = getCustomData(event, "event_type", event.type);
        const imageUrl = getCustomData(event, "thumbnail_url", "");
        const isComplete = isPathComplete(event.id, this.props.pathProgress);
        const activeEventClass =
          event_id && event_id.toString() === event.id.toString()
            ? " active-event"
            : "";
        const pathTypeClass = this.pathTypeClass(eventType);

        return (
          <Item
            key={"eventListItem" + index}
            className={`${pathTypeClass} event-list-accordion-item ${activeEventClass}`}
            as={Link}
            to={url + event.id}
          >
            {this.showThumbnailImage(eventType)
              ? this.eventThumbnail(imageUrl, event)
              : null}
            <Item.Content className="item-content">
              <Item.Description>{getTranslation(event, "title")}</Item.Description>
              <Item.Extra>{ t('eventTypes.' + eventType ) }</Item.Extra>
            </Item.Content>
            {isComplete && <CheckmarkCompleted/>}
          </Item>
        );
      });
    } else {
      return null;
    }
  }

  render() {
    const { module_id, course_id } = this.props;
    const learningEvents = this.props.learningEventsCollection[course_id]
      ? this.props.learningEventsCollection[course_id][module_id]
      : [];

    return (
      <Item.Group divided>{this.renderEventsList(learningEvents)}</Item.Group>
    );
  }
}

const mapStateToProps = store => {
  return {
    learningEventsCollection: store.learningEvent.learningEventsCollection,
    pathProgress: store.learningEvent.progress,
    isUserLoggedIn: store.login.isUserLoggedIn
  };
};

export default withTranslation('translations')(withRouter(connect(mapStateToProps)(PathList)));

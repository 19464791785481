import { combineReducers } from "redux";

import aboutModal from "./aboutModalReducer";
import journey from "./journeyReducer";
import navbar from "./navbarReducer";
import registration from "./registrationReducer";
import learningEvent from "./learningEventReducer";
import login from "./loginReducer";
import userProfile from "./profileReducer";
import search from "./searchReducer";
import discussion from "./discussionReducer";
import accessibility from "./accessibilityReducer";
import global from "./globalReducer";
import excursion from "./excursionReducer";
import touring from "./touringReducer";
import homepage from "./homepageReducer";
import demographic from "./demographicReducer";
import json from "./jsonReducer";

export default combineReducers({
  aboutModal,
  journey,
  navbar,
  registration,
  learningEvent,
  login,
  userProfile,
  search,
  discussion,
  accessibility,
  global,
  excursion,
  touring,
  homepage,
  demographic,
  json
});

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (
      rest.isUserLoggedIn
        ? <Component {...props} />
        : <Redirect to="/" />
    )} />
  )
};

const mapStateToProps = store => {
  return {
    isUserLoggedIn: store.login.isUserLoggedIn,
  }
}

export default connect(mapStateToProps)(PrivateRoute);

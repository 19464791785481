import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button, Header, Menu } from "semantic-ui-react";
import NavbarSearchInput from "./NavbarSearchInput";
import { withTranslation } from 'react-i18next';
import NavigationUserDropdown from "./NavigationUserDropdown";
import RegistrationModal from "../RegistrationModal/RegistrationModal";
import {
  toggleRegister,
  toggleLogin,
  toggleResetPassword,
  toggleConfirmation,
  toggleProfileConfirmation,
  toggleForgotPassword
} from "../../actions/navbarActions";
import {
  toggleDemographics
} from "../../actions/demographicActions";
import { toggleLoginModal } from "../../actions/loginActions";

import { helpdeskLink } from "../../utils/ExternalLinks";
import { truncate, rewriteUrlWithCDN } from "../../utils/HelperFunctions";
import { isTranslationEnabled } from "../../utils/EnvironmentFunctions";
import { logoutUserAndReset } from "../../actions/registrationActions";
import { toggleTouring } from "../../actions/touringActions";

import "./Navbar.scss";
import NavbarDropdown from "./Dropdown/NavbarDropdown";
import TouringModal from "../Touring/TouringModal";
import NavigationHelpDropdown from "./NavigationHelpDropdown";
import EmailConfirmationForm from "../EmailConfirmationForm/EmailConfirmationForm";
import SkipLinks from "./SkipLinks/SkipLinks";
import DynamicImage from "../HelperComponents/DynamicImage";
import DemographicModal from "../Demographics/DemographicModal";
import LoginModal from "../LoginModal/LoginModal";

const lngs = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Spanish' }
};

export class Navbar extends Component {
  state = {
    searchTerm: "",
    userButtonHover: false,
    pathDropdownIcon: "caret down",
    pathDropdownOpen: false,
    mobileDropdownIcon: "bars",
  };

  loginButtons() {
    switch (this.props.location.pathname) {
      case "/confirm":
        this.props.dispatch(toggleConfirmation(true));
        break;
      case "/demographics":
        this.props.dispatch(toggleDemographics(true));
        break;
      case "/forgot_password":
        this.props.dispatch(toggleForgotPassword(true));
        break;
      case "/login":
        this.props.dispatch(toggleLoginModal(true));
        break;
      case "/register":
        this.props.dispatch(toggleRegister(true));
        break;
      case "/reset_password_form":
        this.props.dispatch(toggleResetPassword(true));
        break;
      default:
        break;
    }

    const { t } = this.props;

    return (
      <Menu.Item className="registration-buttons">
        {!this.props.isMobile && (
          <Button onClick={() => this.props.dispatch(toggleRegister(true))}>
            { t('register') }
          </Button>
        )}
        <Button
          className="login-button"
          onClick={() => this.props.dispatch(toggleLoginModal(true))}
        >
          { t('login') }
        </Button>
        <RegistrationModal type={this.props.modalType} open={this.props.open} />
        <LoginModal type={this.props.loginScreen} open={this.props.loginOpen} />
      </Menu.Item>
    );
  }

  languageButtons() {
    const { t, i18n } = this.props;
    
    if (isTranslationEnabled()) {
      return (
        <Menu.Item className="registration-buttons">
          {Object.keys(lngs).map((lng) => {
            if (lng !== i18n.resolvedLanguage){
              return(
                <button key={lng} className="ui button" onClick={() => i18n.changeLanguage(lng)}>
                  {lngs[lng].nativeName}
                </button>
              )
            }
          })}
        </Menu.Item>
      )
    }
  }

  navigationUserDropdown() {
    const username = this.props.firstName
      ? truncate(this.props.firstName, 16)
      : truncate(this.props.userData.user.first_name, 16);

    return (
      <NavigationUserDropdown
        username={username}
        helpdeskLink={helpdeskLink()}
        logoutUser={() =>
          this.props.dispatch(logoutUserAndReset(this.props.accessToken))
        }
      />
    );
  }

  emailConfirmationModal() {
    if (this.props.location.pathname === "/email_confirmation") {
      this.props.dispatch(toggleProfileConfirmation(true));
    }
    return <EmailConfirmationForm />;
  }

  helpDropdown() {
    return (
      <NavigationHelpDropdown
        showTouring={() => this.props.dispatch(toggleTouring(true))}
        gotoHelpdesk={() => window.open(helpdeskLink(), "_blank")}
      />
    );
  }

  userMenuItems() {
    const requireUser =
      process.env.REACT_APP_REQUIRE_USER_TO_TRACK_PROGRESS !== "false";

    if (requireUser) {
      return this.props.isUserLoggedIn
        ? this.navigationUserDropdown()
        : this.loginButtons();
    }
  }

  userHelpMenus() {
    return (
      <Menu.Menu position="right" key="menu-users">
        {this.languageButtons()}
        {this.userMenuItems()}
        {this.helpDropdown()}
      </Menu.Menu>
    );
  }

  homePageIcon() {
    if(this.props.navLogoLink) {
      return (
        <Menu.Item key="nav-logo" header as={"a"} href={this.props.navLogoLink }>
          <DynamicImage className="logo logo-large" src={rewriteUrlWithCDN("my-sba/sba-official-logo.png")} alt="MySBA Learning logo" />
          <DynamicImage className="logo logo-small" src={rewriteUrlWithCDN("my-sba/sba-logo-w79-h64.png")} alt="MySBA Learning logo" />
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item key="nav-logo" header as={Link} to={"/"}>
          <DynamicImage className="logo logo-large" src={rewriteUrlWithCDN("my-sba/sba-official-logo.png")} alt="MySBA Learning logo" />
          <DynamicImage className="logo logo-small" src={rewriteUrlWithCDN("my-sba/sba-logo-w79-h64.png")} alt="MySBA Learning logo" />
        </Menu.Item>
      );
    }
  }

  displayDemographics() {
    if(process.env.REACT_APP_REQUIRE_USER_TO_VIEW_CONTENT !== "false" &&
        this.props.isUserLoggedIn && this.props.mfaEnabled && !this.props.demographicsCompleted) {
      this.props.dispatch(toggleDemographics(true));
      return true;
    } else {
      this.props.dispatch(toggleDemographics(false));
      return false;
    }
  }

  render() {
    const { t } = this.props;

    const NAVIGATION_COMPONENTS = {
      LOGO: (
        this.homePageIcon()
      ),
      SEARCH_BAR: (
        <Menu.Item className="search-bar" key="menu-search">
          <NavbarSearchInput />
        </Menu.Item>
      ),
      BROWSE_JOURNEYS: (
        <Menu.Item key="browse-journeys">
          <Header as="h4" className="navigation-browse-journey-header">
            { t('browseJouneys') }
          </Header>
        </Menu.Item>
      ),
    };

    const desktopNavigation = () => {
      return [
        <SkipLinks
          location={this.props.location}
          key={"skip-links-component"}
        />,
        NAVIGATION_COMPONENTS.LOGO,
        <NavbarDropdown key={"navbar-dropdown-component"} />,
        NAVIGATION_COMPONENTS.SEARCH_BAR,
        this.userHelpMenus(),
      ];
    };

    const mobileNavigation = () => {
      return this.state.pathDropdownOpen
        ? [<NavbarDropdown />, NAVIGATION_COMPONENTS.BROWSE_JOURNEYS]
        : [
            <NavbarDropdown key="navbar-dropdown" />,
            NAVIGATION_COMPONENTS.LOGO,
            this.userHelpMenus(),
          ];
    };

    const tabletNavigation = () => {
      return this.state.pathDropdownOpen
        ? [<NavbarDropdown />, NAVIGATION_COMPONENTS.BROWSE_JOURNEYS]
        : [
            <NavbarDropdown />,
            NAVIGATION_COMPONENTS.LOGO,
            NAVIGATION_COMPONENTS.SEARCH_BAR,
            this.userHelpMenus(),
          ];
    };

    const viewNavigation = () => {
      if (this.props.isMobile) {
        return mobileNavigation();
      } else if (this.props.isTablet) {
        return tabletNavigation();
      } else {
        return desktopNavigation();
      }
    };

    return (
      <span className="header-menu">
        <DemographicModal type={this.props.demographicModalType} open={this.displayDemographics()} />
        <TouringModal />
        {this.emailConfirmationModal()}
        <Menu className="navbar" fluid>
          {viewNavigation()}
        </Menu>
      </span>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    modalType: store.navbar.modalType,
    open: store.navbar.open,
    demographicsOpen: store.demographic.open,
    demographicModalType: store.demographic.modalType,
    isUserLoggedIn: store.login.isUserLoggedIn,
    mfaEnabled: store.login.mfaEnabled,
    demographicsCompleted: store.login.demographicsCompleted,
    userData: store.login.userData,
    userProfile: store.userProfile.userData,
    firstName: store.userProfile.userData.first_name,
    accessToken: store.login.userData.access_token,
    isMobile: store.global.isMobile,
    isTablet: store.global.isTablet,
    navLogoProps: store.json.navLogo,
    navLogoLink: store.json.navLogoLink,
    loginScreen: store.login.currentScreen,
    loginOpen: store.login.open
  };
};

export default withTranslation('translations')(withRouter(connect(mapStateToProps)(Navbar)))

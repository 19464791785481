import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Container } from "semantic-ui-react";
import HomepageExcursionCard from "./HomepageExcursionCard";

import "./HomepageExcursions.scss";

export class HomepageExcursions extends Component {
  listExcursions(excursions) {
    return excursions.map((excursion, index) => (
      <HomepageExcursionCard
        key={index}
        image={excursion.image}
        header={excursion.header}
        description={excursion.description}
        alt={excursion.alt}
        isMobile={this.props.isMobile}
      />
    ));
  }

  render() {
    const excursions = [
      {
        image: "/excursions/HP_Icon_Tools.png",
        header: "Exercises & Tools",
        description: "Learn and apply growth practices to your business.",
        alt: "Worksheet paper with checkbox, a pencil, and a clock.",
      },
      {
        image: "/excursions/HP_Icon_Firesidechat.png",
        header: "Fireside Chats",
        description:
          "Learn from experts about how women grow their businesses.",
        alt: "Woman listening to podcast with headphones.",
      },
      {
        image: "/excursions/HP_Icon_Infographics.png",
        header: "Infographics",
        description: "Gain a snapshot view of growth concepts.",
        alt: "Graph with two points on it.",
      },
      {
        image: "/excursions/HP_Icon_SuccessStories.png",
        header: "Success Stories",
        description: "Be inspired by stories from real-world entrepreneurs.",
        alt: "Trophy with a star in the middle.",
      },
      {
        image: "/excursions/HP_Icon_Discussions.png",
        header: "Discussion Guides",
        description:
          "Use questions to stimulate thinking for you, your advisors or team.",
        alt: "Two women conversing with speech bubbles.",
      },
      {
        image: "/excursions/HP_Icon_Videos.png",
        header: "Videos",
        description: "Grasp key concepts in just minutes.",
        alt:
          "Mac computer screen showing a video with play button in the middle.",
      },
      {
        image: "/excursions/HP_Icon_Key_Insights.png",
        header: "Key Insights",
        description: "Understand key topics to support your business growth.",
        alt: "A brightly-shining light bulb.",
      },
      {
        image: "/excursions/HP_Icon_Self_Assessments.png",
        header: "Self-Assessments",
        description:
          "Benchmark your current practices through self-focused inventories.",
        alt: "A woman with a question mark near her head.",
      },
    ];

    return (
      <Container>
        <Card.Group id="home-excursion-grid" textAlign="center">
          {this.listExcursions(excursions)}
        </Card.Group>
      </Container>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isMobile: store.global.isMobile,
  };
};

export default connect(mapStateToProps)(HomepageExcursions);

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PathProgress from "./PathProgress";

const mapStateToProps = store => {
  return {
    isUserLoggedIn: store.login.isUserLoggedIn,
    journeyLoading: store.journey.journeyLoading,
    isMobile: store.global.isMobile,
  };
};

export default withRouter(connect(mapStateToProps)(PathProgress));

import React, {Component} from "react";
import {Grid, Placeholder} from "semantic-ui-react";

export default class SearchPlaceholder extends Component {
  render() {
    let placeholders = [];
    for(var i = 0; i < this.props.count; i++) {
      placeholders.push(
        <Grid stackable className="search-result-item" key={i}>
          <Grid.Row columns={2}>
            <Grid.Column width={2}>
              <Placeholder className="search-result-image">
                <Placeholder.Image square size="small" />
              </Placeholder>
            </Grid.Column>
            <Grid.Column>
              <div className="search-result-content">
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line length="full" />
                  </Placeholder.Header>
                </Placeholder>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>);
    }

    return placeholders;
  }
}

import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import NavigationJourney from "./MainMenu/NavigationJourney";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { toggleDropdown } from "../../../actions/globalActions";
import { forcePathDropdownCloseToggle } from "../../../actions/navbarActions";

import {
  navBarPathArrowDown,
  navBarPathArrowUp,
  navBarPathArrowRight,
  resetTopicNavActiveIndex,
  navBarTopicArrowDown,
  navBarTopicArrowUp,
  resetMainNav,
} from "../../../actions/accessibilityActions";

export class NavbarDropdown extends Component {
  constructor(props) {
    super(props);

    this.dropdownRef = React.createRef();

    this.state = {
      userButtonHover: false,
      pathDropdownIcon: "caret down",
      pathDropdownOpen: false,
      mobileDropdownIcon: "bars",
      underlineText: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  handleClick = (e) => {
    const dropdownRef = this.dropdownRef;
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      this.pathDropdownClose();
    }
  };

  componentDidUpdate(prevProps) {
    const { forcePathDropdownClose } = this.props;
    if (forcePathDropdownClose !== prevProps.forcePathDropdownClose) {
      if (forcePathDropdownClose) {
        this.pathDropdownClose();
      }
    }
  }

  pathDropdownOpen() {
    this.setState({
      pathDropdownIcon: "caret up",
      pathDropdownOpen: true,
      mobileDropdownIcon: "close",
      isDisableScroll: true,
    });
    this.props.dispatch(toggleDropdown(true));
  }

  pathDropdownClose() {
    this.setState({
      pathDropdownIcon: "caret down",
      pathDropdownOpen: false,
      mobileDropdownIcon: "bars",
      isDisableScroll: false,
    });
    this.props.dispatch(toggleDropdown(false));
    this.props.dispatch(resetMainNav());
    this.props.dispatch(forcePathDropdownCloseToggle(false));
  }

  dropDownKeyDown(e) {
    if (this.state.pathDropdownOpen) {
      // If the dropdown is open preventDefault of space bar and enter,
      // we use keyCode of space since key value is not given
      if (e.keyCode === 32 || e.keyCode === 13) {
        e.preventDefault();
      }
      if (e.key === "Escape" || e.key === "Tab") {
        e.preventDefault();
        this.pathDropdownClose();
      }
      if (!this.props.mainNav.innerNavActive) {
        // If the inner nav is not active we are navigating the journey
        this.handlePathKeyboardNav(e);
      } else {
        // If the inner nav is active we are navigating the inner topic nav menu
        this.handleTopicKeyboardNav(e);
      }
    } else {
      if (e.keyCode === 32 || e.keyCode === 13) {
        e.preventDefault();
        this.pathDropdownOpen();
      }
    }
  }

  handlePathKeyboardNav(e) {
    // Keyboard arrow navigation up or down moves through the Paths
    if (e.key === "ArrowDown") {
      e.preventDefault();
      this.props.dispatch(navBarPathArrowDown());
      //go down or to the top
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      this.props.dispatch(navBarPathArrowUp());
    } else if (e.key === "ArrowRight") {
      e.preventDefault();
      // Navigate into the inner topic nav portion of the dropdown menu
      this.props.dispatch(navBarPathArrowRight());
    }
  }

  handleTopicKeyboardNav(e) {
    // Keyboard arrow navigation up or down moves through the Topics
    if (e.key === "ArrowDown") {
      e.preventDefault();
      this.props.dispatch(navBarTopicArrowDown());
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      this.props.dispatch(navBarTopicArrowUp());
    } else if (e.key === "ArrowLeft") {
      e.preventDefault();
      // Navigate back to the path nav items menu portion
      this.props.dispatch(resetTopicNavActiveIndex());
    } else if (e.keyCode === 32 || e.keyCode === 13) {
      e.preventDefault();
      // If enter or space key is pressed we will navigate to the selected topic link

      // Close the dropdown
      this.pathDropdownClose();

      // Navigate to the link of selected topic
      const { activePathId, activeTopicId } = this.props.mainNav;
      this.props.history.push(`/${activePathId}/${activeTopicId}`);
    }
  }

  togglePathDropdown() {
    if (this.state.pathDropdownOpen) {
      this.pathDropdownClose();
    } else {
      this.pathDropdownOpen();
    }
  }

  toggleUnderLineText(underline) {
    this.setState({ underlineText: underline });
  }

  browseJourneysText() {
    const { t, journeyTerm } = this.props;
    return t('browse') + ' ' + t(journeyTerm) + 's';
  }

  dropdownText() {
    return (
      <div className="text">
        {this.props.isMobile ? "" : this.browseJourneysText()}
      </div>
    );
  }

  dropdownIcon() {
    const iconName = this.props.isMobile
      ? this.state.mobileDropdownIcon
      : this.state.pathDropdownIcon;

    return <Icon name={iconName} />;
  }

  dropdownContent(pathDropdownOpen) {
    const visibleClass = pathDropdownOpen ? " visible" : "";

    return (
      <div
        className={`menu transition navigation-journey-menu${visibleClass}`}
        tabIndex="-1"
      >
        <NavigationJourney />
      </div>
    );
  }

  render() {
    const { pathDropdownOpen, underlineText } = this.state;

    const underlineTextClass = underlineText ? " underline-text" : "";
    const openClass = pathDropdownOpen ? " active visible" : "";

    return (
      <div
        ref={this.dropdownRef}
        onClick={() => {
          this.togglePathDropdown();
          this.toggleUnderLineText(true);
        }}
        onBlur={() => {
          this.toggleUnderLineText(false);
        }}
        id="navbar-journeys"
        className={`ui item dropdown${underlineTextClass}${openClass}`}
        aria-label={this.props.isMobile ? "" : this.browseJourneysText() + " Dropdown Menu"}
        aria-haspopup={true}
        role="listbox"
        aria-expanded={pathDropdownOpen}
        tabIndex={0}
        onKeyDown={(e) => this.dropDownKeyDown(e)}
        onMouseEnter={() => this.toggleUnderLineText(true)}
        onMouseLeave={() => this.toggleUnderLineText(false)}
        onFocus={() => this.toggleUnderLineText(true)}
        key="menu-dropdown"
      >
        {this.dropdownText()}
        {this.dropdownIcon()}
        {this.dropdownContent(pathDropdownOpen)}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isUserLoggedIn: store.login.isUserLoggedIn,
    journeys: store.journey.journeys,
    mainNav: store.accessibility.mainNav,
    isMobile: store.global.isMobile || store.global.isTablet,
    forcePathDropdownClose: store.navbar.forcePathDropdownClose,
    journeyTerm: store.json.journeyTerm
  };
};

export default withTranslation('translations')(withRouter(connect(mapStateToProps)(NavbarDropdown)));

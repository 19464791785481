import React, { Component } from "react";
import ContentBlock from "./ContentBlock";
import { v4 as uuid } from "uuid";

export default class ContentContainer extends Component {
  render() {
    return <React.Fragment>
      { this.props.content.content.map((x) => <ContentBlock key={uuid()} content={x}/>) }
    </React.Fragment>
  }
}
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { getStore } from "./store";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';

import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "./components/Errors/ErrorBoundary";
import ScrollToTop from "./components/HelperComponents/ScrollToTop";
import Refocus from "./components/Refocus/Refocus";

import i18n from './i18n/config';

ReactDOM.render(
  <Provider store={getStore()}>

    <I18nextProvider i18n={i18n} defaultNS={'translations'}>
      <BrowserRouter>
        <ErrorBoundary>
          <ScrollToTop>
            <Refocus>
              <App />
            </Refocus>
          </ScrollToTop>
        </ErrorBoundary>
      </BrowserRouter>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

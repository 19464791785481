import React from "react";
import ReactHtmlParser from "react-html-parser";
import parseHtmlOptions from "../../utils/parseHtmlHelpers/parseHtmlOptions";

export default function ParsedHtml(props) {
  const options = props.options || parseHtmlOptions;
  return (
    <React.Fragment>{ReactHtmlParser(props.html, options)}</React.Fragment>
  );
}

import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';
import DynamicImage from "../HelperComponents/DynamicImage";
import {toggleLoginModal} from '../../actions/loginActions';
import RegisterButton from "../Buttons/RegisterButton";
import {connect} from "react-redux";

export class DashboardCTA extends Component {
  handleScrollClick = (e) => {
    e.preventDefault();
    this.props.scrollToRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  listFeatures = (features) => {
    return (
      features.map( (feature, index) =>
        <Grid.Column className="feature-list-item" computer={4} mobile={13} textAlign="center" key={`feature-list-item-${index}`}>
          <DynamicImage
            src={feature.image}
            size={"tiny"}
            centered
            alt={feature.alt}
          />
          <p>
            {feature.description}
          </p>
        </Grid.Column>
      )
    )
  }

  render() {
    const { t } = this.props;

    const siteFeatures = [
      {
        "image": "/dashboard/track-progress.png",
        "alt": t('dashboardCTA.images.trackProgress.alt'),
        "description": t('dashboardCTA.images.trackProgress.description')
      },
      {
        "image": "/dashboard/resume.png",
        "alt": t('dashboardCTA.images.resume.alt'),
        "description": t('dashboardCTA.images.resume.description')
      },
      {
        "image": "/dashboard/receive-updates.png",
        "alt": t('dashboardCTA.images.receiveUpdates.alt'),
        "description": t('dashboardCTA.images.receiveUpdates.description')
      },
    ];

    return (
        <Grid className="dashboard-progress">
          <Grid.Row centered>
            <Grid.Column computer={13} mobile={13} textAlign="center">
              <span className="dashboard-progress-text">
                { t('dashboardCTA.cta') }
              </span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="feature-list-row" centered>
            { this.listFeatures(siteFeatures) }
          </Grid.Row>
          <Grid.Row centered className="register-button-row">
            <Grid.Column largeScreen={4} computer={4} mobile={13} textAlign="center">
              <RegisterButton/>
            </Grid.Column>
            <Grid.Column largeScreen={4} computer={4} mobile={13} textAlign="center">
              <Button className="login-button" primary onClick={(e) => {
                e.preventDefault();
                this.props.dispatch(toggleLoginModal(true))
              }}>
                { t('login') }
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
    )
  }
}

export default connect((store) => {
  return {}
})(withRouter(withTranslation()(DashboardCTA)));

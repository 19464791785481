import { contentClient } from "../utils/axiosClients";

export function getHomepageContent() {
  const url = "global_content/homepage";
  return (dispatch) => {
    dispatch({
      type: "GET_HOMEPAGE_CONTENT",
      payload: contentClient().get(url),
    });
  };
}

import React, { Component } from "react";
import { Button, Icon } from "semantic-ui-react";
import ParsedHtml from "./ParsedHtml";

import "./ExpandCollapseText.scss";

class ExpandCollapseMarkup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      readMore: false
    };
  }

  readMoreToggle() {
    this.setState({ readMore: !this.state.readMore });
  }

  readMoreContent() {
    return (
      <React.Fragment>
        {"Read More"} <Icon name="angle down" />
      </React.Fragment>
    );
  }

  readLessContent() {
    return (
      <React.Fragment>
        {"Read Less"} <Icon name="angle up" />
      </React.Fragment>
    );
  }

  render() {
    const { readMore } = this.state;

    return (
      <React.Fragment>
        <ParsedHtml html={this.props.defaultHtml} />
        {readMore ? <ParsedHtml html={this.props.expandHtml} /> : null}
        <Button
          className="read-more-button"
          onClick={() => this.readMoreToggle()}
          content={readMore ? this.readLessContent() : this.readMoreContent()}
          floated="right"
        />
      </React.Fragment>
    );
  }
}

export default ExpandCollapseMarkup;

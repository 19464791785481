import React from 'react';
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Message,
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { toggleLogin } from '../../actions/navbarActions';
import { registerUser } from '../../actions/registrationActions';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import PasswordRules from "../PasswordValidator/PasswordRules";
import PasswordValidator from '../PasswordValidator/PasswordValidator';
import "./SignUpForm.scss";


export class SignUpForm extends React.Component {
  state = this.getState(this.props);
  handleRef = component => (this.ref = component);

  getState(props) {
    return {
      first_name: this.checkProps("first_name"),
      last_name: this.checkProps("last_name"),
      middle_name: this.checkProps("middle_name"),
      zip_code: this.checkProps("zip_code"),
      email: this.checkProps("email"),
      password: this.checkProps("password"),
      confirm: "",
      errors: {}
    };
  }

  checkProps(propName) {
    try {
      return this.props.userRegistration[propName]
    } catch {
      return '';
    }
  }

  componentDidMount() {
    if (this.ref) {
      this.ref.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.first_name !== this.props.first_name ||
      prevProps.last_name !== this.props.last_name ||
      prevProps.middle_name !== this.props.middle_name ||
      prevProps.zip_code !== this.props.zip_code ||
      prevProps.email !== this.props.email ||
      prevProps.password !== this.props.password ||
      prevProps.errors !== this.props.errors
    ) {
      this.setState(this.getState(prevProps));
    }
    const { userError } = this.props;
    if (userError && userError !== prevProps.userError) {
      this.props.scrollToTop();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.dispatch(registerUser({
      first_name: this.state.first_name.trim(),
      last_name: this.state.last_name.trim(),
      middle_name: this.state.middle_name.trim(),
      zip_code: this.state.zip_code.trim(),
      email: this.state.email.trim(),
      password: this.state.password.trim(),
    }));
  }

  showLogin = () => {
    this.props.dispatch(toggleLogin(true));
  }

  getChangeHandler(field) {
    return (e) => {
      var state = {};
      state[field] = e.target.value;
      this.setState(state);
    }
  }

  getErrorMessageIfNeeded() {
    if (this.props.userError) {
      const text = this.props.userError.join(" ");
      return (
        <div className="login-error-message">
          <Message negative>
            {text}
          </Message>
        </div>
      )
    };
  };


  getField(field, fieldName, required, refHandler) {
    return <Form.Field required={required}>
              <label>{fieldName}</label>
              <input placeholder={fieldName}
                   name={fieldName}
                   ref={refHandler}
                   value={this.state[field]}
                   aria-label={fieldName}
                   onChange={this.getChangeHandler(field).bind(this)}
              />
            </Form.Field>
  }

  submitEnabled() {
    return (
      this.hasValue(this.state.first_name)
      && this.hasValue(this.state.last_name)
      && this.hasValue(this.state.email)
      && this.hasValue(this.state.zip_code)
      && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.state.zip_code)
      && PasswordRules.valid(this.state.password, this.state.confirm)
    );
  }

  hasValue(value) {
    return value !== undefined && value.length > 0
  }

  render() {
    const { t } = this.props;
    console.log("*************************")
    console.log(t);
    console.log("*************************")
    return (
      <Container className="signup-form">
        <h2>{ t("signUpForm.title") }</h2>
        {this.getErrorMessageIfNeeded()}
        <Form onSubmit={this.handleSubmit.bind(this)}>
          {this.getField('first_name', t('firstName'), true, this.handleRef)}
          {this.getField('last_name', t('lastName'), true)}
          {this.getField('middle_name', t('middleName'), false)}
          {this.getField('zip_code', t('postalCode'), true)}
          {this.getField('email', t('emailAddress'), true)}
          <Form.Field required>
              <label>{ t('password') }</label>
              <input placeholder={ t('password') } type="password"
                   value={this.state.password}
                   aria-label={ t('password') }
                   onChange={this.getChangeHandler('password').bind(this)}
              /> <br />
          </Form.Field>
          <Form.Field required>
              <label>{ t('passwordConfirm') }</label>
              <input placeholder={ t('passwordConfirm') } type="password"
                   value={this.state.confirm}
                   aria-label={ t('passwordConfirm') }
                   onChange={this.getChangeHandler('confirm').bind(this)}
              /> <br />
          </Form.Field>
          <PasswordValidator password={this.state.password} confirm={this.state.confirm} />
          <Divider />
          <Grid className="middle aligned">
            <Grid.Row columns={16}>
              <Grid.Column id="account-text" width={12}>
                { t('haveAccount') } <Link to="/" onClick={(e) => {e.preventDefault(); this.props.dispatch(toggleLogin(true))}}><b>{ t('login') }</b></Link>
              </Grid.Column>
              <Grid.Column id="sign-up-next-button" width={4}><Button className="primary" aria-label={ t('next') } type="submit" disabled={!this.submitEnabled()}>{ t('next') }</Button></Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />
          <Grid>
            <Grid.Row columns={16}>
              <Grid.Column width={16}>
                <br/>
                <p>
                  { t('registration.paragraphOne') }
                  &nbsp;<a href="https://www.sba.gov/about-sba/open-government/about-sbagov-website" target="_blank" rel="noopener noreferrer">{ t('registration.here') }</a>.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid className="bg-gray">
            <Grid.Row columns={16}>
              <Grid.Column width={16}>
                <p>
                  { t('registration.paragraphTwo') }
                </p>
                <p>
                { t('registration.paragraphThree') }
                </p>
                <Grid>
                  <Grid.Row className="mb-control-information">
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      { t('registration.controlNumber') }
                    </Grid.Column>
                    <Grid.Column
                      className="omb-expiration"
                      mobile={16}
                      tablet={8}
                      computer={8}
                    >
                      { t('registration.expirationDate') }
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <br />
                <p>
                  { t('registration.paragraphFour') }
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    );
  }
};

const mapStateToProps = store => {
  return {
    userRegistration: store.registration.userData,
    userError: store.registration.userError
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(SignUpForm)))

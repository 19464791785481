export function linkToJourney(id) {
  if (!id) {
    return null;
  }
  return "/" + id;
}

export function linkToExcursion(journeyId, excursionId) {
  if (!journeyId || !excursionId) {
    return null;
  }
  return linkToJourney(journeyId) + "/" + excursionId;
}

export function linkToPath(journeyId, excursionId, pathId) {
  if (!journeyId || !excursionId || !pathId) {
    return null;
  }
  return linkToExcursion(journeyId, excursionId) + "/" + pathId;
}

export function linkToJourneys() {
  return "/journeys";
}

export function linkToDashboard() {
  return "/dashboard";
}

export function linkToForgotPassword() {
  return "/forgot_password";
}

export function linkToSearch() {
  return "/search";
}

export function linkToProfile() {
  return "/profile";
}

export function linkToRegistration() {
  return "/register";
}

export function linkToResetPassword() {
  return "/reset_password_form";
}

export function linkToConfirmAccount() {
  return "/confirm";
}

export function linkToLogin() {
  return "/login";
}

export function linkToVtt(pathId) {
  return `learning_events/${pathId}.vtt`;
}

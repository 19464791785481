import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';
import DynamicImage from "../HelperComponents/DynamicImage";

class PathCompleted extends Component {

  render() {
    const { t } = this.props;

    return (
      <Grid.Row>
        <Grid.Column className="path-progress-finished">
          <DynamicImage
            src="static_content/progress_image.png"
            size="mini"
            alt="MySBA Learning Mountain Progress Image"
            floated={"left"}
          />
          <b>{ t('pathCompleted.paragraphs.one') }</b> <br/> { t('pathCompleted.paragraphs.two') }
        </Grid.Column>
      </Grid.Row>
    )
  }
}

export default withTranslation()(PathCompleted);

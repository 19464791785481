
const PasswordRules = {
  RULES: [
    {
      rule: "passwordRules.oneUpperCase",
      validator: (pw1, pw2) => pw1 && pw1.match(/(.*)([A-Z]+)(.*)/)
    },
    {
      rule: "passwordRules.oneLowerCase",
      validator: (pw1, pw2) =>  pw1 && pw1.match(/(.*)([a-z]+)(.*)/)
    },
    {
      rule: "passwordRules.oneNumber",
      validator: (pw1, pw2) =>  pw1 && pw1.match(/(.*)([0-9]+)(.*)/)
    },
    {
      rule: "passwordRules.oneSpecialCharacter",
      validator: (pw1, pw2) => pw1 && pw1.match(/(.*)([\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]+)(.*)/)
    },
    {
      rule: "passwordRules.twelveCharacters",
      validator: (pw1, pw2) => pw1 && pw1.length > 11
    },
    {
      rule: "passwordRules.matchingPasswords",
      validator: (pw1, pw2) =>  pw1 && pw1 === pw2 && pw1.length > 1
    }
  ],
  valid: (password, confirmation) => {
    let validations = PasswordRules.RULES.map(rule => rule.validator(password, confirmation));
    let result = true
    for (let elem of validations) {
      if ((elem === false) || (elem === null)) {
        result = false;
        break;
      }
    }
    return result;
  }
}

export default PasswordRules;

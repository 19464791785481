function defaultState() {
  return {
    journeys: [],
    journeysLoading: false,
    journeysError: null,

    journey: {},
    journeyLoading: false,
    journeyError: null,

    pathsComplete: null,
    pathsTotal: null,
    journeyComplete: false,

    journeyProgress: [],
    journeyProgressLoading: false,
    journeyProgressError: null,

    latestUserEnrollment: {},
    latestUserEnrollmentError: null,
    hasUserStartedJourney: false,

    topicsList: [],
    pathTopicsLoading: false,
    pathTopicsError: null,
    pathTopicCompletions: {},
  };
}

export default function reducer(state = defaultState(), action) {
  switch (action.type) {
    case "RESET":
      return defaultState();
    case "GET_JOURNEYS_PENDING":
      return {
        ...state,
        journeys: [],
        journeysLoading: true,
        journeysError: null,
      };
    case "GET_JOURNEYS_FAILURE":
      return {
        ...state,
        journeys: [],
        journeysLoading: false,
        journeysError: action.payload,
      };
    case "GET_JOURNEYS_FULFILLED":
      return {
        ...state,
        journeys: action.payload.data.journeys,
        journeysLoading: false,
        journeysError: null,
      };
    case "GET_JOURNEY_PENDING":
      return {
        ...state,
        journey: {},
        journeyLoading: true,
        journeyError: null,
      };
    case "GET_JOURNEY_FAILURE":
      return {
        ...state,
        journey: {},
        journeyLoading: false,
        journeyError: action.payload,
      };
    case "GET_JOURNEY_FULFILLED": {
      return {
        ...state,
        journeyLoading: false,
        journeyError: null,
        journey: action.payload.data.journey,
      };
    }
    case "GET_PROGRESS_FOR_JOURNEY_PENDING":
      return {
        ...state,
        journeyProgressLoading: true,
        journeyProgressError: null,
      };
    case "GET_PROGRESS_FOR_JOURNEY_FAILURE":
      return {
        ...state,

        journeyProgressLoading: true,
        journeyProgressError: null,
      };
    case "GET_PROGRESS_FOR_JOURNEY_FULFILLED": {
      const progress = action.payload.data.journey || {};
      return {
        ...state,
        pathsComplete: progress.requirement_completed_count || 0,
        pathsTotal: progress.requirement_count || 1,
        journeyComplete: progress.completed,
        journeyProgressLoading: false,
        journeyProgressError: null,
      };
    }
    case "GET_PROGRESS_FOR_JOURNEYS_PENDING":
      return {
        ...state,
        journeyProgress: [],
        journeyProgressLoading: true,
        journeyProgressError: null,
      };
    case "GET_PROGRESS_FOR_JOURNEYS_FAILURE":
      return {
        ...state,
        journeyProgress: [],
        journeyProgressLoading: true,
        journeyProgressError: null,
      };
    case "GET_PROGRESS_FOR_JOURNEYS_FULFILLED":
      return {
        ...state,
        journeyProgress: action.payload.data.journeys,
        journeyProgressLoading: false,
        journeyProgressError: null,
      };
    case "GET_LATEST_USER_PROGRESS_PENDING":
      return {
        ...state,
        latestUserEnrollmentError: null,
      };
    case "GET_LATEST_USER_PROGRESS_FAILURE":
      return {
        ...state,
        latestUserEnrollment: null,
        latestUserEnrollmentError: action.payload.data,
      };
    case "GET_LATEST_USER_PROGRESS_FULFILLED":
      return {
        ...state,
        latestUserEnrollment: action.payload.data,
        latestUserEnrollmentError: null,
      };
    case "USER_HAS_STARTED_JOURNEY":
      return {
        ...state,
        hasUserStartedJourney: true,
      };
    case "USER_HAS_NOT_STARTED_JOURNEY":
      return {
        ...state,
        hasUserStartedJourney: false,
      };
    default:
      break;
  }

  return state;
}

import React from "react";
import { Container, Radio, Checkbox, Input } from "semantic-ui-react";

import "./CheckboxQuestion.scss";

export default class CheckboxQuestion extends React.Component {
  handleRef = component => (this.ref = component);
  constructor(props) {
    super(props);
    this.state = {
      customAnswerText: {}
    };
  }

  componentDidMount() {
    if (this.ref) {
      this.ref.focus();
    }

    // When we navigate back using the previous button, we need to make sure to
    // Add the custom answers back into the component state
    const { customAnswers } = this.props;
    if (customAnswers) {
      const value = this.props.value || [];
      customAnswers.forEach(answer => {
        const checked = this.isSelected(answer, value);
        if (checked && !this.state.customAnswerText[answer]) {
          const customAnswerValue = value.find(x => x.includes(answer));
          this.setState({
            customAnswerText: {
              [answer]: this.sanitizeCustomAnswer(answer, customAnswerValue)
            }
          });
        }
      });
    }
  }

  componentDidUpdate(_, prevState) {
    if (prevState.customAnswerText !== this.state.customAnswerText) {
      Object.keys(this.state.customAnswerText).forEach(key => {
        this.updateCustomAnswer(key);
      });
    }
  }

  toggle(answer) {
    if (this.props.onlyAllowOne) {
      if (this.props.value === answer) {
        this.props.onChange("");
      } else {
        this.props.onChange(answer);
      }
    } else {
      var value = this.props.value || [];

      if (value.find(x => x.includes(answer))) {
        this.props.onChange(value.filter(x => !x.includes(answer)));
        this.setState({ customAnswerText: { [answer]: "" } });
      } else {
        const customAnswer = this.state.customAnswerText[answer];
        const answerToSave = customAnswer
          ? this.customAnswerValue(answer, customAnswer)
          : answer;

        this.props.onChange(value.concat([answerToSave]));
      }
    }
  }

  customAnswerValue(answer, customAnswer) {
    return `${answer} - ${customAnswer}`;
  }

  updateCustomAnswer(answer) {
    var value = this.props.value || [];
    const valueIndex = value.findIndex(x => x.includes(answer));

    if (valueIndex !== -1) {
      const customAnswer = this.state.customAnswerText[answer];
      value[valueIndex] = this.customAnswerValue(answer, customAnswer);

      this.props.onChange(value);
    }
  }

  isSelected(current, value) {
    if (this.props.onlyAllowOne) {
      return value === current;
    } else {
      if (value && value.includes) {
        return value.find(x => x.includes(current)) ? true : false;
      }
      return false;
    }
  }

  getInstructions() {
    return this.props.onlyAllowOne
      ? "(check only one)"
      : "(check all that apply)";
  }

  handleKeyCheckFacet(event, answer) {
    if (event.target.type !== "text") {
      if (event.keyCode === 32 || event.keyCode === 13) {
        event.preventDefault();
        this.toggle(answer);
      }
    }
  }

  generateElement(answer) {
    const handler = () => this.toggle(answer);
    const checked = this.isSelected(answer, this.props.value);
    return this.props.onlyAllowOne ? (
      <Radio
        tabIndex="-1"
        className="checkbox-choice"
        onChange={handler}
        aria-label={answer}
        key={answer}
        label={answer}
        checked={checked}
        autoComplete="off"
      />
    ) : (
      <Checkbox
        tabIndex="-1"
        className="checkbox-choice"
        onChange={handler}
        aria-label={answer}
        key={answer}
        label={answer}
        checked={checked}
        autoComplete="off"
      />
    );
  }

  generateContainer(focus, answer) {
    return (
      <div
        tabIndex="0"
        className="checkbox-wrapper"
        ref={focus ? this.handleRef : null}
        onKeyDown={event => this.handleKeyCheckFacet(event, answer)}
        key={"container for " + answer}
      >
        {this.generateElement(answer)}
      </div>
    );
  }

  generateCustomContainer(focus, answer) {
    const customAnswerText = this.state.customAnswerText[answer];
    return (
      <div
        tabIndex="0"
        className="checkbox-wrapper custom-checkbox"
        ref={focus ? this.handleRef : null}
        onKeyDown={event => this.handleKeyCheckFacet(event, answer)}
        key={"container for " + answer}
      >
        {this.generateElement(answer)}
        <Input
          className="custom-text-input"
          aria-label={`Text input field for ${answer} selection`}
          disabled={!this.isSelected(answer, this.props.value)}
          onChange={(_, target) => {
            this.setState({ customAnswerText: { [answer]: target.value } });
          }}
          value={customAnswerText}
        ></Input>
      </div>
    );
  }

  //We prepend the answer label on the value stored, when re-applying the data
  // after navigation we want to sanitize the string to remove the prepended data
  sanitizeCustomAnswer(answer, text) {
    return text ? text.replace(`${answer} - `, "") : "";
  }

  render() {
    const customAnswers = this.props.customAnswers || [];

    return (
      <Container>
        <p className="checkbox-question">
          <b>{this.props.label}:</b>
        </p>
        <p className="checkbox-instructions">{this.getInstructions()}</p>
        {this.props.answers.map((answer, index) => {
          return this.generateContainer(
            index === 0 && this.props.autoFocus,
            answer
          );
        })}
        {customAnswers.map(answer => {
          return this.generateCustomContainer(false, answer);
        })}
      </Container>
    );
  }
}

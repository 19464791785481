import React, { Component } from "react";
import { connect } from "react-redux";
import { externalSite } from "../../actions/globalActions";

export class ExternalLink extends Component {
  linkClick = e => {
    e.preventDefault();
    this.props.dispatch(externalSite(true, this.props.parsedNode.href));
  };

  render() {
    return (
      <a
        href={this.props.parsedNode.href}
        onClick={this.linkClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        {this.props.parsedNode.children}
      </a>
    );
  }
}

export default connect()(ExternalLink);

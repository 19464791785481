import { contentClient } from "../utils/axiosClients";

export function toggleTouring(show) {
  return {
    type: "TOGGLE_TOURING_MODAL",
    payload: { show },
  };
}

export function getTouringContent() {
  const url = "/global_content/touring";
  return (dispatch) => {
    dispatch({
      type: "GET_TOURING_CONTENT",
      payload: contentClient().get(url),
    });
  };
}

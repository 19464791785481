import React, { Component } from "react";
import MetaTags from "../SEO/MetaTags";
import { linkToJourney } from "../../utils/InternalLinks";
import { getCustomData, getJourneyName } from "../../utils/HelperFunctions";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import BreadcrumbHeader from "../BreadcrumbHeader/BreadcrumbHeader";
import CardContainer from "../CardContainer";

export class Journey extends Component {
  componentDidMount() {
    this.initialFunctions();
  }

  initialFunctions() {
    const id = this.props.match.params.id;

    this.props.getJourney(id);

    if (this.props.isUserLoggedIn) {
      this.props.getProgressForJourney(id);
      this.props.getProgressForExcursions(id);
    }
  }

  componentDidUpdate(prevProps) {
    const { isUserLoggedIn } = this.props;
    const { id } = this.props.match.params;
    const { isUserLoggedIn: prevIsUserLoggedIn } = prevProps;
    const { id: prevId } = prevProps.match.params;

    if (id !== prevId || isUserLoggedIn !== prevIsUserLoggedIn) {
      this.initialFunctions();
    }
  }

  render() {
    const cardHeader = this.props.excursionTerm + "s";
    const journey = this.props.journey || {};
    const { journeyLoading, isMobile, excursionProgress } = this.props;
    const excursions = journey.excursions || [];

    return (
      <div className="journey-content">
        <MetaTags
          metaTitle={getJourneyName(journey)}
          metaDescription={getCustomData(journey, "description", null)}
          metaImage={getCustomData(journey, "thumbnail_url", null)}
          canonicalUrl={`${process.env.REACT_APP_SITE_ROOT}${linkToJourney(
            this.props.match.params.id
          )}`}
        />
        <BreadcrumbHeader
          isMobile={isMobile}
          journey={journey}
          pathName={getJourneyName(journey)}
        />
        <CardContainer
          cardHeader={cardHeader}
          cardItems={excursions}
          loading={journeyLoading}
          journeyId={journey.id}
          progressItems={excursionProgress}
        />
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    excursionTerm: store.json.excursionTerm
  };
};

export default withTranslation()(connect(mapStateToProps)(Journey));

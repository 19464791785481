import React, { Component } from "react";
import TopicContentItem from "./TopicContentItem";
import "./Excursions.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class Excursions extends Component {
  constructor(props) {
    super(props);

    this.topicRefs = {};
  }

  renderTopic(topic) {
    if (!topic) {
      return <div />;
    }
    return (
      <div
        key={"excursions" + topic.id}
        className={"topic-content-view"}
        ref={this.topicRefs[topic.id]}
      >
        <TopicContentItem topic={topic} course_id={this.props.course_id} />
      </div>
    );
  }

  render() {
    const { topicsList } = this.props;
    if (topicsList && topicsList.length) {
      if (this.props.match.params.topicId) {
        const paramsId = this.props.match.params.topicId;
        const isTopicSlugged = isNaN(paramsId) ? paramsId : parseInt(paramsId);
        const topic = topicsList.find(t => {
          return t.id === isTopicSlugged;
        });
        return this.renderTopic(topic);
      } else {
        return topicsList.map((topic, idx) => {
          this.topicRefs[topic.id] = React.createRef();
          return this.renderTopic(topic);
        });
      }
    } else {
      return null;
    }
  }
}

const mapStateToProps = store => {
  return {
    learningEventsLoading: store.learningEvent.learningEventsLoading,
    learningEventsCollection: store.learningEvent.learningEventsCollection,
    isMobile: store.global.isMobile
  };
};

export default withRouter(connect(mapStateToProps)(Excursions));

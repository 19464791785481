import React from 'react';
import NumberedCircle from '../NumberedCircle/NumberedCircle';

import {Grid} from 'semantic-ui-react';

export default class GriddedQuestion extends React.Component {
  render() {
    return <Grid>
            <Grid.Row>
              <Grid.Column width={2}><NumberedCircle number={this.props.number}/></Grid.Column>
              <Grid.Column width={14}>
                {this.props.children}
              </Grid.Column>
            </Grid.Row>
          </Grid>
  }
}
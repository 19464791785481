import React, { Component } from "react";
import HomepageBlockManager from "./HomepageBlocks/HomepageBlockManager";
import MetaTags from "../SEO/MetaTags";

import { getHomepageContent } from "../../actions/homepageActions";
import { connect } from "react-redux";

import { rewriteUrlWithCDN } from "../../utils/HelperFunctions";

import "./Homepage.scss";
import HeroModule from "./HeroModule";

export class Homepage extends Component {
  componentDidMount() {
    this.props.getHomepageContent();
  }

  containers() {
    return [
      {
        "type": "hero-module",
        "content": {
          "backgroundImage1": {
            "type": "image",
            "content": {
              "imageUrl": rewriteUrlWithCDN("/a3/19/9cfd791a42c0ac44d2e1c4feee69/home-splash-background-2.png"),
              "altText": null,
              "altText_spanish": null,
              "describedBy": null,
              "describedBy_spanish": null
            }
          },
          "title": {
            "type": "styled-text",
            "content": {
              "text": "Welcome to MySBA Learning"
            },
            "style": {
              "innerStyle": {
                "textAlign": "center"
              }
            }
          },
          "tagLine": {
            "type": "styled-text",
            "content": {
              "text": "A free learning platform for entrepreneurs"
            },
            "style": {
              "innerStyle": {
                "textAlign": "center"
              }
            }
          },
          "button": {
            "type": "button",
            "content": {
              "buttonText": "Start Learning",
              "buttonURL": "/dashboard"
            }
          }
        }
      },
      {
        "type": "container",
        "content": {
          "subTitle": {
            "type": "styled-text",
            "content": {
              "text": "Small businesses play a crucial role in the U.S. economy. They drive innovation, create jobs and provide stability to the economy. These businesses represent all types of industries and are catalysts for regional development across the country. The majority are small, with great potential for growth in both revenues and job creation. That’s why we created MySBA Learning and packed it with in-depth information to help you grow your business."
            },
            "style": {
              "innerStyle": {}
            }
          },
          "style": {
            "backgroundColor": "#F2F7FB"
          },
          "blocks": [{
            "type": "divider",
            "content": {}
          }, 
          {
            "type": "teaser-component",
            "content": {
              "primaryImage": {
                "type": "image",
                "content": {
                  "imageUrl": rewriteUrlWithCDN("/4f/d6/1a3eb313496b9a799165924bb947/top-image.png"),
                  "altText": "Several women climb a platform toward a flag",
                  "altText_spanish": null,
                  "describedBy": null,
                  "describedBy_spanish": null
                }
              },
              "contentWidth": "large",
              "imageWidth": "medium",
              "mobileImageWidth": "medium",
              "verticalAlignment": "bottom",
              "body": "MySBA Learning is divided into major topics called <b>Journeys</b>, developed by experts in entrepreneurship. Choose any journey to start, skip the ones you already know.<br/><br/>Within each Journey, you'll find <b>Excursions</b> with the tools you need to master a topic. Each excursion includes a time estimate for completion. You're the boss of when and how to apply these tools to your business."
            }
          }]
        }
      },
      {
        
      },
      {
        "type": "container",
        "content": {
          "style": {
            "backgroundColor": "#ffffff"
          },
          "blocks": [{
            "type": "card-grid",
            "content": {
              "title": "Things you can explore within excursions:",
              "cards": [{
                "title": "Exercises & Tools",
                "description": "Learn and apply growth practices to your business.",
                "image": rewriteUrlWithCDN("/08/59/ce35333d4e47bccf5ace6aa7969f/hp-icon-tools.png"),
                "image_alt": "Worksheet paper with checkbox, a pencil, and a clock."
              }, {
                "title": "Fireside Chats",
                "description": "Learn from experts about how entrepreneurs grow their businesses.",
                "image": rewriteUrlWithCDN("/81/7a/0d2c25404f49a71be6d706d58c1e/hp-icon-firesidechat.png"),
                "image_alt": "Woman listening to podcast with headphones."
              }, {
                "title": "Infographics",
                "description": "Gain a snapshot view of growth concepts.",
                "image": rewriteUrlWithCDN("/2d/9d/7197fffa46d59fb8e9fae56b8872/infographics.png"),
                "image_alt": "Graph with two points on it."
              }, {
                "title": "Success Stories",
                "description": "Be inspired by stories from real-world entrepreneurs.",
                "image": rewriteUrlWithCDN("/1c/4a/ab00d0c242f1aeadadb9e27477f6/hp-icon-successstories.png"),
                "image_alt": "Trophy with a star in the middle."
              }, {
                "title": "Discussion Guides",
                "description": "Use questions to stimulate thinking for you, your advisors or team.",
                "image": rewriteUrlWithCDN("/c3/72/e790d5c14447851535cfff2b9d5f/hp-icon-discussions.png"),
                "image_alt": "Use questions to stimulate thinking for you, your advisors or team."
              }, {
                "title": "Videos",
                "description": "Grasp key concepts in just minutes.",
                "image": rewriteUrlWithCDN("/ce/27/734168f64b9db09e766a0a231e39/hp-icon-videos.png"),
                "image_alt": "Mac computer screen showing a video with play button in the middle."
              }, {
                "title": "Key Insights",
                "description": "Understand key topics to support your business growth.",
                "image": rewriteUrlWithCDN("/be/06/67042195415587cb6d29afc37b81/hp-icon-key-insights.png"),
                "image_alt": "A brightly-shining light bulb."
              }, {
                "title": "Self-Assessments",
                "description": "Benchmark your current practices through self-focused inventories.",
                "image": rewriteUrlWithCDN("/01/13/94842e81484d8c56262005d05d28/hp-icon-self-assessments.png"),
                "image_alt": null
              }]
            }
          }]
        }
      },
      {
        "type": "container",
        "content": {
          "style": {
            "backgroundColor": "#F2F7FB"
          },
          "blocks": [{
            "type": "teaser-component",
            "content": {
              "primaryImage": {
                "type": "image",
                "content": {
                  "imageUrl": rewriteUrlWithCDN("/98/e5/cefdc73d43468b6bf88af4f06c8d/homepage-bottom-image2.png"),
                  "altText": "A woman stands at the bottom of a mountain path",
                  "altText_spanish": null,
                  "describedBy": null,
                  "describedBy_spanish": null
                }
              },
              "contentWidth": "medium",
              "imageWidth": "large",
              "verticalAlignment": "top",
              "body": null
            }
          }, {
            "type": "button",
            "content": {
              "buttonText": "Start Learning",
              "buttonURL": "/dashboard"
            }
          }, {
            "type": "divider",
            "content": {}
          }, {
            "type": "disclaimer",
            "content": {
              "disclaimerText": "<b>Disclaimer:</b> Any reference obtained from the Ascent site to a specific product, process, or service does not constitute or imply an endorsement by the SBA or the United States Government of the product, process, or service, or its producer or provider. The views and opinions expressed in any referenced document do not necessarily state or reflect those of the SBA or the United States Government. Furthermore, the SBA does not control or guarantee the accuracy, relevance, timeliness or completeness of information contained on linked websites or PDFs."
            }
          }]
        }
      }
    ]
  }

  renderMetadata(data) {
    const props = data || {};
    const metadata = props.metadata || {};
    const defaultDescription =
      "Welcome to MySBA Learning where you will find the resources you need to set and achieve your business goals";
    const siteName = process.env.REACT_APP_SITE_NAME || "Ascent";

    return (
      <MetaTags
        metaTitle={metadata.title || siteName}
        metaDescription={metadata.description || defaultDescription}
        canonicalUrl={process.env.REACT_APP_SITE_ROOT}
      />
    );
  }

  renderContainers(data) {
    if (!data || !data.blocks || !data.blocks.map) {
      return null;
    }

    //return data.blocks.map((container, index) => {
    return this.containers().map((container, index) => {
      return (
        <HomepageBlockManager
          {...container}
          containerIndex={index}
          key={`homepage-container-${index}`}
        />
      );
    });
  }

  render() {
    return (
      <div id="homepage" className="homepage">
        {this.renderMetadata(this.props.homepage)}
        {this.renderContainers(this.props.homepage)}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  homepage: store.homepage,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getHomepageContent: () => dispatch(getHomepageContent()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);

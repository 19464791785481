function sendToGoogle(payload) {
  if (isAnalyticsEnabled()){
    window.gtag("event", "page_view", payload);
  }
}

export function isAnalyticsEnabled() {
  let gaAccount = process.env.REACT_APP_GOOGLE_ANALYTICS_ACCOUNT
  if ( window.gtag !== undefined && gaAccount !== undefined) {
    return true;
  } else {
    return false;
  }
}

export function setTrackingCode(code) {
  if (isAnalyticsEnabled()){
    window.gtag("config", code);
  }
}

export function sendPageView() {
  sendToGoogle({
    page_location: window.location.href,
    page_path: window.location.pathname
  });
}

export function viewForPage(pagePath, pageTitle) {
  let location = window.location.protocol + "//" + window.location.hostname + pagePath;
  sendToGoogle({
    page_location: location,
    page_path: pagePath,
    page_title: pageTitle
  });
}

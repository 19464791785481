import React, { Component } from "react";

import { completeLearningEvent } from "../../actions/learningEventActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ParsedHtml from "../HelperComponents/ParsedHtml";

export class LearningEventDocument extends Component {
  componentDidMount() {
    this.completeEvent();
  }

  componentDidUpdate(prevProps) {
    if (this.props.event !== prevProps.event) {
      this.completeEvent();
    }
  }

  completeEvent() {
    const { isUserLoggedIn, event } = this.props;
    if (isUserLoggedIn && !event.completed) {
      const { id: path_id, topicId: objective_id } = this.props.match.params;

      this.props.dispatch(
        completeLearningEvent(path_id, objective_id, event.id)
      );
    }
  }

  getExtension(filepath) {
    var basename = filepath.split(/[\\/]/).pop();
    var pos = basename.lastIndexOf(".");

    if (basename === "" || pos < 1)
        return "";

    return basename.slice(pos + 1);
  }

  getOpeningTarget(filepath) {
    if(this.getExtension(filepath).toUpperCase() === "PDF") {
      return "_blank"
    } else {
      return "_self"
    }
  }

  renderFiles() {
    const files = this.props.event.files;
    return(
      <ul>
        { files.map((file, idx) => {
          return (
            <li><a href={file['url']} target={this.getOpeningTarget(file['url'])}>{file["title"]}</a></li>
          )
        })}
      </ul>
    )
  }

  render() {
    const __html = this.props.event.eventContent.body;

    return (
      <div className="learning-event-body">
        <ParsedHtml html={__html} />
        { this.renderFiles() }
      </div>
    );
  }
}

const mapStateToProps = store => {
  return { isUserLoggedIn: store.login.isUserLoggedIn };
};

export default withRouter(connect(mapStateToProps)(LearningEventDocument));

import React, { Component } from "react";
import { Button, Grid, Header, Icon } from "semantic-ui-react";
import ContentBlock from "../ContentBlock/ContentBlock";
import ImageContent from "../ContentBlock/ImageContent";
import { connect } from "react-redux";
import { toggleTouring } from "../../actions/touringActions";
import { v4 as uuid } from "uuid";

import "./TouringModal.scss";

export class TouringModalPage extends Component {
  close = () => {
    this.props.closeTouring();
  };

  getMobileImage() {
    if (this.props.mobileImage) {
      return <ImageContent content={this.props.mobileImage.content}/>
    }
    return null;
  }

  getCarousel() {
    const buttons = [];
    for (var i = 0; i < this.props.pages; i++){
      const active = i === this.props.current ? "active" : null;
      const j = i;
      buttons.push(<Icon name="circle" key={uuid()} className={active} onClick={() => this.props.gotoPage(j)}/>);
    }
    return <div id="carousel">{buttons}</div>;
  }

  getButton() {
    const lastPage = this.props.current === this.props.pages - 1;
    return lastPage ? <Button icon="right chevron" labelPosition="right" className="primary" onClick={() => this.close()} content="Finish"/>
                    : <Button icon="right chevron" labelPosition="right" className="primary" onClick={() => this.props.nextPage()} content="Next" data-autofocus/>
  }

  renderBlocks(blocks) {
    if (!blocks) {
      return null;
    }
    return blocks.map(block => <div key={uuid()}>
                                 <ContentBlock key={uuid()} content={block}/>
                               </div>)
  }

  getTitle() {
    if (this.props.isMobile) {
      return this.props.mobileTitle || this.props.title
    } else {
      return this.props.title
    }
  }

  getContent() {
    if (this.props.isMobile) {
      return <div>
               <div className="touring-mobile-image-row">
                { this.getMobileImage() }
               </div>
               <div className="carousel-row">
                 { this.getCarousel() }
               </div>
               <div>
                 { this.renderBlocks(this.props.mobileContent) }
               </div>
               <div className="carousel-action">
                 { this.getButton() }
               </div>
             </div>
    } else {
      return <div>
               <div className="desktop-content-row">
                 { this.renderBlocks(this.props.desktopContent) }
               </div>
               <div className="carousel-action">
                 { this.getButton() }
               </div>
               <div className="carousel-row">
                 { this.getCarousel() }
               </div>
            </div>
    }
  }

  render() {
    return <div className={this.props.inactive ? 'inactive' : ''}>
      <Grid className="touring-content-header">
        <Grid.Row columns={16}>
          <Grid.Column width={14}>
            <Header>
              {this.getTitle()}
            </Header>
          </Grid.Column>
          <Grid.Column width={2}>
            <Button
              aria-label="Close"
              className="eli-modal-close-button"
              onClick={this.props.closeTouring}
              icon
            >
              <Icon
                link
                name="close"
                size="large"
                className="touring-close"
              />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <hr />
      <div className="touring-content">
        {this.getContent()}
      </div>
    </div>
  }
}

const mapStateToProps = (store) => ({
  isMobile: store.global.isMobile
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeTouring: () => dispatch(toggleTouring(false)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TouringModalPage);

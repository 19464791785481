import React, { Component } from "react";
import "./Disclaimers.scss";

export default class FiresideChatDisclaimer extends Component {
  render() {
    const text = this.props.disclaimerText;
    return (
      <div id="fireside-chat-disclaimer">
        <div id="disclaimer-heading">DISCLAIMER:</div>
        <div id="disclaimer-text">
          { text }
        </div>
      </div>
    );
  }

}

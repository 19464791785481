import RegistrationModal from "../components/RegistrationModal/RegistrationModal";

function defaultState() {
  return {
    open: false,
    profileModal: false,
    modalType: "signup",
    forcePathDropdownClose: false
  };
}

export default function reducer(state = defaultState(), action) {
  switch (action.type) {
    case "RESET":
      return defaultState();
    case "TOGGLE_REGISTER_MODAL":
      return {
        ...state,
        open: action.payload,
        modalType: RegistrationModal.types.signup
      };
    case "TOGGLE_FORGOT_PASSWORD_MODAL":
      return {
        ...state,
        open: action.payload,
        modalType: RegistrationModal.types.forgotpassword
      };
    case "TOGGLE_RESET_PASSWORD_MODAL":
      return {
        ...state,
        open: action.payload,
        modalType: RegistrationModal.types.resetpassword
      };
    case "TOGGLE_CONFIRMATION":
      return {
        ...state,
        open: action.payload,
        modalType: RegistrationModal.types.confirm
      };
    case "TOGGLE_USER_PROFILE_CONFIRMATION":
      return {
        ...state,
        profileModal: action.payload
      }
    case "REGISTER_FULFILLED":
      return {
        ...state,
        open: true,
        modalType: RegistrationModal.types.success
      };
    case "LOGIN_FULFILLED", "SUBMIT_CREDENTIALS_FULFILLED":      
      return {
        ...state,
      };
    case "FORGOT_PASSWORD_FULFILLED":
      return {
        ...state,
        open: true,
        modalType: RegistrationModal.types.forgotpassword
      };
    case "RESET_PASSWORD_FULFILLED":
      return {
        ...state,
        open: true,
        modalType: RegistrationModal.types.resetpassword
      };
    case "FORCE_PATH_DROPDOWN_CLOSE":
      return {
        ...state,
        forcePathDropdownClose: action.payload
      };
    default:
      break;
  }

  return state;
}

import React, { Component } from "react";
import ParsedHtml from "../../../HelperComponents/ParsedHtml";
import { getTranslation } from "../../../../utils/HelperFunctions";
import { withTranslation } from 'react-i18next';

class StyledText extends Component {
  render() {
    const { content } = this.props;
    const style = this.props.style || {};
    const className = style.className || "";
    const innerStyle = style.innerStyle;

    if (content) {
      return (
        <div style={innerStyle} className={`styled-text ${className}`}>
          <ParsedHtml html={getTranslation(content, "text")} />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withTranslation()(StyledText);
import React from 'react';
import {Dropdown, Input, Label, Message} from 'semantic-ui-react';
import {Grid} from 'semantic-ui-react';

import "./NumericQuestionWithLabel.scss"

export default class NumericQuestionWithLabel extends React.Component {
  handleRef = component => (this.ref = component);
  state = {}

  constructor(props) {
    super(props);
    this.numberChangeHandler = this.numberChangeHandler.bind(this);
    this.labelChangeHandler = this.labelChangeHandler.bind(this);
  }

  getNumber() {
    return this.props.value ? this.props.value.substring(0, this.props.value.indexOf(' ')) : '';
  }

  getOptions() {
    return (this.props.options || []).map((option) => { return {text: option, value: option, key: option}; });
  }

  getLabel() {
    if (!this.props.value) {
      if (this.props.options && this.props.options.length) {
        return this.props.options[0];
      }
      return '';
    }
    return this.props.value.substring(this.props.value.indexOf(' ') + 1) || this.props.options[0];
  }

  componentDidMount() {
    if (this.ref) {
      this.ref.focus();
    }
  }

  onChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  isNumeric(value) {
    return value.match(/^\d*$/);
  }

  numberChangeHandler(event, data) {
    if (data && this.isNumeric(data.value)) {
      this.setState({error: false});
      this.onChange(data.value + ' ' + this.getLabel());
    } else { 
      this.setState({error: true});
    }
  }

  labelChangeHandler(event, data) {
    if (data) {
      this.onChange(this.getNumber() + ' ' + data.value);
    }
  }

  getRef() {
    return this.props.autoFocus ? this.handleRef : null
  }

  render() {
    return (
      <Grid className="numeric-question-with-label">
        <Grid.Row>
          <Grid.Column>
            <p className="numeric-question-with-label-text"><b>{this.props.text}</b></p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} stretched>
            <Input fluid ref={this.getRef()}
                     error={this.state.error}
                     aria-label={this.props.text} 
                     onChange={this.numberChangeHandler} 
                     value={this.getNumber()}
                     className="numeric-question-input"/>
          </Grid.Column>
          <Grid.Column className={"numeric-label-column"} width={10} stretched>
            <Label className="numeric-label"><Dropdown options={this.getOptions()} defaultValue={this.getLabel()} onChange={this.labelChangeHandler}/></Label>
          </Grid.Column>
          <Grid.Column width={7}>
            {this.state.error ? <Message negative>Numeric values only</Message> : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}
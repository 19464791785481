import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { ImagePlaceholder, StripHtmlTags } from "../../utils/HelperFunctions";

export default class MetaTags extends Component {
  getMetaTitle(pageTitle) {
    const siteName = process.env.REACT_APP_SITE_NAME || "Ascent";
    return pageTitle ? pageTitle : siteName;
  }

  getMetaDescription(description) {
    return description
      ? StripHtmlTags(description)
      : "We support America's small businesses. The SBA connects entrepreneurs with lenders and funding to help them plan, start and grow their business.";
  }

  getImage(image) {
    //An image url cannot be relative so If the url does not start with the site
    //root (url is a relative link) we prepend the site root variable to the url
    const siteRoot = process.env.REACT_APP_SITE_ROOT;
    return image.startsWith("http")
      ? image
      : image.startsWith("/")
      ? `${siteRoot}${image}`
      : `${siteRoot}/${image}`;
  }

  getCanonicalUrl(url) {
    //A canonical url cannot be relative so If the url does not start with the site
    //root (url is a relative link) we prepend the site root variable to the url
    const siteRoot = process.env.REACT_APP_SITE_ROOT;
    if (url) {
      return url.startsWith(siteRoot) || url === siteRoot
        ? url
        : `${siteRoot}${url}`;
    } else {
      return siteRoot;
    }
  }

  render() {
    const metaProps = [
      {
        name: "description",
        content: this.getMetaDescription(this.props.metaDescription),
      },
      {
        property: "og:title",
        content: this.getMetaTitle(this.props.metaTitle),
      },
      {
        property: "og:description",
        content: this.getMetaDescription(this.props.metaDescription),
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:image",
        content: this.getImage(ImagePlaceholder(this.props.metaImage)),
      },
      {
        property: "og:url",
        content: this.getCanonicalUrl(this.props.canonicalUrl),
      },
    ];

    const linkProps = [
      {
        rel: "canonical",
        href: this.getCanonicalUrl(this.props.canonicalUrl),
      },
    ];
    return (
      <Helmet
        title={this.getMetaTitle(this.props.metaTitle)}
        meta={metaProps}
        link={linkProps}
      />
    );
  }
}

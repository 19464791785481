import React, { Component } from "react";
import { Icon } from "semantic-ui-react";

import './CheckmarkCompleted.scss';

export default class CheckmarkCompleted extends Component {
  render() {
    return (
      <span className="checkmark-completed-container">
        <Icon
          className="checkmark-completed"
          name="check circle outline"
          size="large"
        />
      </span>
    );
  }
}
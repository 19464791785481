import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { linkToDashboard } from "../../../utils/InternalLinks";

export class HomepageButton extends Component {
  render() {
    const content = this.props.content || {};
    return (
      <Button
        className="homepage-button"
        size="large"
        onClick={() => {
          this.props.history.push(content.buttonURL || linkToDashboard());
        }}
        content={content.buttonText || "Dashboard"}
      />
    );
  }
}

export default withRouter(HomepageButton);

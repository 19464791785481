import React from 'react';
import {Container, Input} from 'semantic-ui-react';

import "./TextQuestion.scss"

export default class TextQuestion extends React.Component {
  handleRef = component => (this.ref = component);

  componentDidMount() {
    if (this.ref) {
      this.ref.focus();
    }
  }

  onChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    return (
      <Container>
        <p className="text-question-text"><b>{this.props.text}</b></p>
        <Input fluid ref={this.props.autoFocus ? this.handleRef : null } aria-label={this.props.text} onChange={(event, data) => this.onChange(data.value)} value={this.props.value}/>
      </Container>
    )
  }
}
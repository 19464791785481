import React, { Component } from "react";
import { Button, Icon } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';


class DownloadButton extends Component {
  render() {
    const { t, url } = this.props;

    return (
      <Button
        icon
        as="a"
        href={url}
        aria-label={ t('downloadButton.buttons.download') }
        download
        className="header-button download-button"
        floated="right"
        target="_blank"
      >
        <Icon name="download" />
      </Button>
    );
  }
}

export default withTranslation()(DownloadButton);

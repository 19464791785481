import { viewForPage } from "../utils/AnalyticsFunctions";
import get from "lodash/get";

function defaultState() {
  return {
    learningEvent: {},
    learningEventLoading: false,
    learningEventError: null,

    learningEventsCollection: {},
    learningEventsLoading: false,
    learningEventsError: null,
    progress: [],

    completeEventError: null,

    mediaTranscriptContent: null,

    submitQuiz: null,
    submitQuizLoading: null,
    submitQuizError: null,
  };
}

export default function reducer(state = defaultState(), action) {
  switch (action.type) {
    case "RESET":
      return defaultState();
    case "GET_LEARNING_EVENT_PENDING":
      return {
        ...state,
        learningEvent: {},
        learningEventLoading: true,
        learningEventError: null,
      };
    case "GET_LEARNING_EVENT_FAILURE":
      return {
        ...state,
        learningEvent: {},
        learningEventLoading: false,
        learningEventError: action.payload,
      };
    case "GET_LEARNING_EVENT_FULFILLED":
      return {
        ...state,
        learningEvent: action.payload.data.path,
        learningEventLoading: false,
        learningEventError: null,
      };
    case "GET_LEARNING_EVENTS":
      return {
        ...state,
        learningEventsLoading: true,
        learningEventsError: null,
      };
    case "GET_LEARNING_EVENTS_FAILURE":
      return {
        ...state,
        learningEventsLoading: false,
        learningEventsError: action.payload,
      };
    case "GET_LEARNING_EVENTS_FULFILLED": {
      const { journeyId, excursionId } = action.meta;

      return {
        ...state,
        learningEventsLoading: false,
        learningEventsError: null,
        learningEventsCollection: {
          ...state.learningEventsCollection,
          [journeyId]: {
            ...state.learningEventsCollection[journeyId],
            [excursionId]: get(action.payload.data, "excursion.paths", []),
          },
        },
      };
    }
    case "GET_PROGRESS_FOR_PATHS_FULFILLED":
      return {
        ...state,
        learningEventsLoading: false,
        learningEventsError: null,
        progress: action.payload.data.path_progress,
      };
    case "COMPLETE_LEARNING_EVENT_PENDING":
      return {
        ...state,
        completeEventError: null,
      };
    case "COMPLETE_LEARNING_EVENT_FAILURE":
      return {
        ...state,
        completeEventError: action.payload,
      };
    case "COMPLETE_LEARNING_EVENT_FULFILLED": {
      let gaAccount = process.env.REACT_APP_GOOGLE_ANALYTICS_ACCOUNT;

      if (gaAccount !== undefined) {
        viewForPage(
          window.location.href,
          `${state.learningEvent.title} Completed`
        );
      }

      return {
        ...state,
        completeEventError: null,
      };
    }
    case "SET_TRANSCRIPT_CONTENT_TYPE":
      return {
        ...state,
        mediaTranscriptContent: action.transcriptType,
      };
    case "SUBMIT_QUIZ_PENDING":
      return {
        ...state,
        submitQuiz: null,
        submitQuizLoading: true,
        submitQuizError: null,
      };
    case "SUBMIT_QUIZ_FAILURE":
      return {
        ...state,
        submitQuiz: null,
        submitQuizLoading: false,
        submitQuizError: action.payload,
      };
    case "SUBMIT_QUIZ_FULFILLED":
      return {
        ...state,
        submitQuiz: action.payload.data,
        submitQuizLoading: false,
        submitQuizError: null,
      };
    case "CLEAR_SUBMIT_QUIZ":
      return {
        ...state,
        submitQuiz: null,
        submitQuizLoading: false,
        submitQuizError: null,
      };
    default:
      break;
  }

  return state;
}

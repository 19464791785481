function defaultState() {
  return {
    ...mainNavDefault
  };
}

const mainNavDefault = {
  mainNav: {
    activePathNavIndex: null,
    activePathId: null,
    innerNavActive: false,
    activeTopicIndex: null,
    activeTopicId: null
  }
};

export default function reducer(state = defaultState(), action) {
  switch (action.type) {
    case "RESET":
      return defaultState();
    case "RESET_MAIN_NAV":
      return {
        ...state,
        ...mainNavDefault
      };
    case "SET_PATH_NAV_ACTIVE_INDEX":
      return {
        ...state,
        mainNav: {
          ...state.mainNav,
          activePathNavIndex: action.activeIndex,
          activePathId: action.activeId
        }
      };
    case "SET_TOPIC_NAV_ACTIVE_INDEX":
      return {
        ...state,
        mainNav: {
          ...state.mainNav,
          innerNavActive: true,
          activeTopicId: action.activeTopicId,
          activeTopicIndex: action.activeIndex
        }
      };

    case "RESET_TOPIC_NAV_ACTIVE_INDEX":
      return {
        ...state,
        mainNav: {
          ...state.mainNav,
          innerNavActive: false,
          activeTopicId: null,
          activeTopicIndex: null
        }
      };
    default:
      break;
  }

  return state;
}

import DemographicModal from "../components/Demographics/DemographicModal";

function defaultState() {
  return {
    demographicDetails: {
      gender: "",
      race: [],
      ethnicity: "",
      business_name: "",
      percent_business_owned: "",
      number_employees: "",
      business_types: "",
      revenue_goal: "",
      employment_goal: "",
      in_business: "",
      user_code: "",
      certified_for_contracting: "",
      certifications: "",
      current_procuring: "",
      military_status: "",
    },
    open: false,
    modalType: "demographic",
    userError: null,
  }
}

function stateAfter(state) {
  switch (state) {
    case DemographicModal.types.demographic:
      return DemographicModal.types.business;
    case DemographicModal.types.business:
      return DemographicModal.types.businessgoals;
    case DemographicModal.types.businessgoals:
      return DemographicModal.types.success;
    default:
      throw new Error("Tried to navigate forward past " + state);
  }
}

function stateBefore(state) {
  switch (state) {
    case DemographicModal.types.business:
      return DemographicModal.types.demographic;
    case DemographicModal.types.businessgoals:
      return DemographicModal.types.business;
    default:
      throw new Error("Tried to navigate backward past " + state);
  }
}

export default function reducer(state = defaultState(), action) {
  switch(action.type) {
    case "TOGGLE_DEMOGRAPHICS_MODAL":
      return {
        ...state,
        open: action.payload.show,
      };
    case "CLOSE_DEMOGRAPHIC_MODAL":
      return {
        ...state,
        open: false,
      }
    case "SAVE_DEMOGRAPHIC_DETAILS_FULFILLED":
      return {
        ...state,
        open: true,
        modalType: DemographicModal.types.success
      };
    case "PREVIOUS_DEMOGRAPHIC_PAGE":
      return {
        ...state,
        open: true,
        modalType: stateBefore(state.modalType)
      };
    case "UPDATE_DEMOGRAPHIC_DETAILS":
      return {
        ...state,
        demographicDetails: {
          ...state.demographicDetails,
          [action.payload.key]: action.payload.value,
        },
      };
    case "NEXT_DEMOGRAPHIC_PAGE":
      return {
        ...state,
        open: true,
        modalType: stateAfter(state.modalType)
      };
    default:
      break;
  }

  return state;
}
export const staticTimeFilters = [
  { key: "short", text: "Less than 1 minute" },
  { key: "medium", text: "2 - 5 minutes" },
  { key: "long", text: "5 minutes +" },
];

/*const subjectFilters = [
  {key: 'finance', text: 'Finance'},
  {key: 'communications', text: 'Communications'},
  {key: 'management', text: 'Management'},
  {key: 'marketing', text: 'Marketing'},
  {key: 'sales', text: 'Sales'},
  {key: 'strategy', text: 'Strategy'},
];*/

export function staticMediaFilters() {
  if (process.env.REACT_APP_HIDE_SEARCH_FILTERS !== "true") {
    return ascentMediaFilters;
  } else {
    return lcStaticMediaFilters;
  }
}

export const ascentMediaFilters = [
  { key: "video", text: "Video" },
  { key: "fireside-chat", text: "Fireside Chat" },
  { key: "success-story", text: "Success Story" }, //Case study
  { key: "self-assessment", text: "Self-Assessment" }, //Quiz and assessments
  { key: "discussion", text: "Discussion Guide" },
  { key: "key-insights", text: "Key Insights" }, //Newsletter
  { key: "infographic", text: "Infographic" },
  { key: "tool", text: "Tool" }, //Exercise or Worksheet
  { key: "file", text: "File" },
];

export const lcStaticMediaFilters = [
  { key: "video", text: "Video" },
  { key: "file", text: "File" },
];

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "../HelperComponents/PrivateRoute";
import ClosedRoute from "../HelperComponents/ClosedRoute";
import Homepage from "../Homepage/Homepage";
import NotFound from "../Errors/NotFound";
import JourneyContentWrapper from "../JourneyContentWrapper/JourneyContentWrapper";
import SearchPage from "../Search/SearchPage";
import Profile from "../Profile/Profile";
import Journeys from "../Journeys";
import Journey from "../Journey";
import Dashboard from "../Dashboard/Dashboard";
import {
  linkToDashboard,
  linkToJourneys,
  linkToSearch
} from "../../utils/InternalLinks";
import { isHomepageDisabled } from "../../utils/EnvironmentFunctions";

const NavigationDisplay = () => {
  return (
    <Switch>
      <Route exact path="/">
        { isHomepageDisabled() ? <Redirect to="/dashboard" /> : <Homepage /> }
      </Route>
      <Route exact path="/reset_password_form" component={Homepage} />
      <Route exact path="/forgot_password" component={Homepage} />
      <Route exact path="/confirm" component={Homepage} />
      <Route exect path="/email_confirmation" component={Dashboard} />
      <Route exact path="/login" component={Dashboard} />
      <Route exact path="/touring" component={Dashboard}/>
      <Route exact path="/demographics" component={Dashboard} />
      <PrivateRoute exact path="/profile" component={Profile} />
      <Route exact path={linkToSearch()} component={SearchPage} />
      <Route exact path={linkToJourneys()} component={Journeys} />
      <Route exact path={linkToDashboard()} component={Dashboard} />
      {/* Make Sure This /:id Route is always last behind other first level routes */}
      <ClosedRoute
        exact
        path="/:id/:topicId/:eventId"
        component={JourneyContentWrapper}
      />
      <ClosedRoute
        exact
        path="/:id/:topicId"
        component={JourneyContentWrapper}
      />
      <ClosedRoute exact path="/:id" component={Journey} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default NavigationDisplay;

import PropTypes from "prop-types";
import React from "react";

export class SkipLink extends React.Component {
  skipToId(e, targetId) {
    e.preventDefault();
    const element = document.getElementById(targetId);
    element.focus();
    element.scrollIntoView();
  }

  keyDown(e, targetId) {
    if (e.keyCode === "32") {
      this.skipToId(e, targetId);
    }
  }

  render() {
    const { id, targetDomId, text } = this.props;
    return (
      <a
        id={id}
        href={"#" + targetDomId}
        onClick={(e) => this.skipToId(e, targetDomId)}
        onKeyDown={(e) => this.keyDown(e, targetDomId)}
      >
        {text}
      </a>
    );
  }
}

SkipLink.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  targetDomId: PropTypes.string,
};

export default SkipLink;

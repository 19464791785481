import React from "react";
import { Button, Icon } from "semantic-ui-react";

const FullscreenButton = props => (
  <Button icon basic onClick={props.onClickFullscreen} aria-label="fullscreen">
    <Icon name="expand" size="big" inverted />
  </Button>
);

export default FullscreenButton;

import React, { Component } from "react";
import { Container, Divider, Grid, Header } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';
import CardList from "./CardList";
import PropTypes from "prop-types";

import "./CardContainer.scss";

class CardContainer extends Component {
  render() {
    const {
      cardHeader,
      cardItems,
      loading,
      journeyId,
      progressItems
    } = this.props;

    return (
      <Grid id="card-grid">
        <Grid.Row id="card-row">
          <Grid.Column>
            <Container id="card-container" tabIndex="-1">
              <Grid.Row
                columns={1}
                id="card-header"
              >
                <Header as="h2">{cardHeader}</Header>
                <Divider />
              </Grid.Row>
              <CardList
                journeyId={journeyId}
                cardItems={cardItems}
                loading={loading}
                progressItems={progressItems}
              />
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

CardContainer.propTypes = {
  cardHeader: PropTypes.string,
  cardItems: PropTypes.array,
  loading: PropTypes.bool
};

export default withTranslation()(CardContainer);
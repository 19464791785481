export function toggleLogin(show) {
  return {
    type: "TOGGLE_LOGIN_MODAL",
    payload: show
  };
}

export function toggleRegister(show) {
  return {
    type: "TOGGLE_REGISTER_MODAL",
    payload: show
  };
}

export function toggleForgotPassword(show) {
  return {
    type: "TOGGLE_FORGOT_PASSWORD_MODAL",
    payload: show
  };
}

export function toggleResetPassword(show) {
  return {
    type: "TOGGLE_RESET_PASSWORD_MODAL",
    payload: show
  };
}

export function toggleConfirmation(show) {
  return {
    type: "TOGGLE_CONFIRMATION",
    payload: show
  };
}

export function toggleProfileConfirmation(show) {
  return {
    type: "TOGGLE_USER_PROFILE_CONFIRMATION",
    payload: show
  }
}

export function forcePathDropdownCloseToggle(force) {
  return {
    type: "FORCE_PATH_DROPDOWN_CLOSE",
    payload: force
  };
}


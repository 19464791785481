import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Grid } from "semantic-ui-react";
import JourneyResume from "./JourneyResume";
import DashboardCTA from "./DashboardCTA";
import { getLatestUserProgress } from "../../actions/journeyActions";

import "./JourneyResumeWrapper.scss";

export class JourneyResumeWrapper extends Component {
  componentDidMount() {
    const isUserLoggedIn = this.props.isUserLoggedIn;

    if (isUserLoggedIn) {
      this.props.getLatestUserProgress();
    }
  }

  componentDidUpdate(prevProps) {
    const isUserLoggedIn = this.props.isUserLoggedIn;

    if (isUserLoggedIn && prevProps.isUserLoggedIn !== isUserLoggedIn) {
      this.props.getLatestUserProgress();
    }
  }

  userView(allowProgress, requireUser) {
    const { scrollToRef, latestUserEnrollment } = this.props;

    if (allowProgress && latestUserEnrollment && latestUserEnrollment.journey) {
      const journeyId = latestUserEnrollment.journey.course_id;
      return <JourneyResume journeyId={journeyId} />;
    } else if (requireUser) {
      return <DashboardCTA scrollToRef={scrollToRef} />;
    }
  }

  render() {
    const requireUser =
      process.env.REACT_APP_REQUIRE_USER_TO_TRACK_PROGRESS !== "false";
    const { isUserLoggedIn, hasUserStartedJourney } = this.props;
    const allowProgress = isUserLoggedIn || !requireUser;

    if (!allowProgress || (allowProgress && hasUserStartedJourney)) {
      const userView = this.userView(allowProgress, requireUser);
      if (userView) {
        return (
          <Container id="journey-resume-component">
            <Grid>
              <Grid.Row>{this.userView(allowProgress, requireUser)}</Grid.Row>
            </Grid>
          </Container>
        );
      }
    }
    return null;
  }
}

const mapStateToProps = (store) => {
  return {
    isUserLoggedIn: store.login.isUserLoggedIn,
    hasUserStartedJourney: store.journey.hasUserStartedJourney,
    latestUserEnrollment: store.journey.latestUserEnrollment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLatestUserProgress: () => dispatch(getLatestUserProgress()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JourneyResumeWrapper)
);

import React from "react";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { toggleRegister } from "../../actions/navbarActions";

export const RegisterButton = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <Button className="register-button" primary onClick={() => props.toggleRegister(true)}>
      { t('registerButton.buttonText') }
    </Button>
  );
};

const mapStateToProps = store => {
  return {
    modalType: store.navbar.modalType,
    open: store.navbar.open,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    toggleRegister: (show) => { dispatch(toggleRegister(show)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterButton);

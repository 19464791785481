import React, { Component } from "react";

export class DropdownItem extends Component {
  constructor(props) {
    super(props);

    this.itemRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { active } = this.props;

    if (active && active !== prevProps.active) {
      if (this.itemRef && this.itemRef.current) {
        this.itemRef.current.focus();
      }
    }
  }

  render() {
    const { active, text, ariaLabel, customChildren } = this.props;
    const activeClass = active ? " active selected" : "";
    const className = this.props.className || "";

    return (
      <div
        role="option"
        aria-selected={active}
        aria-label={ariaLabel}
        tabIndex={-1}
        ref={this.itemRef}
        className={`${className} item simple-dropdown-item${activeClass}`}
        onClick={() => this.props.action()}
      >
        <span className="text">{text}</span>
        {customChildren}
      </div>
    );
  }
}

export default DropdownItem;

import React, { Component } from "react";
import { Card } from "semantic-ui-react";

import CardItem from "./CardItem";
import CardLoadingItem from "./CardLoadingItem";
import PropTypes from "prop-types";
import { getJourneyName, getTranslation } from "../../utils/HelperFunctions";

export default class CardList extends Component {
  cardLoading() {
    return (
      <React.Fragment>
        <CardLoadingItem key="card-loading1" />
        <CardLoadingItem key="card-loading2" />
        <CardLoadingItem key="card-loading3" />
      </React.Fragment>
    );
  }

  getProgressItem(journeyId, id) {
    const progressItems = this.props.progressItems || [];

    //If there is a journeyId it has excursions progress data
    if (journeyId) {
      return progressItems[id];
    } else {
      const item = progressItems.find((item) => item.id === id) || {};
      return item.course_progress;
    }
  }

  cardItems() {
    const { isUserLoggedIn, journeyId } = this.props;

    return this.props.cardItems.map((c) => {
      const progressItem = this.getProgressItem(journeyId, c.id);

      return (
        <CardItem
          key={`card-item-${c.id}`}
          id={c.id}
          name={journeyId ? getTranslation(c, "name") : getJourneyName(c)}
          courseCode={c.course_code}
          progress={progressItem}
          custom_data={c.custom_data}
          isUserLoggedIn={isUserLoggedIn}
          journeyId={journeyId}
        />
      );
    });
  }

  render() {
    const result = this.props.loading ? this.cardLoading() : this.cardItems();

    return (
      <Card.Group tabIndex="-1" id="card-list">
        {result}
      </Card.Group>
    );
  }
}

CardList.propTypes = {
  cardItems: PropTypes.array,
  loading: PropTypes.bool,
};

import React, { Component } from "react";
import { Grid, Progress } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';
import DynamicImage from "../HelperComponents/DynamicImage";
import { connect } from "react-redux";

export class PathProgressBar extends Component {
  render() {
    const { pathsComplete, pathsTotal, pathsProgressPercent} = this.props
    const { t } = this.props;
    console.log("****************************************************")
    console.log(t);
    console.log("****************************************************")
    return (
      <Grid>
        <Grid.Row className="path-progress-image-row">
          <Grid.Column className="path-progress-column">
            { this.props.progressPicture && <DynamicImage {...this.props.progressPicture} centered/> }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="path-progress-header-row">
          <Grid.Column className="path-progress-column">
            <b id="path-progress-bar-text">{ t('pathProgressBar.title') }</b>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="path-progress-bar-row">
          <Grid.Column>
            <Progress
              percent={pathsProgressPercent}
              color={"blue"}
              size="tiny"
              className="path-progress-bar"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="path-progress-footer-row">
          <Grid.Column>
            <div className="path-progress-meta">
              {pathsComplete} { t('pathProgressBar.outOf') } {pathsTotal} { t('pathProgressBar.completed') }!
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

const mapStateToProps = store => {
  return {
    progressPicture: store.json.progressPicture,
    journeyTerm: store.json.journeyTerm,
    pathTerm: store.json.pathTerm
  };
};

export default withTranslation()(connect(mapStateToProps)(PathProgressBar));

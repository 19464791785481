import { viewForPage } from "../utils/AnalyticsFunctions";

function defaultState() {
  return {
    userData: {
      first_name: "",
      middle_name: "",
      last_name: "",
      zip_code: "",
      email: "",
      in_business: "",
      password: "",
      errors: {},
    },
    registrationDetails: {
      gender: "",
      race: [],
      ethnicity: "",
      business_name: "",
      percent_business_owned: "",
      number_employees: "",
      business_types: "",
      revenue_goal: "",
      employment_goal: "",
      in_business: "",
      user_code: "",
    },
    confirmationData: {
      errors: {},
    },
    onPasswordSubmit: false,
    userConfirmed: false,
  };
}

export default function reducer(state = defaultState(), action) {
  let gaAccount = process.env.REACT_APP_GOOGLE_ANALYTICS_ACCOUNT;
  let gaRegisterPathVar = "register";

  switch (action.type) {
    case "RESET":
      return defaultState();
    case "REGISTER":
      if (gaAccount !== undefined) {
        viewForPage(
          `/${gaRegisterPathVar}`,
          "Register"
        );
      }
      return {
        ...state,
        userData: {},
        userLoading: true,
        userError: null,
      };
    case "REGISTER_REJECTED": {
      if (gaAccount !== undefined) {
        viewForPage(
          `/${gaRegisterPathVar}/rejected`,
          "Registration Rejected"
        );
      }

      return {
        ...state,
        userData: null,
        userLoading: false,
        userError: registrationErrors(action.payload),
      };
    }
    case "REGISTER_FULFILLED":
      if (gaAccount !== undefined) {
        viewForPage(
          `/${gaRegisterPathVar}/success`,
          "Registration Completed"
        );
      }
      return {
        ...state,
        userData: action.payload.data || {},
        userLoading: false,
        userError: null,
      };
    case "CONFIRM_ACCOUNT_FULFILLED":
      return {
        ...state,
        userConfirmed: true,
      };
    case "CONFIRM_ACCOUNT_REJECTED":
      return {
        ...state,
        confirmationData: action.payload.response.data,
      };
    case "FORGOT_PASSWORD":
      return {
        ...state,
        userData: action.payload.response.data,
        userLoading: false,
        userError: null,
        onPasswordSubmit: false,
      };
    case "FORGOT_PASSWORD_FULFILLED":
      return {
        ...state,
        onPasswordSubmit: true,
      };
    case "FORGOT_PASSWORD_REJECTED":
      let error = registrationErrors(action.payload);
      return {
        ...state,
        userData: action.payload.response.data,
        userLoading: false,
        userError: error,
        onPasswordSubmit: false,
      };
    case "RESET_PASSWORD":
      return {
        ...state,
        userData: action.payload.response.data,
        userLoading: false,
        userError: null,
        onPasswordSubmit: false,
      };
    case "RESET_PASSWORD_FULFILLED":
      return {
        ...state,
        onPasswordSubmit: true,
      };
    case "RESET_PASSWORD_REJECTED":
      return {
        ...state,
        userData: action.payload.response.data,
        userLoading: false,
        userError: action.payload,
        onPasswordSubmit: false,
      };
    case "USER_IN_BUSINESS":
      return {
        ...state,
        userInBusiness: action.payload,
      };
    case "CLEAR_USER_ERROR":
      return {
        ...state,
        userError: null,
        onPasswordSubmit: false,
      }
    default:
      break;
  }

  return state;
}

function registrationErrors(payload) {
  const response = payload.response || {};
  switch (response.status) {
    case 500: {
      return [
        "We have encountered an error with our server, please try again in a few minutes",
      ];
    }
    case 422: {
      return Object.keys(response.data.json.errors).reduce(
        (list, c) => list.concat(response.data.json.errors[c]),
        []
      );
    }
    default:
      return [payload.message];
  }
}

import axios from "axios";

export const contentClient = () => {
  const previewContent = process.env.REACT_APP_ENABLE_PREVIEW_CONTENT;
  const previewHeader = previewContent
    ? { "X-PREVIEW-CONTENT": previewContent }
    : {};

  return axios.create({
    baseURL: process.env.REACT_APP_CONTENT_HOST,
    headers: {
      "X-SITE-NAME": process.env.REACT_APP_SITE_NAME,
      ...previewHeader,
    },
  });
};

export const userClient = (state) => {
  return axios.create({
    baseURL: process.env.REACT_APP_SERVICE_HOST,
    ...axiosConfigForUser(state),
  });
};

function axiosConfigForUser(state) {
  let authConfig = {};
  if (state.login.isUserLoggedIn) {
    const { access_token } = state.login.userData;
    const user = state.login.userData.user || {};
    const csrf_token = user.csrf_token;
    authConfig = {
      headers: { AUTHORIZATION: access_token, "X-CSRF-TOKEN": csrf_token },
    };
  }

  return authConfig;
}

import React, { Component } from "react";
import { Button, Form, Icon, Input } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { resetFacets } from '../../actions/searchActions';
import queryString from "query-string";
import { withTranslation } from 'react-i18next';

export class NavbarSearchInput extends Component {
  state = {
    searchTerm: ""
  };

  handleSubmit = () => {
    let query = {
      searchTerm: this.state.searchTerm
    };

    if(this.props.location.pathname !== '/search') {
      this.props.dispatch(resetFacets());
    } else {
      query = {
        ...query,
        media_types: this.props.mediaFilter,
        time: this.props.durationFilter,
        subject: this.props.subjectFilter
      }
    }

    this.props.history.push("/search?" + queryString.stringify(query));
  };

  searchTermChanged = e => {
    this.setState({ searchTerm: e.target.value });
  };

  render() {
    const { t } = this.props;

    return (
      <Form
        id="navigation_site_search"
        role="search"
        onSubmit={this.handleSubmit.bind(this)}
      >
        <Input
          fluid={this.props.isMobile}
          placeholder={ t('search') }
          name="searchTerm"
          className="search-input"
          value={this.state.searchTerm}
          onChange={this.searchTermChanged.bind(this)}
          role="textbox"
          aria-label="Input search terms here"
          label={
            <Button type="submit" role="button">
              {!this.props.isMobile ?
                t('submit') : (<Icon name="search"/>)
              }
            </Button>
          }
          labelPosition="right"
        />
      </Form>
    )
  }
}

const mapStateToProps = store => {
  return {
    isMobile: store.global.isMobile,
    durationFilter: store.search.durationFilter,
    subjectFilter: store.search.subjectFilter,
    mediaFilter: store.search.mediaFilter
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(NavbarSearchInput)));
import React, { Component } from "react";
import { Image } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  imageViewPortSuffix,
  rewriteUrlWithCDN,
} from "../../utils/HelperFunctions";

export class DynamicImage extends Component {
  //We want to pass through all props except for these to the image component
  filterProps(props) {
    const filterKeys = ["dispatch", "activeImageViewPort", "src"];
    let filteredProps = {};
    Object.keys(props).forEach((key) => {
      if (!filterKeys.includes(key)) {
        filteredProps = {
          ...filteredProps,
          [key]: props[key],
        };
      }
    });
    filteredProps.src = rewriteUrlWithCDN(props.src);
    return filteredProps;
  }

  render() {
    const { className, size, activeImageViewPort } = this.props;
    const srcAppend = imageViewPortSuffix(className, size, activeImageViewPort);

    const newProps = this.filterProps({
      ...this.props,
      src: this.props.src + srcAppend,
    });
    return <Image {...newProps} />;
  }
}

const mapStateToProps = (store) => {
  return {
    activeImageViewPort: store.global.activeImageViewPort,
  };
};

export default connect(mapStateToProps)(DynamicImage);

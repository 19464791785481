import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import {
  getJourney,
  getProgressForJourney
} from "../../actions/journeyActions";
import { getProgressForExcursions } from "../../actions/excursionActions";

import Journey from "./Journey";

const mapStateToProps = store => {
  return {
    journey: store.journey.journey,
    journeyLoading: store.journey.journeyLoading,
    isUserLoggedIn: store.login.isUserLoggedIn,
    isMobile: store.global.isMobile,
    excursionProgress: store.excursion.excursionProgress
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getJourney: id => dispatch(getJourney(id)),
    getProgressForJourney: id => dispatch(getProgressForJourney(id)),
    getProgressForExcursions: journeyId =>
      dispatch(getProgressForExcursions(journeyId))
  };
};

export default withTranslation()(withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Journey)
));

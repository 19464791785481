import React, { Component } from "react";
import { Header, Icon } from "semantic-ui-react";

export default class SearchFacet extends Component {
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
  }

  onChange(e, { value }) {
    this.props.handleCheckFacet(this.props.filter, value);
  }

  handleKeyCheckFacet(event, filter, key){
    if(event.keyCode === 32 ||
       event.keyCode === 13) {
      event.preventDefault();
      this.props.handleCheckFacet(filter, key)
    }
  }

  render() {
    let data = this.props.data;

    if(data == null || data.length === 0) {
      return "";
    }

    let checkboxes = [];
    for(var i = 0; i < data.length; i++) {
      let checked = this.props.checkedValues.indexOf(data[i].key) > -1;
      let iconname = checked ? "check square" : "square outline";
      let key = data[i].key;

      checkboxes.push(
        <div name={data[i].text}
          tabIndex="0"
          key={key}
          className="facet-checkbox"
          onKeyDown={(event) => this.handleKeyCheckFacet(event, this.props.filter, key)}
          onClick={() => this.props.handleCheckFacet(this.props.filter, key)}>
            <Icon name={iconname} className={checked ? "checked" : null} />
            {data[i].text}
        </div>
      )
    }

    const borderRight = this.props['right-border'] ? " border-right" : ""
    return (
      <div className={"search-facet" + borderRight}>
        <Header as="h3" tabIndex="-1">{this.props.title}</Header>
        {checkboxes}
      </div>
    )
  }
}

import React, { Component } from "react";

import StatementImage from "./Blocks/StatementImage";
import ImageHeaderText from "./Blocks/ImageHeaderText";
import Columns from "./Blocks/Columns";
import StatementText from "./Blocks/StatementText";
import Sources from "./Blocks/Sources";
import CenteredText from "./Blocks/CenteredText";
import Section from "./Blocks/Section";
import List from "./Blocks/List";
import Quote from "./Blocks/Quote";
import StyledText from "./Blocks/StyledText";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { parseBGImageStyle } from "../../../utils/HelperFunctions";
import { withTranslation } from 'react-i18next';

export class InfographicBlock extends Component {
  blockByType(block) {
    const { useMobileAlignment } = this.props;

    switch (block.type) {
      case "list":
        return <List {...block} />;
      case "section":
        return <Section {...block} />;
      case "styled-text":
        return <StyledText {...block} />;
      case "centered-text":
        return <CenteredText {...block} />;
      case "quote":
        return <Quote useMobileAlignment={useMobileAlignment} {...block} />;
      case "heading":
        return <ImageHeaderText {...block} />;
      case "image-statement":
        return (
          <StatementImage useMobileAlignment={useMobileAlignment} {...block} />
        );
      case "statement":
        return (
          <StatementText useMobileAlignment={useMobileAlignment} {...block} />
        );
      case "three-column":
      case "two-column":
        return <Columns {...block} />;
      case "sources":
        return <Sources {...block} />;
      case "page-break":
      case "transition-border":
      case "spacer":
      default:
        return null;
    }
  }

  render() {
    const { block, activeImageViewPort } = this.props;
    const style = block.style || {};

    const className = style.className || "";
    const innerStyle = style.innerStyle;
    const parsedBackgroundStyle = parseBGImageStyle(
      style.innerStyle,
      activeImageViewPort
    );
    const styleProp = { ...innerStyle, ...parsedBackgroundStyle };

    return (
      <div className={`block ${block.type} ${className}`} style={styleProp}>
        {this.blockByType(block)}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    useMobileAlignment: store.global.isMobile || store.global.isTablet,
    activeImageViewPort: store.global.activeImageViewPort,
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(InfographicBlock)));

import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import DynamicImage from "../HelperComponents/DynamicImage";

import RegisterButton from "../Buttons/RegisterButton";

export class PathRegister extends Component {
  render() {
    const { t } = this.props;

    return (
      <Grid>
        <Grid.Row className="path-progress-image-row">
          <Grid.Column className="path-progress-column">
            { this.props.progressPicture && <DynamicImage {...this.props.progressPicture} centered/> }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="path-progress-header-row">
          <Grid.Column className="path-progress-column">
            <b id="path-progress-bar-text">{ t('pathRegister.message') }</b>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="path-progress-register-row">
          <Grid.Column textAlign="center">
            <RegisterButton />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = store => {
  return {
    progressPicture: store.json.progressPicture,
    journeyTerm: store.json.journeyTerm
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(PathRegister)));

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

export const ClosedRoute = ({ component: Component, ...rest }) => {

  return (
    <Route {...rest} render={(props) => (
      (process.env.REACT_APP_REQUIRE_USER_TO_VIEW_CONTENT === "false" || rest.isUserLoggedIn)
        ? <Component {...rest} />
        : <Redirect to="/login" />
    )} />
  )
};

const mapStateToProps = store => {
  return {
    isUserLoggedIn: store.login.isUserLoggedIn,
    demographicsCompleted: store.login.userData.demographics_completed,
  }
}

export default connect(mapStateToProps)(ClosedRoute);

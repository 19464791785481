import React, { Component } from "react";
import ReactDOM from "react-dom";
import FocusLock from "react-focus-lock";
import { Icon, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { toggleTouring, getTouringContent } from "../../actions/touringActions";
import TouringModalPage from "./TouringModalPage";
import Cookies from "js-cookie";
import { Swipeable } from 'react-swipeable'

import "./TouringModal.scss";

export class TouringModal extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.state = {page: 0}
  }

  scrollToTop = () => {
    const ref = this.modalRef;
    if (ref && ref.current) {
      const node = ReactDOM.findDOMNode(ref.current);
      node.scrollIntoView({ block: "start" });
    }
  };

  componentDidMount() {
    this.props.getTouringContent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.open) {
      Cookies.set("touring", true);
    }
    const { open } = this.props;
    if (prevProps.open !== open) {
      if (this.props.open) {
        this.focusModal();
      } else {
        this.setState({ page: 0 });
      }
    }

    if (prevState.page !== this.state.page) {
      this.focusModal();
    }
  }

  focusModal() {
    const ref = this.modalRef;
    if (ref && ref.current) {
      ref.current.focus();
    }
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 27) {
      this.props.toggleTouring(false)
    }
  };

  getCircleButtons(count) {
    const buttons = [];
    for (var i = 0; i < count; i++){
      const active = i === this.state.page ? "active" : null;
      const j = i;
      buttons.push(<Icon name="circle" className={active} onClick={() => this.setState({page: j})}/>);
    }
    return buttons;
  }

  gotoPage(n) {
    this.setState({page: n})
  }

  nextPage() {
    if (this.state.page < this.props.content.length - 1)
      this.setState({page: this.state.page + 1})
  }

  prevPage() {
    if (this.state.page >= 1)
      this.setState({page: this.state.page - 1})
  }

  getTitle() {
    return this.props.content[this.state.page].title
  }

  getContent() {
    this.scrollToTop();
    if (!this.props.content || !this.props.content.length > 0) {
      return <div/>
    }

    return this.props.content.map((page, index) => {
            return <TouringModalPage mobileContent={page.mobileContent}
                              desktopContent={page.desktopContent}
                              title={page.title}
                              key={"page" + index}
                              mobileTitle={page.mobileTitle}
                              mobileImage={page.mobileImage}
                              pages={this.props.content.length}
                              current={this.state.page}
                              nextPage={this.nextPage.bind(this)}
                              gotoPage={this.gotoPage.bind(this)}
                              inactive={this.state.page !== index}/>
          });
  }

  shouldModalOpen() {
    const { content } = this.props;
    const hasContent = content && content.length !== undefined
    return this.props.open && hasContent
  }

  render() {
    return (
      <Modal
        id="touring-modal"
        open={this.shouldModalOpen()}
        closeOnDimmerClick={false}
        aria-modal="true"
        size={"tiny"}
        onKeyDown={this.handleKeyPress}
      >
        <FocusLock returnFocus={true} className="content">
          <div ref={this.modalRef}>
            <Swipeable onSwipedLeft={(eventData) => {this.nextPage()}} onSwipedRight={(eventData) => {this.prevPage()}} trackMouse={true} >
              {this.getContent()}
            </Swipeable>
          </div>
        </FocusLock>
      </Modal>
    );
  }
}

const mapStateToProps = (store) => ({
  open: store.touring.open,
  content: store.touring.content,
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleTouring: (show) => dispatch(toggleTouring(show)),
    getTouringContent: () => dispatch(getTouringContent()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TouringModal);

import React from 'react';
import "./error.scss";
import {Button, Grid} from "semantic-ui-react";
import { Link } from 'react-router-dom';
import DynamicImage from "../HelperComponents/DynamicImage";

import "./error.scss"

const Error = () => {
  return (
    <Grid textAlign="center" id="error-page">
      <Grid.Row>
        <Grid.Column width={15}>
          <DynamicImage src="/errors/404_error.png" alt="Not Found" centered/>
          <h1>Seems like you've lost your way.</h1>
          <p>It appears that we can't find the page you were looking for.</p>
          <div className="return-back">
            <Link to="/">
              <Button className="ui button primary">
                <p>Go Back Home</p>
              </Button>
            </Link>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Error;
import React from "react"
import Duration from "../../../utils/Duration";

const VideoDuration = (props) => (
  <div className="video-duration">
    <b>
      <Duration seconds={props.duration * props.played} />
    </b>{" "}
    / <Duration seconds={props.duration} />
  </div>
)

export default VideoDuration;

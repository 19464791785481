function defaultState() {
  return {
    excursionProgress: {},
    excursionProgressLoading: false,
    excursionProgressError: null
  };
}

export default function reducer(state = defaultState(), action) {
  switch (action.type) {
    case "RESET":
      return defaultState();
    case "GET_PROGRESS_FOR_EXCURSION_PENDING":
      return {
        ...state,
        excursionProgressLoading: true,
        excursionProgressError: null
      };
    case "GET_PROGRESS_FOR_EXCURSION_FAILURE":
      return {
        ...state,
        excursionProgressLoading: true,
        excursionProgressError: null
      };
    case "GET_PROGRESS_FOR_EXCURSION_FULFILLED":
      return {
        ...state,
        excursionProgress: {
          ...state.excursionProgress,
          [action.meta.excursionId]: {
            ...action.payload.data.excursion_progress
          }
        },
        excursionProgressLoading: false,
        excursionProgressError: null
      };
    case "GET_PROGRESS_FOR_EXCURSIONS_PENDING":
      return {
        ...state,
        excursionProgressLoading: true,
        excursionProgressError: null
      };
    case "GET_PROGRESS_FOR_EXCURSIONS_FAILURE":
      return {
        ...state,
        excursionProgressLoading: true,
        excursionProgressError: null
      };
    case "GET_PROGRESS_FOR_EXCURSIONS_FULFILLED":
      return {
        ...state,
        excursionProgress: action.payload.data.excursions,
        excursionProgressLoading: false,
        excursionProgressError: null
      };
    default:
      return state;
  }
}

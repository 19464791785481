import React, { Component } from "react";

export default class Sources extends Component {
  renderSourceLinks(sources) {
    return sources.map((source) => {
      if (source.url) {
        return (
          <li>
            <a href={source.url}>{source.urlTitle || source.url}</a>
          </li>
        );
      } else {
        return null;
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="column sources-header">References</div>
        </div>
        <div className="row">
          <div className="column">
            <ol className="sources-list">
              {this.renderSourceLinks(this.props.sources)}
            </ol>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

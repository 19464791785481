import LeftNavigation from "./LeftNavigation";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

const mapStateToProps = store => {
  return {
    isUserLoggedIn: store.login.isUserLoggedIn
  };
};

export default  withTranslation()(withRouter(connect(mapStateToProps)(LeftNavigation)));

import React, { Component } from "react";
import { Container, Item, List, Placeholder } from "semantic-ui-react";

export default class LeftNavigationPlaceholder extends Component {
  render() {
    return <Container fluid className="left-navigation-container">
      <List>
        <List.Header className="ln-excursion-title">
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        </List.Header>
        <List.Item className="ln-excursion-content" active={true} >
          {
            [1,2,3].map(i => {
              let className = i === 1 ? 'topic-accordion-placeholder-item-first' : 'topic-accordion-placeholder-item';

              return <Item key={i} className={className}>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              </Item>
            })
          }
        </List.Item>
      </List>
    </Container>
  }
}

import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  List,
  Message,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import {
  getProfile,
  toggleProfile,
  sendVerificationCode,
} from "../../actions/profileActions";
import ProfileModal, { profileModalTypes } from "./ProfileModal";
import "./profile.scss";
import { displayMfaRegistration } from "../../actions/loginActions";
import LoginModal from "../LoginModal/LoginModal";

export class Profile extends Component {
  componentDidMount() {
    this.props.dispatch(getProfile(this.props.userId));
  }

  componentDidUpdate(prevProps) {
    const { sendConfirmationSuccess } = this.props;

    if (
      sendConfirmationSuccess &&
      sendConfirmationSuccess !== prevProps.sendConfirmationSuccess
    ) {
      this.openModal(profileModalTypes.verificationSent);
    }
  }

  openModal(type) {
    this.props.dispatch(toggleProfile(true, type));
  }

  userProfileContent(email, zip_code, emailVerified) {
    const { t } = this.props;
    
    return (
      <Grid id="user-profile-content">
        <Grid.Row>
          <Grid.Column
            widescreen={3}
            largeScreen={4}
            computer={5}
            tablet={5}
            id="user-profile-headings">
            <List>
              <List.Item>
                <b>{ t('emailAddress') }</b>
              </List.Item>
              <List.Item>
                <b>{ t('postalCode') }</b>
              </List.Item>
              <List.Item>
                <b>{ t('profile.twoFactor') }:</b>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column
            widescreen={13}
            largeScreen={12}
            computer={11}
            tablet={11}
          >
            <List>
              <List.Item>
                {this.isFieldEmpty(email)}
                {emailVerified === true
                  ? null
                  : this.getVerificationLink(false)}
              </List.Item>
              <List.Item>{this.isFieldEmpty(zip_code)}</List.Item>
              <List.Item>
                <a
                  onClick={this.openMfaModal.bind(this)}
                >
                { t('profile.changeTwoFactor') }
                </a>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  mobileProfileContent(email, zip_code, emailVerified) {
    const { t } = this.props;

    return (
      <Grid id="mobile-user-profile-content">
        <Grid.Row>
          <Grid.Column mobile={16}>
            <List>
              <List.Item>
                <b>{ t('emailAddress') }</b>
              </List.Item>
              <List.Item>{this.isFieldEmpty(email)}</List.Item>
              {emailVerified === true ? null : this.getVerificationLink(true)}
              <div className="mobile-profile-field-divider" />
              <List.Item>
                <b>{ t('inBusiness.label') }</b>
              </List.Item>
              <div className="mobile-profile-field-divider" />
              <List.Item>
                <b>{ t('postalCode') }</b>
              </List.Item>
              <List.Item>{this.isFieldEmpty(zip_code)}</List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  isNameEmpty(name) {
    return name ? name : "";
  }

  isFieldEmpty(field) {
    return field ? field : "N/A";
  }

  openMfaModal = (e) => {
    e.preventDefault();

    this.props.dispatch(displayMfaRegistration())
  }
  

  getVerificationLink(isMobile) {
    const verificationLink = (
      <u
        id="resend-verification"
        onClick={this.sendVerificationEmail.bind(this)}
      >
        { t('profile.resendVerification') }
      </u>
    );

    if (isMobile) {
      return <List.Item>{verificationLink}</List.Item>;
    } else {
      return verificationLink;
    }
  }

  sendVerificationEmail() {
    this.props.dispatch(sendVerificationCode());
  }

  profileMessage(isError, errorText) {
    return (
      <div className="profile-load-error">
        <Message positive={!isError} negative={isError} content={errorText} />
      </div>
    );
  }

  profileLoadError() {
    const errorText = (
      <React.Fragment>
        { t('profile.profileLoadError') }
      </React.Fragment>
    );
    return this.profileMessage(true, errorText);
  }

  resendVerificationError() {
    const errorText = (
      <React.Fragment>
        { t('profile.resendVerificationError') }
      </React.Fragment>
    );
    return this.profileMessage(true, errorText);
  }

  render() {
    const { t } = this.props;
    const title = t('profile.title');

    let first_name = "";
    let last_name = "";
    let middle_name = "";
    let zip_code = "";
    let email = "";

    if (this.props.userProfile) {
      ({
        first_name,
        last_name,
        middle_name,
        zip_code,
        email,
      } = this.props.userProfile);
    }
    const {
      profileLoadError,
      sendConfirmationFailure,
      emailVerified,
    } = this.props;

    return (
      
      <div className="profile">
        <LoginModal 
          type={this.props.mfaScreen} 
          open={this.props.mfaOpen} 
          stayOnScreen={true} 
          twoFaTitle={"Change Two-Factor Number"}/>
        <Grid id="profile-header-grid" centered>
          <Grid.Row id="profile-page-header">
            <Grid.Column mobile={14} tablet={15} computer={15}>
              <Header tabIndex="0" as="h1" id="profile-header">
                {title}
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid id="user-profile" centered>
          <Grid.Row>
            <Grid.Column
              computer={12}
              mobile={14}
              tablet={14}
              id="profile-name-header"
            >
              <div id="profile-name-header-container">
                <Header as="h2">
                  {this.isNameEmpty(first_name)} {this.isNameEmpty(middle_name)}{" "}
                  {this.isNameEmpty(last_name)}
                </Header>
              </div>
              {profileLoadError ? this.profileLoadError() : null}
              {sendConfirmationFailure ? this.resendVerificationError() : null}
              <Divider />
              {this.props.isMobile
                ? this.mobileProfileContent(
                    email,
                    zip_code,
                    emailVerified
                  )
                : this.userProfileContent(
                    email,
                    zip_code,
                    emailVerified
                  )}
              <Divider />
              <div className="profile-button-group">
                <Button
                  className="edit-form-button"
                  floated="left"
                  onClick={() => this.openModal(profileModalTypes.editProfile)}
                  content={ t('profile.editProfile') }
                  disabled={this.props.profileLoadError}
                />
                <Button
                  className="delete-account-button"
                  floated="right"
                  secondary
                  onClick={() =>
                    this.openModal(profileModalTypes.deleteAccount)
                  }
                  content={ t('profile.deleteAccount')}
                  disabled={this.props.profileLoadError}
                />
              </div>
              <ProfileModal email={email} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    userId: store.login.userData.user.id,
    userProfile: store.userProfile.userData,
    profileLoadError: store.userProfile.profileLoadError,
    isMobile: store.global.isMobile,
    emailVerified: store.userProfile.emailVerified,
    sendConfirmationFailure: store.userProfile.sendConfirmationFailure,
    sendConfirmationSuccess: store.userProfile.sendConfirmationSuccess,
    mfaScreen: store.login.currentScreen,
    mfaOpen: store.login.open
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(Profile)));

import React, { Component } from "react";
import { Form, Radio, Divider } from "semantic-ui-react";
import ParsedHtml from "../../HelperComponents/ParsedHtml";

export class MultipleChoiceQuestion extends Component {
  state = {};
  handleChange = (_, { value }) => {
    this.setState({ value });

    if (this.props.onSelected) {
      this.props.onSelected(this.props.questionIndex, value);
    }
  };

  render() {
    const question = this.props.question;
    return (
      <div className="question multiple-choice">
        <p>
          <strong>Question {this.props.order}</strong>
        </p>
        <p>
          <ParsedHtml html={question.question_text}></ParsedHtml>
        </p>
        <Form>
          {question.answers.map(a => (
            <Form.Field
              key={a.id}
              id={a.id}
              control={Radio}
              label={a.text}
              name={a.id}
              value={a.weight}
              checked={this.state.value === a.weight}
              onChange={this.handleChange}
            />
          ))}
        </Form>
        <Divider />
      </div>
    );
  }
}

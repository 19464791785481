import React, { Component } from "react";
import ContentBlock from "./ContentBlock";

export default class SplitContent extends Component {
  render() {
    return <React.Fragment>
      <ContentBlock content={this.props.content.first}/>
      <ContentBlock content={this.props.content.second}/>
    </React.Fragment>
  }
}
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Button, Grid, Header, Icon, Modal } from "semantic-ui-react";
import FocusLock from "react-focus-lock";
import {
  resetUserProfileErrors,
  toggleProfile,
} from "../../actions/profileActions";
import { reset } from "../../actions/globalActions";

import DeleteAccount from "./DeleteAccount";
import DeleteSuccess from "./DeleteSuccess";
import EditProfileForm from "./EditProfileForm";
import VerificationSent from "./VerificationSent";

export class ProfileModal extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { open, activeModalType } = this.props;
    if (
      (open && prevProps.open !== open) ||
      prevProps.activeModalType !== activeModalType
    ) {
      this.focusModal();
    }
  }

  scrollToTop = () => {
    const ref = this.modalRef;
    if (ref && ref.current) {
      const node = ReactDOM.findDOMNode(ref.current);
      node.scrollIntoView({ block: "start" });
    }
  };

  focusModal() {
    const { current } = this.modalRef;
    if (current && current.ref) {
      current.ref.focus();
    }
  }

  close = () => {
    this.props.resetUserProfileErrors();
    this.props.toggleProfile(false, profileModalTypes.editProfile);
    if (this.props.activeModalType === profileModalTypes.deleteSuccess) {
      this.props.reset();
    }
  };

  handleKeyPress = (e) => {
    if (e.keyCode === 27) {
      this.close();
    }
  };

  modalContent() {
    switch (this.props.activeModalType) {
      case profileModalTypes.editProfile:
        return (
          <EditProfileForm close={this.close} scrollToTop={this.scrollToTop} />
        );
      case profileModalTypes.deleteAccount:
        return <DeleteAccount close={this.close} />;
      case profileModalTypes.deleteSuccess:
        return <DeleteSuccess close={this.close} />;
      case profileModalTypes.verificationSent:
        return <VerificationSent close={this.close} email={this.props.email} />;
      default:
        return null;
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Modal
        id="edit-profile-modal"
        open={this.props.open}
        closeOnDimmerClick={false}
        aria-modal="true"
        tabIndex="-1"
        size={"tiny"}
        ref={this.modalRef}
        onKeyDown={this.handleKeyPress}
      >
        <div className="content" id="profile-modal-content" ref={this.modalRef}>
          <FocusLock returnFocus={true} className="content">
            <Grid>
              <Grid.Row columns={16}>
                <Grid.Column width={14}>
                  <Header id="edit-profile-header">
                    { t(this.props.activeModalType) }
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button
                    aria-label="Close"
                    className="eli-modal-close-button"
                    onClick={this.close}
                    icon
                  >
                    <Icon
                      link
                      name="close"
                      size="large"
                      className="edit-profile-close"
                    />
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <hr />
            {this.modalContent()}
          </FocusLock>
        </div>
      </Modal>
    );
  }
}

export const profileModalTypes = {
  editProfile: "profileModal.types.editProfile",
  deleteAccount: "profileModal.types.deleteAccount",
  deleteSuccess: "profileModal.types.deleteSuccess",
  verificationSent: "profileModal.types.verficationSent",
};

const mapStateToProps = (store) => ({
  open: store.userProfile.open,
  activeModalType: store.userProfile.activeModalType,
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetUserProfileErrors: () => dispatch(resetUserProfileErrors()),
    toggleProfile: (show, type) => dispatch(toggleProfile(show, type)),
    reset: () => dispatch(reset()),
  };
};

const Extended = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProfileModal));

Extended.profileModalTypes = profileModalTypes;

export default Extended;

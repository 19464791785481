import React from "react";
import { Button, Icon } from "semantic-ui-react";

const PrintButton = () => {
  if (window.print) {
    return (
      <Button
        icon
        onClick={() => window.print()}
        aria-label="print"
        className="print-button header-button mobile hidden"
        floated="right"
        target="_blank"
      >
        <Icon name="print" />
      </Button>
    );
  } else {
    return null;
  }
};

export default PrintButton;

import React, { Component } from "react";

import { Range, getTrackBackground } from "react-range";

const MIN = 0;
const MAX = 100;
const STEP = 1;
export class VolumeSlider extends Component {
  render() {
    const { volume } = this.props;
    return (
      <div className="volume-seek-bar-wrapper">
        <div className="volume-seek-bar">
          <Range
            min={MIN}
            max={MAX}
            step={STEP}
            values={[volume]}
            onChange={this.props.setVolume}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "14px",
                  display: "flex",
                  width: "100%"
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "4px",
                    width: "100%",
                    borderRadius: "4px",
                    background: getTrackBackground({
                      values: [volume],
                      colors: ["orange", "white"],
                      min: MIN,
                      max: MAX
                    }),
                    alignSelf: "center"
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                aria-valuemin={MIN}
                aria-valuemax={MAX}
                aria-valuenow={volume}
                aria-valuetext={`Volume ${volume}%`}
                aria-label="volume slider handle"
                className="volume-slider-handle"
                style={{
                  borderColor: "white",
                  height: "14px",
                  width: "14px",
                  backgroundColor: "white",
                  borderRadius: "50%"
                }}
              ></div>
            )}
          />
        </div>
      </div>
    );
  }
}

export default VolumeSlider;

import React from "react";
import { Card } from "semantic-ui-react";
import DynamicImage from "../HelperComponents/DynamicImage";
import PropTypes from "prop-types";

const HomepageExcursionCard = ({image, header, description, alt, isMobile}) => (
  <Card
    className="homepage-excursion-card"
  >
    <DynamicImage
      src={image}
      size={isMobile ? "large" : "small"}
      centered
      alt={alt}
    />
    <Card.Content className="homepage-excursion-card-content">
      <Card.Header as="h3" className="homepage-excursion-card-header">{header}</Card.Header>
      <Card.Description className="homepage-excursion-card-description">
        {description}
      </Card.Description>
    </Card.Content>
  </Card>
)

HomepageExcursionCard.propTypes = {
  image: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
}

export default HomepageExcursionCard;

function defaultState() {
  return {
    isMobile: false,
    isTablet: false,
    isInitialized: false,
    isDisableScroll: false,
    activeImageViewPort: {},
    siteName: process.env.REACT_APP_SITE_NAME || "Ascent"
  }
}

 export default function reducer(state=defaultState(), action) {
  switch(action.type) {
    case 'MOBILE':
      return {
        ...state,
        isMobile: action.payload
      }
    case 'TABLET':
      return {
        ...state,
        isTablet: action.payload
      }
    case "SET_ACTIVE_IMAGE_VIEWPORT":
      return {
        ...state,
        activeImageViewPort: action.payload,
      };
    case 'EXTERNAL_SITE':
        return {
          ...state,
          externalSite: action.payload.isModalOpen,
          externalUrl: action.payload.externalUrl
        }
    case 'DISABLE_SCROLL':
      return {
        ...state,
        isDisableScroll: action.payload && state.isMobile
      }
    case 'INITIALIZED_FULFILLED':
    case 'INITIALIZED_REJECTED':
      return {
        ...state,
        isInitialized: true
      }
    default:
      break;
  };

   return state;
}

import React from "react";
import { formatTime } from "./HelperFunctions";

export default function Duration({ className, seconds }) {
  return (
    <time dateTime={`P${Math.round(seconds)}S`} className={className}>
      {formatTime(seconds)}
    </time>
  );
}

import React from 'react';
import {Icon} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import PasswordRules from './PasswordRules';
import "./PasswordValidator.scss";
class PasswordValidator extends React.Component {
  render() {
    const { t } = this.props;

    return <div className="passwordValidation">
      {
        PasswordRules.RULES.map((rule, idx) => rule.validator(this.props.password, this.props.confirm) ?
        <div key={`validated-rule-${idx}`} className="validated"><Icon name="check circle" />{t(rule.rule)}</div> :
        <div key={`unvalidated-rule-${idx}`}><Icon />{t(rule.rule)}</div>)
      }
    </div>
  }
}

export default withTranslation()(PasswordValidator);
import React, { Component } from "react";
import DropdownItem from "./DropdownItem";

export class SimpleDropdown extends Component {
  constructor(props) {
    super(props);

    this.dropdownRef = React.createRef();

    this.state = {
      isActive: false,
      isOpen: false,
      activeItem: null,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentDidUpdate(_, prevState) {
    const { isOpen, activeItem } = this.state;
    if (isOpen !== prevState.isOpen) {
      //Remove activeItem if we close the dropdown
      if (!isOpen) {
        this.setState({ activeItem: null });
      }
    }

    if (activeItem === null && activeItem !== prevState.activeItem) {
      //If there is no active item after a change we refocus the main dropdown
      if (this.dropdownRef && this.dropdownRef.current) {
        this.dropdownRef.current.focus();
      }
    }
  }

  handleFocus() {
    this.setState({ isActive: true });
  }

  handleBlur() {
    this.setState({ isActive: false });
  }

  handleClick = (e) => {
    const dropdownRef = this.dropdownRef;
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  };

  handleKeyDown(e) {
    const { isOpen, activeItem } = this.state;
    const { items } = this.props;

    if (isOpen) {
      switch (e.key) {
        case "Enter": {
          const activeOption = items[activeItem];
          //If we have an item selected execute its action, otherwise close dropdown
          if (activeOption) {
            activeOption.action();
          }
          this.setState({ isOpen: false });
          break;
        }
        case "Tab": {
          if (e.shiftKey) {
            if (activeItem !== null) {
              this.previousItem(e, activeItem);
            } else {
              this.setState({ isOpen: false });
            }
          } else if (!e.shiftKey && activeItem + 1 !== items.length) {
            this.nextItem(e, items, activeItem);
          } else {
            this.setState({ isOpen: false });
          }
          break;
        }
        case "ArrowDown": {
          this.nextItem(e, items, activeItem);
          break;
        }
        case "ArrowUp": {
          this.previousItem(e, activeItem);
          break;
        }
        case "Escape": {
          this.setState({ isOpen: false });
          break;
        }
        default:
          break;
      }
    } else {
      switch (e.key) {
        case "Enter":
        case " ": {
          e.preventDefault();
          e.stopPropagation();
          this.setState({ isOpen: true });
          break;
        }
        default:
          break;
      }
    }
  }

  previousItem(e, activeItem) {
    e.preventDefault();
    e.stopPropagation();
    const previousItem = activeItem === null ? null : activeItem - 1;

    if (activeItem === 0) {
      this.setState({ activeItem: null });
    } else {
      if (previousItem !== null && 0 <= previousItem) {
        this.setState({ activeItem: previousItem });
      }
    }
  }

  nextItem(e, items, activeItem) {
    e.preventDefault();
    e.stopPropagation();
    const nextActive = activeItem === null ? 0 : activeItem + 1;
    if (items.length > nextActive) {
      this.setState({ activeItem: nextActive });
    }
  }

  iconName(isActive, icon, activeIcon) {
    if (icon) {
      if (isActive && activeIcon) {
        return activeIcon;
      } else {
        return icon;
      }
    } else {
      return "dropdown caret down";
    }
  }

  renderIcon(isActive, icon, activeIcon) {
    const iconName = this.iconName(isActive, icon, activeIcon);

    return <i aria-hidden={true} className={`icon ${iconName}`} />;
  }

  renderText(text) {
    if (text) {
      return (
        <div aria-hidden={true} className="text">
          {text}
        </div>
      );
    }
  }

  renderDropdownItems(items, activeItem) {
    if (items) {
      return items.map((item, index) => {
        const active = index === activeItem;
        return (
          <DropdownItem
            key={`simple-dropdown-item-${item.itemKey}`}
            {...item}
            active={active}
          />
        );
      });
    }
  }

  render() {
    const { isOpen, activeItem } = this.state;
    const { text, id, ariaLabel, icon, activeIcon, items } = this.props;

    const isActive = this.state.isActive && activeItem === null;
    const className = this.props.className || "";
    const activeClassName = isActive ? " focused" : "";
    const visibleClassName = isOpen ? " active visible" : "";
    const fullClassName = `ui item dropdown simple-dropdown${className}${activeClassName}${visibleClassName}`;

    return (
      <div
        id={id}
        ref={this.dropdownRef}
        className={fullClassName}
        aria-label={ariaLabel}
        role="listbox"
        aria-haspopup={true}
        aria-expanded={isOpen}
        tabIndex={0}
        onKeyDown={(e) => this.handleKeyDown(e)}
        onFocus={() => this.handleFocus()}
        onBlur={() => this.handleBlur()}
        onMouseEnter={() => this.setState({ isActive: true })}
        onMouseLeave={() => this.setState({ isActive: false })}
        onClick={() => this.setState({ isOpen: !isOpen })}
      >
        {this.renderText(text)}
        {this.renderIcon(isActive, icon, activeIcon)}
        <div className={`menu transition${visibleClassName}`}>
          {this.renderDropdownItems(items, activeItem)}
        </div>
      </div>
    );
  }
}

export default SimpleDropdown;

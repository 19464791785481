function defaultState() {
  return {
    open: false,
    content: {}
  }
}

export default function reducer(state = defaultState(), action) {
  switch (action.type) {
    case "RESET":
      return defaultState();
    case "TOGGLE_TOURING_MODAL":
      return {
        ...state,
        open: action.payload.show,
      };
    case "GET_TOURING_CONTENT_FULFILLED":
      return {
        ...state,
        ...action.payload.data
      };
    default:
      break;
  }

  return state;
}
export const isHomepageEnabled = () => {
  const homePageEnabled = process.env.REACT_APP_ENABLE_HOMEPAGE
  return homePageEnabled === "true" || homePageEnabled === undefined
}

export const isUserRequired = () => {
  return process.env.REACT_APP_REQUIRE_USER_TO_TRACK_PROGRESS !== "false";
}

export const isHomepageDisabled = () => {
  return !isHomepageEnabled()
}

export const isTranslationEnabled = () => {
  return process.env.REACT_APP_TOGGLE_I18N === "true";
}

export const isTranslationDisabled = () => {
  return !isTranslationEnabled()
}
import { userClient } from "../utils/axiosClients";

export function toggleDemographics(show) {
  return {
    type: "TOGGLE_DEMOGRAPHICS_MODAL",
    payload: { show },
  };
}

export function previousDemographicPage() {
  return {
    type: "PREVIOUS_DEMOGRAPHIC_PAGE",
  };
}

export function nextDemographicPage() {
  return {
    type: "NEXT_DEMOGRAPHIC_PAGE",
  };
}

export function updateDemographicDetails(key, value) {
  return {
    type: "UPDATE_DEMOGRAPHIC_DETAILS",
    payload: { key: key, value: value },
  };
}

export function saveDemographicDetails(userData) {
  const url = "/registration_details";

  return (dispatch, getState) => {
    return dispatch({
      type: "SAVE_DEMOGRAPHIC_DETAILS",
      payload: userClient(getState()).post(url, userData),
    });
  };
}
import React, { Component } from "react";
import MetaTags from "../SEO/MetaTags";
import CardContainer from "../CardContainer";
import { linkToJourneys } from "../../utils/InternalLinks";
import { connect } from "react-redux";

import "./Journeys.scss";

export class Journeys extends Component {
  componentDidMount() {
    this.props.getJourneys();
    this.props.getLatestUserProgress();
  }

  componentDidUpdate(prevProps) {
    const prevLoggedIn = prevProps.isUserLoggedIn;
    const nextLoggedIn = this.props.isUserLoggedIn;

    if (prevLoggedIn !== nextLoggedIn) {
      this.props.getJourneys();
      this.props.getLatestUserProgress();
    }
  }

  render() {
    const cardHeader = this.props.journeyTerm;

    return (
      <div id="journeys">
        <MetaTags
          metaTitle={this.props.journeysTitle}
          metaDescription={this.props.journeysDescription}
          canonicalUrl={`${process.env.REACT_APP_SITE_ROOT}${linkToJourneys()}`}
        />
        <CardContainer
          cardHeader={cardHeader}
          cardItems={this.props.journeys}
          loading={this.props.journeysLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    journeysTitle: store.json.journeysTitle,
    journeysDescription: store.json.journeysDescription,
    journeyTerm: store.json.journeyTerm
  };
};

export default connect(mapStateToProps)(Journeys);
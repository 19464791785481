import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import { forcePathDropdownCloseToggle } from "../../../../../actions/navbarActions";

export class NavigationLearningObjectiveItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.mainNav !== prevProps.mainNav) {
      const { mainNav } = this.props;
      const topicIndex = mainNav.activeTopicIndex;

      if (topicIndex === this.props.index) {
        this.setState({ active: true });

        //Set focus when excursion becomes active, allows screen reader to function
        if (this.props.topicRef && this.props.topicRef.current) {
          this.props.topicRef.current.focus();
        }
      } else {
        this.setState({ active: false });
      }
    }
  }

  handleItemClick(e, topicPath) {
    this.props.history.push(topicPath);
    // Semantic nested dropdown is having issues with not closing the dropdown
    // When item link is clicked
    // This is a workaround to control the component just to close it
    this.props.dispatch(forcePathDropdownCloseToggle(true));
  }

  pathCountText(mleCount) {
    const { t } = this.props;
    const term = t('path');
    return `${mleCount} ${mleCount === 1 ? term : term + "s"}`;
  }

  render() {
    const { index, loName, topicRef, topicPath, mleCount } = this.props;
    const { active } = this.state;

    const activeClass = active ? " active" : "";
    const pathCountText = this.pathCountText(mleCount);
    return (
      <div
        className={`item navbar-topic-item${activeClass}`}
        role="option"
        aria-selected={active}
        key={`learning_objective_${index}`}
        aria-label={`Link to Excursion ${loName} with ${pathCountText}`}
        tabIndex={-1}
        ref={topicRef}
      >
        <div
          className="navbar-topic-item-link"
          onClick={(e) => this.handleItemClick(e, topicPath)}
        >
          <span>
            <b>{loName}</b>
            <em className="navbar-topic-item-summary">{pathCountText}</em>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    mainNav: store.accessibility.mainNav,
    pathTerm: store.json.pathTerm
  };
};

export default withRouter(
  withTranslation()(connect(mapStateToProps)(NavigationLearningObjectiveItem))
);

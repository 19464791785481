import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "semantic-ui-react";
import { setMediaTranscriptType } from "../../actions/learningEventActions";
import MediaTranscriptContent from "./MediaTranscriptContent";

import "./MediaTranscript.scss";

export class MediaTranscriptMenu extends Component {
  constructor(props) {
    super(props);

    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.setInitialActiveItem();
  }

  componentDidUpdate(prevProps) {
    if (this.props.event !== prevProps.event) {
      this.setInitialActiveItem();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setMediaTranscriptType(null));
  }

  setInitialActiveItem() {
    const types = MediaTranscriptContent.availableTypes(this.props.event);
    let first = types[0];
    if (
      process.env.REACT_APP_COMMENTS !== "true" &&
      MediaTranscriptContent.types.comments === types[0] &&
      types.length > 1
    ) {
      first = types[1];
    }
    this.props.dispatch(setMediaTranscriptType(first));
  }

  handleItemClick(e, { name }) {
    this.props.dispatch(setMediaTranscriptType(name));
  }

  handleKeyDown(name) {
    return event => {
      if (event.keyCode === 13 || event.keyCode === 32) {
        event.preventDefault();
        this.props.dispatch(setMediaTranscriptType(name));
      }
    };
  }

  menuItems() {
    const types = MediaTranscriptContent.availableTypes(this.props.event);
    return types.map(type => {
      return (
        <Menu.Item
          name={type}
          tabIndex="0"
          key={`transcript-menu-item-${type}`}
          active={this.props.activeItem === type}
          onClick={this.handleItemClick}
          onKeyDown={this.handleKeyDown(type)}
        >
          <div className="transcript-item-text">{type}</div>
        </Menu.Item>
      );
    });
  }

  render() {
    return (
      <Menu pointing secondary id="media-transcript-menu">
        {this.menuItems()}
      </Menu>
    );
  }
}

const mapStateToProps = store => {
  return {
    event: store.learningEvent.learningEvent,
    activeItem: store.learningEvent.mediaTranscriptContent,
    isMobile: store.global.isMobile
  };
};

export default connect(mapStateToProps)(MediaTranscriptMenu);

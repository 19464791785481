import React, { Component } from "react";
import Section from "./Section";
import { withTranslation } from 'react-i18next';

import "./ListCustomBulletStyles.scss";

class List extends Component {
  renderElements(elements) {
    return elements.map(element => {
      return (
        <li>
          <Section {...element} />
        </li>
      );
    });
  }

  render() {
    const { content } = this.props;

    if (content) {
      if (content.isOrdered) {
        return <ol>{this.renderElements(content.elements)}</ol>;
      } else {
        return <ul>{this.renderElements(content.elements)}</ul>;
      }
    }
  }
}

export default withTranslation()(List);
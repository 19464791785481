export default function (g) {
  // ForeSee Production Embed Script v2.01
  // DO NOT MODIFY BELOW THIS LINE *****************************************
  (function (g) {
    var currentEnvironment = process.env.REACT_FORESEE_ENVIRONMENT ? process.env.REACT_FORESEE_ENVIRONMENT : "staging";
    var d = document, am = d.createElement('script'), h = d.head || d.getElementsByTagName("head")[0], fsr = 'fsReady',
    aex = {
      "src": `//gateway.foresee.com/sites/sba-gov/${currentEnvironment}/gateway.min.js`,
      "type": "text/javascript",
      "async": "true",
      "data-vendor": "fs",
      "data-role": "gateway"
    };
    for (var attr in aex) { am.setAttribute(attr, aex[attr]); } h.appendChild(am); g[fsr] || (g[fsr] = function () { var aT = '__' + fsr + '_stk__'; g[aT] = g[aT] || []; g[aT].push(arguments); });
  })(window);
  // DO NOT MODIFY ABOVE THIS LINE ******************************************
}

function defaultState() {
  return {
    userData: {
      first_name: "",
      middle_name: "",
      last_name: "",
      zip_code: "",
      email: "",
      in_business: "",
      password: "",
      errors: {},
    },
    open: false,
    userError: null,
    onProfileSubmit: false,
    userConfirmed: false,
    profileLoadError: false,

    emailVerified: true,
    sendConfirmationFailure: false,
    sendConfirmationSuccess: false,
  };
}

export default function reducer(state = defaultState(), action) {
  switch (action.type) {
    case "RESET":
      return defaultState();
    case "RESET_USER_PROFILE_ERRORS": {
      return {
        ...state,
        userError: null,
      };
    }
    case "GET_USER_PROFILE_PENDING":
      return {
        ...state,
        userLoading: true,
        userError: null,
        profileLoadError: false,
      };
    case "GET_USER_PROFILE_REJECTED":
      return {
        ...state,
        userData: {},
        userLoading: false,
        userError: action.payload,
        profileLoadError: true,
      };
    case "GET_USER_PROFILE_FULFILLED":
      return {
        ...state,
        userData: action.payload.data,
        userLoading: false,
        userError: null,
        profileLoadError: false,
      };
    case "EDIT_USER_PROFILE":
      return {
        ...state,
        userData: {},
        userLoading: true,
        userError: null,
        onProfileSubmit: false,
      };
    case "EDIT_USER_PROFILE_REJECTED":
      let error = profileEditError(action);
      return {
        ...state,
        userLoading: false,
        userError: error,
        onProfileSubmit: false,
      };
    case "EDIT_USER_PROFILE_FULFILLED":
      return {
        ...state,
        userData: action.payload.data,
        userLoading: false,
        userError: null,
        onProfileSubmit: true,
      };
    case "EDIT_PROFILE_VALIDATION_FAILED":
      return { ...state, userLoading: false, userError: action.error };
    case "PROFILE_CONFIRM_EMAIL_FULFILLED":
      return {
        ...state,
        userConfirmed: true,
        userError: null,
      };
    case "PROFILE_CONFIRM_EMAIL_REJECTED":
      return {
        ...state,
        userError: action.payload.response.data.errors,
        userConfirmed: false,
      };
    case "DELETE_USER_ACCOUNT_PENDING":
      return {
        ...state,
        userError: null,
      };
    case "DELETE_USER_ACCOUNT_FULFILLED":
      return {
        ...state,
        userError: null,
      };
    case "DELETE_USER_ACCOUNT_REJECTED":
      return {
        ...state,
        userError: action.payload.response.data.errors,
      };
    case "TOGGLE_PROFILE_MODAL":
      return {
        ...state,
        open: action.payload.show,
        activeModalType: action.payload.type,
        userError: null,
        onProfileSubmit: false,
        sendConfirmationFailure: false,
        sendConfirmationSuccess: false,
      };
    case "REQUEST_VERIFICATION_CODE_PENDING":
      return {
        ...state,
        emailVerified: true,
      };
    case "REQUEST_VERIFICATION_CODE_REJECTED":
      return {
        ...state,
        emailVerified: true,
      };
    case "REQUEST_VERIFICATION_CODE_FULFILLED":
      return {
        ...state,
        emailVerified: action.payload.data.email_verified === "true",
      };

    case "SEND_VERIFICATION_CODE_PENDING":
      return {
        ...state,

        sendConfirmationFailure: false,
        sendConfirmationSuccess: false,
      };
    case "SEND_VERIFICATION_CODE_REJECTED":
      return {
        ...state,
        sendConfirmationFailure: true,
        sendConfirmationSuccess: false,
      };
    case "SEND_VERIFICATION_CODE_FULFILLED":
      return {
        ...state,
        sendConfirmationFailure: false,
        sendConfirmationSuccess: true,
      };
    default:
      break;
  }

  return state;
}

function profileEditError(action) {
  if (action.payload.response) {
    switch (action.payload.response.status) {
      case 500:
        return [
          "We have encountered an error with our server, please try again in a few minutes",
        ];
      case 422:
        return parseErrorsByKeys(action.payload.response.data.json.errors);
      case 403:
        return parseErrorsByKeys(action.payload.response.data.errors);
      default:
        return null;
    }
  }
}

function parseErrorsByKeys(errors) {
  return Object.keys(errors).reduce((list, c) => list.concat(errors[c]), []);
}

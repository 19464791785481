import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Header, Modal } from "semantic-ui-react";
import FocusLock from "react-focus-lock";
import PropTypes from "prop-types";
import DemographicsForm from "../RegistrationModal/DemographicsForm";
import BusinessForm from "../RegistrationModal/BusinessForm";
import BusinessGoalsForm from "../RegistrationModal/BusinessGoalsForm";
import DemographicSuccess from "./DemographicSuccess";
import { toggleDemographics } from "../../actions/demographicActions";
import { completeDemographics } from "../../actions/loginActions";
import { linkToDashboard } from "../../utils/InternalLinks";


export class DemographicModal extends Component {

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    const ref = this.modalRef;
    if (ref && ref.current) {
      const node = ReactDOM.findDOMNode(ref.current);
      node.scrollIntoView({ block: "start" });
    }
  };

  modalContent(type) {
    switch(type) {
      case types.demographic:
        return <DemographicsForm />;
      case types.business:
        return <BusinessForm />;
      case types.businessgoals:
        return <BusinessGoalsForm />;
      case types.success:
        return <DemographicSuccess closeModal={this.setRedirectToHome} />;
      default:
        break;
    }
  }
  
  getTitle = (type) => {
    switch(type) {
      case types.demographic:
        return "Tell us about yourself";
      case types.business:
        return "Tell us about yourself (cont)";
      case types.businessgoals:
        return "Tell us about yourself (cont)";
      case types.success:
        return "Lets get started on your journey"
      default:
        return "";
    }
  }

  close = () => {
    this.props.dispatch(completeDemographics());
    this.props.dispatch(toggleDemographics(false))
  };

  setRedirectToHome = () => {
    this.close();
    return this.props.history.push(linkToDashboard());
  };

  render() {
    return (
      <Modal
        id="demographics-modal"
        open={this.props.open}
        closeOnDimmerClick={false}
        aria-modal="true"
        tabIndex="-1"
        size={"tiny"}
      >
        <Modal.Content>
          <div ref={this.modalRef}>
            <Grid>              
              <Grid.Row columns={16}>
                <Grid.Column width={16}>
                  <Header id="edit-profile-header">
                    {this.getTitle(this.props.type)}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <hr />
            <div
              onMouseDown={ e => e.preventDefault() }
              onClick={e => e.target.focus()}
            >
              <FocusLock returnFocus={true}>
                {this.modalContent(this.props.type)}
              </FocusLock>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const types = {
  demographic: "demographic",
  business: "business",
  businessgoals: "businessgoals",
};

DemographicModal.propTypes = { type: PropTypes.oneOf(Object.keys(types)) };
DemographicModal.types = types;

export default connect()(withRouter(DemographicModal));

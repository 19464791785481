import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BreadcrumbHeader from "../BreadcrumbHeader/BreadcrumbHeader";
import LeftNavigation from "../LeftNavigation";
import Excursions from "../Excursions/Excursions";
import LearningEvent from "../LearningEvent/LearningEvent";
import MetaTags from "../SEO/MetaTags";
import PathProgress from "../PathProgress";
import {
  getJourney,
  getProgressForJourney
} from "../../actions/journeyActions";
import { withTranslation } from 'react-i18next';
import { linkToExcursion } from "../../utils/InternalLinks";
import { getCustomData, getJourneyName, getTranslation } from "../../utils/HelperFunctions";
import { getProgressForExcursions } from "../../actions/excursionActions";
import { getProgressForPaths } from "../../actions/learningEventActions";

import "./JourneyContentWrapper.scss";

export class JourneyContentWrapper extends Component {
  state = {};

  constructor(props) {
    super(props);

    this.topicSelectChange = this.topicSelectChange.bind(this);
  }

  componentDidMount() {
    this.initialFunctions();
  }

  initialFunctions() {
    const id = this.props.match.params.id;
    const isUserLoggedIn = this.props.isUserLoggedIn;

    this.props.dispatch(getJourney(id));
    if (isUserLoggedIn) {
      const topicId = this.props.match.params.topicId;

      this.props.dispatch(getProgressForJourney(id));
      this.props.dispatch(getProgressForExcursions(id));
      this.props.dispatch(getProgressForPaths(id, topicId));
    }
  }

  componentDidUpdate(prevProps) {
    const { isUserLoggedIn } = this.props;
    const { id, topicId } = this.props.match.params;
    const { isUserLoggedIn: prevIsUserLoggedIn } = prevProps;
    const { id: prevId, topicId: prevTopicId } = prevProps.match.params;

    if (id !== prevId || isUserLoggedIn !== prevIsUserLoggedIn) {
      this.initialFunctions();
    }

    if (topicId !== prevTopicId) {
      this.props.dispatch(getProgressForPaths(id, topicId));
    }
  }

  topicSelectChange(topicId) {
    this.props.history.push("/" + this.props.match.params.id + "/" + topicId);
  }

  getTopicName(topicId) {
    let topic = this.getTopic(topicId);
    return topic ? getTranslation(topic, "name") : null;
  }

  getTopic(topicId) {
    const journey = this.props.journey || {};
    const excursions = journey.excursions || [];
    for (var key in excursions) {
      if (
        excursions[key].id &&
        topicId &&
        excursions[key].id.toString() === topicId.toString()
      ) {
        return excursions[key];
      }
    }
    return null;
  }

  getEventName() {
    if (this.props.learningEvent) {
      return getTranslation(this.props.learningEvent, "title");
    }
    return null;
  }

  renderRightColumnContent() {
    const { isMobile, pathsComplete, pathsTotal } = this.props;
    const { id, topicId, eventId } = this.props.match.params;
    const isLearningEvent = topicId && eventId;
    const journey = this.props.journey || {};
    const excursions = journey.excursions;

    if (isLearningEvent) {
      return <LearningEvent topicTitle={this.getTopicName(topicId)} />;
    } else {
      return (
        <React.Fragment>
          {isMobile && (
            <PathProgress
              pathsComplete={pathsComplete}
              pathsTotal={pathsTotal}
            />
          )}
          <Excursions course_id={id} topicsList={excursions} />
        </React.Fragment>
      );
    }
  }

  render() {
    const journey = this.props.journey || {};

    const leftNavigationProps = {
      journey: journey,
      path_id: this.props.match.params.eventId,
      excursionProgress: this.props.excursionProgress
    };

    const { pathsComplete, pathsTotal, isMobile } = this.props;

    const { topicId, eventId } = this.props.match.params;
    const topic = this.getTopic(topicId);
    const topicName = this.getTopicName(topicId);

    return (
      <div className="journey-content">
        <MetaTags
          metaTitle={topicName}
          metaDescription={getCustomData(topic, "description", null)}
          metaImage={getCustomData(topic, "thumbnail_url", null)}
          canonicalUrl={`${process.env.REACT_APP_SITE_ROOT}${linkToExcursion(
            this.props.match.params.id,
            topicId
          )}`}
        />
        <BreadcrumbHeader
          isMobile={isMobile}
          journey={journey}
          pathName={getJourneyName(journey)}
          topicName={topicName}
          eventName={eventId ? this.getEventName() : null}
        />
        <Grid centered id="journey-content-grid">
          <Grid.Row className="journey-content-row">
            <Grid.Column
              className="mobile hidden"
              tablet={6}
              computer={5}
              largeScreen={4}
              widescreen={4}
              id="journey-sidebar"
            >
              <PathProgress
                pathsComplete={pathsComplete}
                pathsTotal={pathsTotal}
              />
              <LeftNavigation {...leftNavigationProps} />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={10}
              computer={11}
              largeScreen={10}
              widescreen={10}
              tabIndex="-1"
              id="topic-content-view"
            >
              {this.renderRightColumnContent()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    journey: store.journey.journey,
    learningEvent: store.learningEvent.learningEvent,
    journeyLoading: store.journey.journeyLoading,
    pathsComplete: store.journey.pathsComplete,
    pathsTotal: store.journey.pathsTotal,
    isUserLoggedIn: store.login.isUserLoggedIn,
    isMobile: store.global.isMobile,
    excursionProgress: store.excursion.excursionProgress
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(JourneyContentWrapper)));

import React, { Component } from "react";
import StyledText from "./StyledText";
import DescribedImage from "./DescribedImage"
import { withTranslation } from 'react-i18next';

class ImageHeaderText extends Component {
  imageRow() {
    const { content } = this.props;

    if (content && content.image) {
      return (
        <div className="row heading-image">
          <div className="column">
            <DescribedImage {...content.image} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  headerRow() {
    const { headerText } = this.props.content;

    if (headerText) {
      return (
        <div className="row main-header">
          <div className="column">
            <StyledText {...headerText} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  textRow() {
    const { supportingText } = this.props.content;

    if (supportingText) {
      return (
        <div className="row intro-text">
          <div className="column">
            <div>
              <StyledText {...supportingText} />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="image-header-text-wrapper">
        {this.imageRow()}
        {this.headerRow()}
        {this.textRow()}
      </div>
    );
  }
}

export default withTranslation()(ImageHeaderText);
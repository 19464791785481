import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleDropdown from "../HelperComponents/SimpleDropdown/SimpleDropdown";
import { withTranslation } from 'react-i18next';

export class NavigationHelpDropdown extends Component {
  items() {
    const { content, t } = this.props;

    var itemArray = [
      {
        itemKey: "helpdesk",
        text: t('support'),
        ariaLabel: t('supportAria'),

        action: this.props.gotoHelpdesk,
      },
    ];

    if(content && content.length !== undefined) {
      itemArray.push(
        {
          itemKey: "touring",
          text: t('tour'),
          ariaLabel: t('tourAria'),
          action: this.props.showTouring,
        }
      )
    }

    return itemArray;
  }

  render() {
    return (
      <SimpleDropdown
        items={this.items()}
        icon={"question circle outline"}
        activeIcon={"question circle"}
        id="navbar-help-dropdown"
        ariaLabel="Expand User Help menu"
      />
    );
  }
}

const mapStateToProps = (store) => ({
  content: store.touring.content
});

export default withTranslation()(connect(mapStateToProps)(NavigationHelpDropdown));

import React, { Component } from "react";

import SkipLink from "./SkipLink";
import { numberOfOccurrences } from "../../../utils/HelperFunctions";

export class SkipLinks extends Component {
  skipLinks() {
    const pathname = String(this.props.location.pathname);

    return (
      <span key="nav-skip-link">
        <SkipLink
          id="skip-link-content"
          targetDomId="content"
          text="Skip to Content"
        />
        {this.midSkipLink(pathname)}
        <SkipLink
          id="skip-link-footer"
          targetDomId="footer"
          text="Skip to Footer"
        />
      </span>
    );
  }

  midSkipLink(pathname) {
    const journeyRegex = /\/(?!profile|dashboard|search)(.*)/g; // Include all paths except /profile, /dashboard, /search
    if (pathname.includes("search")) {
      return (
        <SkipLink
          id="skip-link-results"
          targetDomId="search-results"
          text="Skip to Search Results"
        />
      );
    } else if (pathname.includes("dashboard")) {
      return (
        <SkipLink
          id="skip-link-journeys"
          targetDomId="card-container"
          text="Skip to Journey Cards"
        />
      );
    } else if (
      numberOfOccurrences(pathname, "/") === 1 &&
      pathname !== "/" &&
      pathname.match(journeyRegex)
    ) {
      // journey page
      return (
        <SkipLink
          id="skip-link-excursions"
          targetDomId="card-container"
          text="Skip to Excursion Cards"
        />
      );
    } else if (numberOfOccurrences(pathname, "/") === 3) {
      // path page
      return (
        <SkipLink
          id="skip-link-path-content"
          targetDomId="topic-content-view"
          text="Skip to Path Content"
        />
      );
    } else if (numberOfOccurrences(pathname, "/") === 2) {
      // excursion page
      return (
        <SkipLink
          id="skip-link-excursion-content"
          targetDomId="topic-content-view"
          text="Skip to Excursion Content"
        />
      );
    }
  }

  render() {
    return <div key="nav-skip-links">{this.skipLinks()}</div>;
  }
}

export default SkipLinks;

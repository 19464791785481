import React from 'react';
import {Input, Label, Message} from 'semantic-ui-react';
import {Grid} from 'semantic-ui-react';

import "./NumericQuestion.scss"

export default class NumericQuestion extends React.Component {
  handleRef = component => (this.ref = component);
  state = {}

  constructor(props) {
    super(props);
    this.changeHandler = this.changeHandler.bind(this);
  }

  componentDidMount() {
    if (this.ref) {
      this.ref.focus();
    }
  }

  onChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  isNumeric(value) {
    return value.match(/^\d*$/);
  }

  changeHandler(event, data) {
    if (data && this.isNumeric(data.value)) {
      this.setState({error: false});
      this.onChange(data.value)
    } else { 
      this.setState({error: true});
    }
  }

  getRef() {
    return this.props.autoFocus ? this.handleRef : null
  }

  render() {
    return (
      <Grid className="numeric-question">
        <Grid.Row>
          <Grid.Column>
            <p className="numeric-question-text"><b>{this.props.text}</b></p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={3} mobile={5}>
            <Input fluid ref={this.getRef()}
                     error={this.state.error}
                     aria-label={this.props.text} 
                     onChange={this.changeHandler} 
                     value={this.props.value}/>
          </Grid.Column>
          <Grid.Column computer={1} mobile={1}>
            <Label className="numeric-label">{this.props.label}</Label>
          </Grid.Column>
          <Grid.Column width={7}>
            {this.state.error ? <Message negative>Numeric values only</Message> : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}
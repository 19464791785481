import React, { Component } from "react";
import ParsedHtml from "../../HelperComponents/ParsedHtml";

export default class Disclaimer extends Component {
  render() {
    const content = this.props.content || {}
    return (
      <p className="disclaimer">
        <ParsedHtml html={content.disclaimerText} />
      </p>
    );
  }
}

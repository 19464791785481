import React, { Component } from "react";
import ImageHeaderText from "./ImageHeaderText";
import DescribedImage from "./DescribedImage";
import { withTranslation } from 'react-i18next';

class StatementImage extends Component {
  imageColumn() {
    return (
      <div className="column image">
        <DescribedImage {...this.props.content.image} />
      </div>
    );
  }

  statementColumn() {
    const headingProps = {
      content: {
        headerText: this.props.content.headerText,
        supportingText: this.props.content.body
      }
    };
    return (
      <div className="column image-stat-text">
        <ImageHeaderText {...headingProps} />
      </div>
    );
  }

  orderedColumns() {
    const { imageSide, mobileImageAlignment } = this.props.content;
    if ((!this.props.useMobileAlignment && imageSide && imageSide.name !== "RIGHT") ||
        (this.props.useMobileAlignment && mobileImageAlignment && mobileImageAlignment.name !== "BOTTOM")) {
      return (
        <React.Fragment>
          {this.imageColumn()}
          {this.statementColumn()}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {this.statementColumn()}
          {this.imageColumn()}
        </React.Fragment>
      );
    }
  }

  render() {
    return <div className="row">{this.orderedColumns()}</div>;
  }
}

export default withTranslation()(StatementImage);
import * as componentParses from "./componentParses";
import { checkExternalLink } from "../../utils/HelperFunctions";

export default function componentTransform(node, index) {
  if (node.type === "tag" && node.name === "div" && node.attribs["class"]) {
    if (node.attribs["class"].includes(parseClasses.expandCollapseText)) {
      return componentParses.expandCollapseTextParse(node, index);
    } else if (
      node.attribs["class"].includes(parseClasses.expandCollapseMarkup)
    ) {
      return componentParses.expandCollapseMarkupParse(node, index);
    }
  } else if (node.type === "tag" && node.name === "a") {
    if (checkExternalLink(node.attribs)) {
      return componentParses.externalLink(node, index);
    } else {
      node.attribs.target = "_blank";
    }
  } else if (node.type === "tag" && node.name === "img") {
    return componentParses.dynamicImage(node, index);
  }
}

// These are a list of classes used as flags to check against to parse html coming
// from canvas into react components using react-html-parser module
const parseClasses = {
  expandCollapseText: "expand-collapse-text",
  expandCollapseMarkup: "expand-collapse-markup",
};

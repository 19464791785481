import React from "react";
import { Button, Container, Divider, Grid } from "semantic-ui-react";
import {
  previousDemographicPage,
  nextDemographicPage,
  saveDemographicDetails,
} from "../../actions/demographicActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import "./OptionalForm.scss";

export class OptionalForm extends React.Component {
  
  nextClick(e) {
    this.props.dispatch(nextDemographicPage());
  }

  prevClick() {
    this.props.dispatch(previousDemographicPage());
  }

  registerClick() {
    this.props.dispatch(
      saveDemographicDetails(this.props.demographicDetails)
    );
  }

  ifButton(bool, button) {
    if (bool) {
      return button;
    }
    return "";
  }

  hasTwoButtons() {
    return (
      (this.props.prevButton && this.props.nextButton) ||
      (this.props.prevButton && this.props.registerButton)
    );
  }

  render() {
    const { t } = this.props;
    
    return (
      <Container id="optional-form">
        <h2>{this.props.header}</h2>
        <p className="optional">(optional)</p>
        {this.props.children}
        <Divider className="optional-form-bottom-divider" />
        <Grid className="optional-button-row-grid">
          <Grid.Row className="button-row">
            <Grid.Column width={16}>
              {this.ifButton(
                this.props.prevButton,
                <Button
                  aria-label="Previous"
                  className="primary previous"
                  onClick={this.prevClick.bind(this)}
                >
                  { t('previous') }
                </Button>
              )}
              {this.ifButton(
                this.props.nextButton,
                <Button
                  aria-label="Next"
                  className="primary next"
                  onClick={this.nextClick.bind(this)}
                  tabIndex="0"
                >
                  { t('next') }
                </Button>
              )}
              {this.ifButton(
                this.props.registerButton,
                <Button
                  aria-label="Register"
                  className="primary"
                  onClick={this.registerClick.bind(this)}
                  tabIndex="0"
                >
                  { t('save') }
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      
    );
  }
}

const mapStateToProps = (store) => {
  return {
    demographicDetails: store.demographic.demographicDetails,
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(OptionalForm)));

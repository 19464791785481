import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LearningEventVideo from "./LearningEventVideo";

const mapStateToProps = store => {
  return {
    isUserLoggedIn: store.login.isUserLoggedIn,
    isMobile: store.global.isMobile,
    isLoading: store.learningEvent.learningEventLoading
  };
};

export default withRouter(connect(mapStateToProps)(LearningEventVideo));

import { applyMiddleware, createStore } from "redux";

import logger from "redux-logger";
import promise from "redux-promise-middleware";
import thunk from 'redux-thunk';
import configureStore from 'redux-mock-store';

import reducer from "./reducers";

export function getStore() {
  let middleware = [thunk, promise()]

  if (process.env.NODE_ENV !== 'production') {
    middleware = [...middleware, logger]
  }

  const store = createStore(reducer, applyMiddleware(...middleware));

  return store;
}

export function getMockStore() {
  return configureStore(
    [ thunk, promise() ]
  );
}

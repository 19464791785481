import React from 'react';
import "./NumberedCircle.scss"

export default class NumberedCircle extends React.Component {
  render() {
    return <div className="number-circle-ring">
             <div className="number-circle">
               <div className="number-circle-text">{this.props.number}</div>
              </div>
            </div>
  }
}
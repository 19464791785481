import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { updateDemographicDetails } from '../../actions/demographicActions';
import CheckboxQuestion from '../CheckboxQuestion/CheckboxQuestion';
import TextQuestion from '../TextQuestion/TextQuestion';
import DropdownQuestion from '../DropdownQuestion/DropdownQuestion';
import NumericQuestion from '../NumericQuestion/NumericQuestion';
import OptionalForm from './OptionalForm';
import GriddedQuestion from './GriddedQuestion';

export class BusinessForm extends React.Component {
  updateState(key, value) {
    this.props.dispatch(updateDemographicDetails(key, value));
  }

  render() {
    const { t } = this.props;

    return (
      <OptionalForm prevButton={true} nextButton={true}>
        <GriddedQuestion number="4">
          <TextQuestion text={ t('nameOfBusiness') } value={this.props.business_name}
                        onChange={(value) => this.updateState("business_name", value)}
                        autoFocus={true}/>
        </GriddedQuestion>
        <GriddedQuestion number="5">
          <NumericQuestion small text={ t('percentOfBusiness') } value={this.props.percent_business_owned}
                        label="%" onChange={(value) => this.updateState("percent_business_owned", value)}/>
        </GriddedQuestion>
        <GriddedQuestion number="6">
          <DropdownQuestion text={ t('totalEmployees.label') } value={this.props.number_employees}
                        onChange={(value) => this.updateState("number_employees", value)}
                        options={[
                          t('totalEmployees.selfEmployed'), 
                          t('totalEmployees.oneToTen'), 
                          t('totalEmployees.elevenToFifty'), 
                          t('totalEmployees.fiftyOneToTwoHundred'), 
                          t('totalEmployees.twoHundredOneToFiveHundred'), 
                          t('totalEmployees.fiveHundredPlus')
                        ]}/>
        </GriddedQuestion>
        <GriddedQuestion number="7" value={this.props.business_types}>
          <CheckboxQuestion label={ t('typeOfBusiness.label') }
                            answers={[
                              t('typeOfBusiness.retail'), 
                              t('typeOfBusiness.construction'), 
                              t('typeOfBusiness.informationTechnology'),
                              t('typeOfBusiness.utilities'),
                              t('typeOfBusiness.mining'),
                              t('typeOfBusiness.manufacturing'),
                              t('typeOfBusiness.financeAndInsurance'),
                              t('typeOfBusiness.wholesaleTrade'),
                              t('typeOfBusiness.publicAdministration'),
                              t('typeOfBusiness.educationalServices'),
                              t('typeOfBusiness.realEstateAndRentalAndLeasing'),
                              t('typeOfBusiness.healthcareAndSocialAssistance'),
                              t('typeOfBusiness.accommodationsAndFoodServices'),
                              t('typeOfBusiness.artsEntertainmentAndRecreation'),
                              t('typeOfBusiness.transportationAndWarehousing'),
                              t('typeOfBusiness.professionalScientificAndTechnicalServices'),
                              t('typeOfBusiness.managementOfCompaniesAndEnterprises'),
                              t('typeOfBusiness.agricultureForestryFishingAndHunting'),
                              t('typeOfBusiness.administrativeAndSupport'),
                              t('typeOfBusiness.wasteManagementAndRemediationServices')
                            ]}
                            customAnswers={[ t('typeOfBusiness.other')]}
                            value={this.props.business_types}
                            onChange={(value) => this.updateState("business_types", value)}/>
        </GriddedQuestion>
      </OptionalForm>
     )
  }
}

const mapStateToProps = store => {
  return {
    business_name: store.demographic.demographicDetails.business_name,
    percent_business_owned: store.demographic.demographicDetails.percent_business_owned,
    number_employees: store.demographic.demographicDetails.number_employees,
    business_types: store.demographic.demographicDetails.business_types
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(BusinessForm)));

import React from "react";
import { Button, Icon } from "semantic-ui-react";

const PlayPauseButton = props => {
  const playPauseName = props.playing ? "pause" : "play";
  return (
    <Button icon basic onClick={props.playPause} aria-label={playPauseName}>
      <Icon name={playPauseName} size="big" inverted />
    </Button>
  );
};

export default PlayPauseButton;

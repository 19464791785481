import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import MetaTags from "./components/SEO/MetaTags";
import LoadForesee from "./utils/LoadForesee";
import { sendPageView, setTrackingCode } from "./utils/AnalyticsFunctions";
import TimeoutWarning from "./components/TimeoutWarning/TimeoutWarning";
import { getUser } from "./actions/registrationActions";
import {
  setMobile,
  setTablet,
  externalSite,
  changeActiveImageViewPort,
} from "./actions/globalActions";
import ExternalSiteModal from "./components/ExternalSiteModal/ExternalSiteModal";
import { checkExternalLink } from "./utils/HelperFunctions";
class App extends Component {
  constructor(props) {
    super(props);

    this.handleLinkClick = this.handleLinkClick.bind(this);
  }
  componentDidMount() {
    setTrackingCode(process.env.REACT_APP_GOOGLE_ANALYTICS_ACCOUNT);
    window.addEventListener("resize", this.resize.bind(this));
    this.props.dispatch(getUser());
    this.resize();
    this.checkATags();
    this.props.dispatch(changeActiveImageViewPort());
    this.setTheme();
  }

  componentDidUpdate(prevProps) {
    this.checkATags();
    if (this.props.location !== prevProps.location) {
      sendPageView();
    }
  }

  setTheme() {
    const theme = process.env.REACT_APP_THEME || "ascent";

    document.documentElement.classList.add(`theme-${theme}`);
  }

  resize() {
    this.props.dispatch(setMobile(window.innerWidth <= 767));
    this.props.dispatch(
      setTablet(window.innerWidth >= 768 && window.innerWidth <= 991)
    );
    this.props.dispatch(changeActiveImageViewPort());
  }

  checkATags() {
    const atags = document.getElementsByTagName("a");
    if (atags.length > 0) {
      for (let i = 0; i < atags.length; i++) {
        if (checkExternalLink(atags[i])) {
          atags[i].removeEventListener("click", this.handleLinkClick);
          atags[i].addEventListener("click", this.handleLinkClick);
        }
      }
    }
  }

  handleLinkClick(e) {
    e.preventDefault();
    // When clicking on Semantic UI icon links we need to do check for parentElement href value
    const href = e.target.href ? e.target.href : e.target.parentElement.href;
    this.props.dispatch(externalSite(true, href));
  }

  renderTimeoutWarning() {
    const requireUser =
      process.env.REACT_APP_REQUIRE_USER_TO_TRACK_PROGRESS !== "false";
    if (requireUser) {
      return (
        <TimeoutWarning
          warning={process.env.REACT_APP_TIMEOUT_WARNING || 900}
          timeout={process.env.REACT_APP_TIMEOUT || 1200}
        />
      );
    }
  }

  render() {
    LoadForesee();

    return (
      <div className="application">
        {this.renderTimeoutWarning()}
        <ExternalSiteModal />
        <MetaTags />
        {this.props.isInitialized ? <Navigation /> : ""}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isMobile: store.global.isMobile,
    isInitialized: store.global.isInitialized,
  };
};
export default withRouter(connect(mapStateToProps)(App));

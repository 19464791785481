import React, { Component } from "react";
import { Button, Container, Form, Grid, Input } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';
import { requestPasswordReset } from "../../actions/registrationActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./ForgotPasswordForm.scss";

export class ForgotPasswordForm extends Component {
  state = {
    email: '',
    password: ''
  };
  handleRef = component => (this.ref = component);

  handleEmailChange = (e) => {
    this.setState({email: e.target.value});
  }

  handlePasswordChange = (e) => {
    this.setState({password: e.target.value});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.dispatch(requestPasswordReset({
      email: this.state.email
    }));
  }

  componentDidMount() {
    if (this.ref) {
      this.ref.focus();
    }
  }

  // User will see same response msg regardless of success or failure
  returnUserMessageIfNeeded(onPasswordSubmit) {
    const { t } = this.props;
    
    if ((this.props.userError) || (onPasswordSubmit)) {
      return (
        <Grid centered>
          <Grid.Row>
            <Grid.Column textAlign="left" className="ui success message">
              { t('forgotPasswordForm.messages.success') } {this.state.email}.
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
  }

  getPasswordFormOrResetMessage(onPasswordSubmit) {
    const { t } = this.props;

    return (
      <Container className="reset-modal">
        {this.returnUserMessageIfNeeded(onPasswordSubmit)}
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Grid centered>
            <Grid.Row>
              <Grid.Column textAlign="left">
                { t('forgotPasswordForm.instructions')}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Form.Field>
            <label>{ t('emailAddress') }</label>
            <Input type="text"
                    value={this.state.email}
                    aria-label={ t('emailAddress') }
                    ref={this.handleRef}
                    placeholder={ t('emailAddress') }
                    onChange={this.handleEmailChange.bind(this)}/> <br />
          </Form.Field>
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button className="secondary" aria-label={ t('submit') } type="submit">{ t('forgotPasswordForm.button') }</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    );
  }

  render() {
    return (
      <Container>
        {this.getPasswordFormOrResetMessage(this.props.onPasswordSubmit)}
      </Container>
    );
  }
}

const mapStateToProps = store => {
  return {
    email: store.registration.userData.email,
    password: store.registration.userData.password,
    userError: store.registration.userError,
    onPasswordSubmit: store.registration.onPasswordSubmit
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(ForgotPasswordForm)));

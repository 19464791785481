import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import "./EventFooterButtons.scss";

export function EventNextButton(props) {
  const { t } = useTranslation();

  return (
    <Link tabIndex="-1" className="event-footer-button-link" to={props.url}>
      <Button
        icon="right chevron"
        content={ t(props.text) }
        labelPosition="right"
        primary
        className="event-footer-buttons"
      />
    </Link>
  );
}

export function EventPrevButton(props) {
  const { t } = useTranslation();

  return (
    <Link className="event-footer-button-link" to={props.url}>
      <Button
        icon="left chevron"
        content={ t('previous') }
        labelPosition="left"
        tabIndex="-1"
        className="event-footer-buttons"
      />
    </Link>
  );
}

import React, { Component } from "react";
import { Card, Container } from "semantic-ui-react";
import CardContent from "./CardContent";
import TitledModule from "../TitledModule/TitledModule";

import "./CardGrid.scss";

export default class CardGrid extends Component {
  listCards() {
    return(
      this.props.content.cards.map( (card, index) =>
        <CardContent content={card} key={index} />
      )
    )
  }

  render() {
    return(
      <div className="card-grid-container">
        <div>
          <TitledModule
            title={this.props.content.title}
          >
            <Container>
              <Card.Group
                className="card-grid"
                textAlign="center"
              >
              { this.listCards() }
              </Card.Group>
            </Container>
          </TitledModule>
        </div>
      </div>
    )
  }
}

import React from "react";
import { convertNodeToElement } from "react-html-parser";
import ReactDOMServer from "react-dom/server";

import ExpandCollapseText from "../../components/HelperComponents/ExpandCollapseText";
import ExpandCollapseMarkup from "../../components/HelperComponents/ExpandCollapseMarkup";
import ExternalLink from "../../components/HelperComponents/ExternalLink";
import DynamicImage from "../../components/HelperComponents/DynamicImage";

export function expandCollapseTextParse(node, index) {
  //The block of text in this node is located in the children array
  const textNode = node.children.find((child) => child.type === "text");
  return (
    <ExpandCollapseText
      text={textNode && textNode.data ? textNode.data : ""}
      defaultTextLength={550}
      key={`parse-expand-collapse-${index}`}
    />
  );
}

export function expandCollapseMarkupParse(node, index) {
  let defaultHtml = null;
  let expandHtml = null;

  // Since we are returning a react component, we need to mimic the parsing for the children elements
  // We convert the child nodes to elements, then flatten those back to a string
  // for the ExpandCollapseMarkup component to parse further
  node.children.forEach((child) => {
    if (child.attribs) {
      const convertedNode = convertNodeToElement(child, child.index, {});

      if (child.attribs["class"].includes("expand-html")) {
        expandHtml = ReactDOMServer.renderToStaticMarkup(convertedNode);
      } else if (child.attribs["class"].includes("default-html")) {
        defaultHtml = ReactDOMServer.renderToStaticMarkup(convertedNode);
      }
    }
  });

  return (
    <ExpandCollapseMarkup
      key={`markup-expand-collapse-${index}`}
      defaultHtml={defaultHtml}
      expandHtml={expandHtml}
    />
  );
}

export function dynamicImage(node, index) {
  const convertedNode = convertNodeToElement(node, index, {});

  return (
    <DynamicImage
      key={`parsed-dynamic-image-${index}`}
      {...convertedNode.props}
    />
  );
}

export function externalLink(node, index) {
  const convertedNode = convertNodeToElement(node, index, {});
  return (
    <ExternalLink
      key={"external-link-" + index}
      parsedNode={convertedNode.props}
    />
  );
}

import React from 'react';
import {Grid} from "semantic-ui-react";

import "./error.scss"
import {helpdeskLink} from "../../utils/ExternalLinks";
import DynamicImage from "../HelperComponents/DynamicImage";

const InternalServerError = () => {
  return (
    <Grid textAlign="center" id="error-page">
      <Grid.Row>
        <Grid.Column width={15}>
          <DynamicImage src="/errors/500_error.png" alt="Internal Server Error" centered/>
          <h1>Oops! Something is blocking the path</h1>
          <p>Something went wrong on our end and we’re working to fix the problem! Please try again later.</p>
          <p>If the problem persists, please contact our <a href={helpdeskLink()}>Help Desk</a> for additional information. Thanks for your patience!</p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default InternalServerError;
import React, {Component} from "react";
import InternalServerError from "./InternalServerError";


export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true
    });
  }

  render() {
    if (this.state.hasError) {
      return <InternalServerError />
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { updateDemographicDetails } from '../../actions/demographicActions';
import NumericQuestionWithLabel from '../NumericQuestionWithLabel/NumericQuestionWithLabel';
import OptionalForm from './OptionalForm';
import GriddedQuestion from './GriddedQuestion';
import CheckboxQuestion from '../CheckboxQuestion/CheckboxQuestion';


import i18n from '../../i18n/config';

export class BusinessGoalsForm extends React.Component {
  updateState(key, value) {
    this.props.dispatch(updateDemographicDetails(key, value));
  }

  render() {
    const { t } = this.props;

    return (
      <OptionalForm prevButton={true} registerButton={true}>
        <GriddedQuestion number="8">
          <NumericQuestionWithLabel small text={ t('revenueGoal.label') }
                        value={this.props.revenue_goal} options={[t('revenueGoal.options.percent'), t('revenueGoal.options.dollars')]}
                        onChange={(value) => this.updateState("revenue_goal", value)}
                        autoFocus={true}/>
        </GriddedQuestion>
        <GriddedQuestion number="9">
          <NumericQuestionWithLabel text={ t('employeeGoal.label') }
                        value={this.props.employment_goal} options={[t('employeeGoal.options.percent'), t('employeeGoal.options.number')]}
                        onChange={(value) => this.updateState("employment_goal", value)}/>
        </GriddedQuestion>
        <GriddedQuestion number="10">
          <CheckboxQuestion label={ t('certifiedGovernementContracting.label') } onlyAllowOne={true}
                            answers={[
                              t('certifiedGovernementContracting.options.yes'), 
                              t('certifiedGovernementContracting.options.no'),
                              t('certifiedGovernementContracting.options.notSure')
                            ]}
                            value={this.props.certified_for_contracting}
                            onChange={(value) => this.updateState("certified_for_contracting", value)} />
        </GriddedQuestion>
        <GriddedQuestion number="11">
          <CheckboxQuestion label={ t('certifications.label') }
                            answers={[
                              t('certifications.options.smallBusiness'), 
                              t('certifications.options.wosb'),
                              t('certifications.options.hz'),
                              t('certifications.options.8a'),
                              t('certifications.options.vet'),
                              t('certifications.options.sdvosb'),
                            ]}
                            value={this.props.certifications}
                            onChange={(value) => this.updateState("certifications", value)}/>
        </GriddedQuestion>
        <GriddedQuestion number="12">
          <CheckboxQuestion label={ t('federalGovernmentProcurement.label') } onlyAllowOne={true}
                            answers={[
                              t('federalGovernmentProcurement.options.yes'), 
                              t('federalGovernmentProcurement.options.no'),
                              t('federalGovernmentProcurement.options.notSure')
                            ]}
                            value={this.props.current_procuring}
                            onChange={(value) => this.updateState("current_procuring", value)} />
        </GriddedQuestion>
      </OptionalForm>
    )
  }
}

const mapStateToProps = store => {
  return {
    revenue_goal: store.demographic.demographicDetails.revenue_goal,
    employment_goal: store.demographic.demographicDetails.employment_goal,
    certified_for_contracting: store.demographic.demographicDetails.certified_for_contracting,
    certifications: store.demographic.demographicDetails.certifications,
    current_procuring: store.demographic.demographicDetails.current_procuring,
  }
}

//export default withTranslation('translations')(withRouter(connect(mapStateToProps)(BusinessGoalsForm)));

export default withRouter(connect(mapStateToProps)(withTranslation()(BusinessGoalsForm)));
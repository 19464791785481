import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import Navbar from "./Navbar";
import NavbarSearchInput from "./NavbarSearchInput";
import NavigationDisplay from "./NavigationDisplay";
import ErrorBoundary from "../Errors/ErrorBoundary";
import Footer from "../Footer/Footer";

import "../../assets/styles/_base.scss";
import "./Navigation.scss";

export class Navigation extends Component {
  render() {
    const sbaMainFixed = this.props.isDisableScroll ? 'sba-main-fixed' : '';
    const mobileSearch = this.props.isMobile ? <NavbarSearchInput/> : "";

    return (
      <Grid stackable className="sba">
        <Grid.Row columns={1} id="sba-main-row">
          <Grid.Column id="sba-main-column" className={`${sbaMainFixed}`}>
            <Navbar/>
            {mobileSearch}
            <ErrorBoundary>
              <div tabIndex="-1" id="content" role="main" className="content-window">
                <NavigationDisplay/>
              </div>
            </ErrorBoundary>
            <Footer/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = store => {
  return {
    isMobile: store.global.isMobile,
    isDisableScroll: store.global.isDisableScroll
  };
};

export default withRouter(connect(mapStateToProps)(Navigation));

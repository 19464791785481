import { userClient } from "../utils/axiosClients";

export function getProgressForExcursion(pathId, excursionId) {
  return (dispatch, getState) => {
    const url = `journeys/${pathId}/excursions/${excursionId}/progress`;
    dispatch({
      type: "GET_PROGRESS_FOR_EXCURSION",
      meta: { excursionId },
      payload: userClient(getState()).get(url),
    });
  };
}

export function getProgressForExcursions(journeyId) {
  return (dispatch, getState) => {
    const url = `journeys/${journeyId}/excursion_progress`;
    dispatch({
      type: "GET_PROGRESS_FOR_EXCURSIONS",
      payload: userClient(getState()).get(url),
    });
  };
}

import React, { Component } from "react";
import Duration from "../../../utils/Duration";
import { formatReadableTime } from "../../../utils/HelperFunctions";
import { Range, getTrackBackground } from "react-range";

const MIN = 0;
const MAX = 1;
const STEP = 0.01;

export default class VideoScrubber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false
    };
  }

  videoTime() {
    const seconds = this.props.duration * this.props.played;
    return (
      <span className="video-tooltip-time">
        <Duration seconds={seconds} />
      </span>
    );
  }

  scrubberAriaText(played, duration) {
    const playedSeconds = duration * played;
    return `${formatReadableTime(playedSeconds)} of ${formatReadableTime(
      duration
    )}`;
  }

  render() {
    const { played, duration } = this.props;
    const ariaValueText = this.scrubberAriaText(played, duration);
    return (
      <div className="video-seek-bar">
        <Range
          values={[this.props.played]}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={this.props.onSliderChange}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                display: "flex",
                width: "100%"
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "11px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: [this.props.played],
                    colors: ["orange", "white"],
                    min: MIN,
                    max: MAX
                  }),
                  alignSelf: "center"
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              aria-valuemin={MIN}
              aria-valuemax={duration}
              aria-valuenow={played}
              aria-valuetext={ariaValueText}
              aria-label="video scrubber handle"
              style={{
                ...props.style,
                height: "22px",
                width: "22px",
                borderRadius: "50%",
                backgroundColor: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              onMouseOver={() => this.setState({ showTooltip: true })}
              onMouseLeave={() => this.setState({ showTooltip: false })}
            >
              <div
                className="video-scrubber-tooltip"
                style={{
                  position: "absolute",
                  top: "-50px",
                  color: "#fff",
                  padding: "8px 10px",
                  backgroundColor: "#6c6c6c",
                  display:
                    this.state.showTooltip || isDragged ? "unset" : "none"
                }}
              >
                {this.videoTime()}
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

import React, { Component } from "react";
import TeaserComponent from "../../TeaserComponent/TeaserComponent";
import { Divider } from "semantic-ui-react";
import HomepageExcursions from "../../HomepageExcursions/HomepageExcursions";
import HomepageButton from "./HomepageButton";
import HomepageContainer from "../HomepageContainer";
import HeroModule from "../HeroModule";
import Disclaimer from "./Disclaimer";
import CardGrid from "../../ContentBlock/CardGrid";
import get from "lodash/get";

export default class HomepageBlockManager extends Component {
  render() {
    const type = this.props.type || get(this.props, 'block.type', {});
    const content = this.props.block || this.props;
    switch (type) {
      case "teaser-component":
        return <TeaserComponent {...content} />;
      case "divider":
        return <Divider />;
      case "hero-module":
        return <HeroModule {...content}/>
      case "container":
        return <HomepageContainer {...content}/>
      case "card-grid":
        return <CardGrid {...content} />
      case "button":
        return <HomepageButton {...content} />;
      case "content-cards":
        return <HomepageExcursions {...content} />;
      case "disclaimer":
        return <Disclaimer {...content} />;
      default:
        return null;
    }
  }
}

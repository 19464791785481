import { viewForPage } from "../utils/AnalyticsFunctions";
import LoginModal from "../components/LoginModal/LoginModal";
import { isUserRequired } from "../utils/EnvironmentFunctions";

function defaultState() {
  return {
    userData: {
      email: '',
      password: '',
    },
    mfaEnrollment: {
      phone_number: '',
    },
    mfaVerification: {
      code: ''
    },
    isUserLoggedIn: false,
    clearLoginForm: false,
    open: false,
    currentScreen: 'credentials',
    demographicsCompleted: false,
    mfaEnabled: false,
    userError: ''
  }
}

export default function reducer(state=defaultState(), action) {
  let gaAccount = process.env.REACT_APP_GOOGLE_ANALYTICS_ACCOUNT;
  let gaLoginPathVar = "login";
  let gaRejectedVar = "rejected";

  switch(action.type) {
    case "TOGGLE_LOGIN_MODAL":
      const screen = action.payload.show ? state.currentScreen : LoginModal.types.credentials;
      return {
        ...state,
        currentScreen: screen,
        open: action.payload.show,
      };
    case "SUBMIT_CREDENTIALS":
      return {
        ...state,
        currentScreen: LoginModal.types.enable2fa,
      };
    case "ENROLL_MFA":
      return {
        ...state,
        currentScreen: LoginModal.types.enable2fa,
        open: true
      };
    case "VERIFY_MFA":
      return {
        ...state,
        currentScreen: LoginModal.types.response2fa
      }


    case 'RESET':
      return defaultState();
    case 'LOGIN':
      if (gaAccount !== undefined) {
        viewForPage(
          `/${gaLoginPathVar}`,
          "Login"
        );
      }

      return {
        ...state,
        userData: {},
        userLoading: true,
        userError: null,
        clearLoginForm: false
      };
    case 'SUBMIT_CREDENTIALS_REJECTED':
      if (gaAccount !== undefined) {
        viewForPage(
          `/${gaLoginPathVar}/${gaRejectedVar}`,
          "Login Rejected"
        );
      }

      let clearLoginForm = action.payload.response && action.payload.response.status === 403;
      let error =
        ((action.payload.response == null) || (action.payload.response.status ===  500)) ?
          ["We have encountered an error with our authentication service, please try again in a few minutes"] :
          action.payload.response.status === 422 ?
            Object.keys(action.payload.response.data.errors).reduce((list, c) =>
              list.concat(c + " " + action.payload.response.data.errors[c]), []) :
            action.payload.response.status === 403 ?
              Object.keys(action.payload.response.data.errors).reduce((list, c) =>
                list.concat(action.payload.response.data.errors[c]), []) :
              [action.payload.message]
      return {
        ...state,
        userData: {
          password: ''
        },
        userError: error,
      };
    case 'INITIALIZED_FULFILLED':
      if (action.payload.data.user) {
        const isLoggedIn = isUserRequired() ? action.payload.data.is_logged_in : true;

        return {
          ...state,
          userData: action.payload.data,
          userLoading: false,
          userError: null,
          demographicsCompleted: action.payload.data.demographics_completed,
          isUserLoggedIn: isLoggedIn
        }
      } else {
        const error = [
          "We have encountered an error with our authentication service, please try again in a few minutes"
        ];
        return {
          ...state,
          userData: {},
          userLoading: false,
          userError: error
        };
      }
    case "SUBMIT_CREDENTIALS_FULFILLED":
      if (!action.payload.data.user) {
        // This is a patch to account for a 200 coming back but a bad response and containing
        // no user data. So I mimic the LOGIN_REJECTED reducer to trigger error state
        const error = [
          "We have encountered an error with our authentication service, please try again in a few minutes"
        ];
        return {
          ...state,
          userData: {},
          userLoading: false,
          userError: error
        };
      } else {
        // Normal behavior
        if (action.payload.data.mfa_enabled) {
          var targetModal = LoginModal.types.response2fa
        } else {
          var targetModal = LoginModal.types.enable2fa
        }

        return {
          ...state,
          userData: action.payload.data,
          userLoading: false,
          userError: null,
          clearLoginForm: false,
          mfaEnabled: action.payload.data.mfa_enabled,
          currentScreen: targetModal,
          demographicsCompleted: action.payload.data.demographics_completed
        };
      }
    case "ENROLL_MFA_FULFILLED":
      if (gaAccount !== undefined) {
        viewForPage(
          `/${gaLoginPathVar}/success`,
          "Login"
        );
      }

      return {
        ...state,
        userLoading: false,
        userError: null,
        clearLoginForm: false,
        currentScreen: LoginModal.types.response2fa,
      };
    case "ENROLL_MFA_REJECTED":
      return {
        ...state,
        userError: requestErrors(action.payload)
      }
    case "VERIFY_MFA_FULFILLED":
      return {
        ...state,
        isUserLoggedIn: true,
        userError: null,
        mfaEnabled: true,
        open: false,
        currentScreen: LoginModal.types.credentials
      }
    case "VERIFY_MFA_REJECTED":
      return {
        ...state,
        userError: requestErrors(action.payload)
      }
    case "CONFIRM_PHONE_NUMBER_FULFILLED":
      return {
        ...state,
        userError: null,
        currentScreen: LoginModal.types.credentials,
        open: false,
      }
    case "CONFIRM_PHONE_NUMBER_REJECTED":
      return {
        ...state,
        userError: requestErrors(action.payload)
      }
    case 'LOGIN_VALIDATION_FAILED','SUBMIT_CREDENTIALS_VALIDATION_FAILED':
      if (gaAccount !== undefined) {
        viewForPage(
          `/${gaLoginPathVar}/${gaRejectedVar}/validation-failed`,
          "Login Validation Failed"
        );
      }

      return {
        ...state,
        userData: {
          password: ''
        },
        userLoading: false,
        userError: action.payload,
        clearLoginForm: false
      };
    case "LOGOUT_REJECTED":
      return {
        ...state,
        userData: {},
        userLoading: false,
        userError: null,
        isUserLoggedIn: false,
        clearLoginForm: false,
      };
    case "LOGOUT_FULFILLED":
      if (gaAccount !== undefined) {
        viewForPage(
          "/logout",
          "Logout"
        );
      }

      return {
        ...state,
        userData: {},
        userLoading: false,
        userError: null,
        isUserLoggedIn: false,
        clearLoginForm: false
      };
    case 'CLEANUP_LOGIN_ERRORS':
      return {
        ...state,
        userError: null
      }
    case 'RESET_CLEAR_LOGIN_FORM':
      return {
        ...state,
        clearLoginForm: false
      }
    case "LOGIN_COMPLETE_DEMOGRAPHICS":
      return {
        ...state,
        demographicsCompleted: true,
      };
    default:
      break;
  }

  return state;
}

function requestErrors(payload) {
  const response = payload.response || {};
  switch (response.status) {
    case 500: {
      return [
        "We have encountered an error with our server, please try again in a few minutes",
      ];
    }
    case 422: {
      return Object.keys(response.data.json.errors).reduce(
        (list, c) => list.concat(response.data.json.errors[c]),
        []
      );
    }
    default:
      return [payload.message];
  }
}

import React, { Component } from "react";
import { Button, Divider, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  clearQuizSubmission,
  submitQuiz,
  completeLearningEvent
} from "../../../actions/learningEventActions";
import { MultipleChoiceQuestion } from "./LearningEventQuestions";
import ParsedHtml from "../../HelperComponents/ParsedHtml";

export class LearningEventQuiz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answers: {}
    };

    this.beginQuiz = this.beginQuiz.bind(this);
    this.recordAnswer = this.recordAnswer.bind(this);
    this.viewResults = this.viewResults.bind(this);
  }

  componentDidMount() {
    if (this.props.event) {
      this.beginQuiz();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.event !== prevProps.event) {
      this.beginQuiz();
    }
  }

  completeEvent() {
    const { isUserLoggedIn, event } = this.props;

    if (isUserLoggedIn && !event.completed) {
      const { id: path_id, topicId: objective_id } = this.props.match.params;

      this.props.dispatch(
        completeLearningEvent(path_id, objective_id, event.id)
      );
    }
  }

  recordAnswer(id, answer) {
    this.setState({
      answers: {
        ...this.state.answers,
        [id]: answer
      }
    });
  }

  checkToEnableViewResults() {
    const answers = Object.keys(this.state.answers);
    const quiz = this.props.event;

    return !(
      answers &&
      quiz.questions &&
      answers.length === quiz.questions.length
    );
  }

  beginQuiz() {
    this.setState({ answers: {} });
    this.props.dispatch(clearQuizSubmission());
  }

  viewResults() {
    const answers = Object.keys(this.state.answers).map(k => {
      return {
        value: this.state.answers[k]
      };
    });

    const submission = {
      answers: answers
    };

    this.props.dispatch(submitQuiz(this.props.event.id, submission));
    this.completeEvent();
  }

  renderQuestions(quiz) {
    const viewResultsDisabled = this.checkToEnableViewResults();
    if (quiz && quiz.questions) {
      const questionElements = quiz.questions.map((question, i) => {
        return (
          <MultipleChoiceQuestion
            key={i}
            questionIndex={i}
            order={i + 1}
            question={question}
            onSelected={this.recordAnswer}
          />
        );
      });

      return (
        <React.Fragment>
          {questionElements}
          <div style={{ textAlign: "right" }}>
            <Button
              className="gold"
              onClick={this.viewResults}
              disabled={viewResultsDisabled}
            >
              View Results
            </Button>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  renderResults() {
    const { results } = this.props;
    const assessment = results && results.assessment ? results.assessment : {};
    const resultHeader = assessment.name || "No Matching Results";
    const resultText =
      assessment.description || "Your submission does not match any results";
    window.scrollTo(0, 0)

    return (
      <div>
        <Header as="h2">{resultHeader}</Header>
        <div>{resultText}</div>
        <div style={{ textAlign: "right" }}>
          <Button className="gold" onClick={this.beginQuiz}>
            Retake Assessment
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { event: quiz, results } = this.props;
    return (
      <div className="learning-event-quiz">
        <span>
          <ParsedHtml html={quiz.eventContent.body}></ParsedHtml>
        </span>

        <Divider />
        {results ? this.renderResults() : this.renderQuestions(quiz)}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    results: store.learningEvent.submitQuiz,
    isUserLoggedIn: store.login.isUserLoggedIn
  };
};

export default withRouter(connect(mapStateToProps)(LearningEventQuiz));

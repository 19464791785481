import React, { Component } from "react";
import { Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DynamicImage from "../../../../HelperComponents/DynamicImage";

import { forcePathDropdownCloseToggle } from "../../../../../actions/navbarActions";

export class NavigationJourneyIntro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  handleItemClick(e, journey) {
    e.preventDefault();
    this.props.history.push(journey);
    // Semantic nested dropdown is having issues with not closing the dropdown
    // When item link is clicked
    // This is a workaround to control the component just to close it
    this.props.dispatch(forcePathDropdownCloseToggle(true));
  }

  render() {
    return (
      <div className="navigation-journey-intro">
        <div className="navigation-journey-menu-image">
          <DynamicImage
            className="navigation-journey-image"
            src={this.props.image}
            alt={this.props.imageAltText}
            circular
          />
        </div>
        <div>
          <div>
            <Header as="h1" className="navigation-journey-menu-header">
              <Link
                to={`/${this.props.id}`}
                onClick={(e) => this.handleItemClick(e, `/${this.props.id}`)}
              >
                {this.props.name}
              </Link>
            </Header>
            <em>
              {this.props.topicsNumber} {this.props.journeyTimeLength}
            </em>
            <br />
            <p>{this.props.introText}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(NavigationJourneyIntro));

import React, { Component } from "react";
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Message,
} from "semantic-ui-react";
import { withTranslation } from 'react-i18next';
import { editProfile, toggleProfile } from "../../actions/profileActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PasswordRules from "../PasswordValidator/PasswordRules";
import PasswordValidator from "../PasswordValidator/PasswordValidator";
import "../SignUpForm/SignUpForm.scss";

export class EditProfileForm extends Component {
  state = this.getState();

  getState() {
    return {
      first_name: this.checkProps("first_name"),
      last_name: this.checkProps("last_name"),
      middle_name: this.checkProps("middle_name"),
      zip_code: this.checkProps("zip_code"),
      email: this.checkProps("email"),
      user_id: this.checkProps("user_id"),
      previousEmail: this.checkProps("email")
        ? this.checkProps("email").slice(0)
        : "",
      current_password: "",
      password: "",
      password_confirmation: "",
      emailHasChanged: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { userError } = this.props;
    if (userError && userError !== prevProps.userError) {
      this.props.scrollToTop();
    }
  }

  checkProps(propName) {
    try {
      return this.props.userProfile[propName];
    } catch {
      return "";
    }
  }

  checkState(stateName) {
    try {
      return this.state[stateName].trim();
    } catch {
      return "";
    }
  }

  handleRef = (component) => (this.ref = component);

  handleSubmit = (e) => {
    e.preventDefault();

    var emailHasChanged =
      this.state.email.trim() !== this.state.previousEmail.trim();

    this.setState({ emailHasChanged: emailHasChanged }, () => {
      this.props.dispatch(
        editProfile({
          first_name: this.checkState("first_name"),
          last_name: this.checkState("last_name"),
          middle_name: this.checkState("middle_name"),
          zip_code: this.checkState("zip_code"),
          email: this.checkState("email"),
          user_id: this.checkState("user_id"),
          current_password: this.checkState("current_password"),
          password: this.checkState("password"),
          password_confirmation: this.checkState("password_confirmation"),
        })
      );
    });
  };

  submitEnabled() {
    return (
      this.hasValue(this.state.first_name) &&
      this.hasValue(this.state.last_name) &&
      this.hasValue(this.state.email) &&
      this.hasValue(this.state.zip_code) &&
      /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.state.zip_code) &&
      ((!this.hasValue(this.state.current_password) &&
        !this.hasValue(this.state.password) &&
        !this.hasValue(this.state.password_confirmation)) ||
        (this.hasValue(this.state.current_password) &&
          this.hasValue(this.state.password) &&
          this.hasValue(this.state.password_confirmation) &&
          PasswordRules.valid(
            this.state.password,
            this.state.password_confirmation
          )))
    );
  }

  hasValue(value) {
    return value.length > 0;
  }

  componentDidMount() {
    if (this.ref) {
      this.ref.focus();
    }
  }

  getChangeHandler(field) {
    return (e) => {
      var state = {};
      state[field] = e.target.value;
      this.setState(state);
    };
  }

  getErrorMessageIfNeeded() {
    if (this.props.userError) {
      const text = this.props.userError.join(" ");
      return (
        <div className="edit-profile-error-message">
          <Message negative>{text}</Message>
        </div>
      );
    }
  }

  getField(field, fieldName, type, required) {
    return (
      <Form.Field required={required}>
        <label>{fieldName}</label>
        <input
          placeholder={fieldName}
          type={type}
          name={fieldName}
          aria-label={fieldName}
          value={this.state[field]}
          ref={field === "first_name" ? this.handleRef : ""}
          onChange={this.getChangeHandler(field).bind(this)}
          error={"false"}
        />
      </Form.Field>
    );
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  getProfileForm() {
    const { t } = this.props;

    return (
      <Container
        className="profile-modal-container"
        onKeyDown={this.handleKeyPress}
      >
        {this.getErrorMessageIfNeeded()}
        <Form onSubmit={this.handleSubmit.bind(this)}>
          {this.getField("first_name", t('firstName'), "text", true)}
          {this.getField("last_name", t('lastName'), "text", true)}
          {this.getField("middle_name", t('middleName'), "text", false)}
          {this.getField("email", t('emailAddress'), "text", true)}
          {this.getField("zip_code", t('postalCode'), "text", true)}
          <Divider />
          <Form.Group grouped required>
            <p className="change-password-msg">
              { t('editProfileForm.passwordMessage') }
            </p>
            {this.getField(
              "current_password",
              t('editProfileForm.fields.currentPassword'),
              "password",
              true
            )}
            {this.getField("password", "New Password", "password", true)}
            {this.getField(
              "password_confirmation",
              t('editProfileForm.fields.confirmationPassword'),
              "password",
              true
            )}
            <Grid centered>
              <Grid.Row>
                <Grid.Column>
                  <PasswordValidator
                    password={this.state.password}
                    confirm={this.state.password_confirmation}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group grouped className="edit-form-button-group">
            <Button
              aria-label="Cancel"
              className="form-cancel-button"
              size="large"
              onClick={this.props.close}
            >
              Cancel
            </Button>
            <Button
              aria-label="Submit"
              className="primary"
              type="submit"
              size="large"
              disabled={!this.submitEnabled()}
            >
              Save
            </Button>
          </Form.Group>
        </Form>
      </Container>
    );
  }

  getEmailChangedMessage() {
    if (this.state.emailHasChanged) {
      return (
        <React.Fragment>
          <p>
            { t("editProfileForm.emailChangedMessage.paragraphOne") }
          </p>
          <p>
            { t("editProfileForm.emailChangedMessage.paragraphTwo") }
          </p>
        </React.Fragment>
      );
    }
    return null;
  }

  getConfirmationMessage() {
    return (
      <Container className="profile-modal-container">
        <div className="profile-modal-messaging">
          <p>
            <span className="profile-modal-emphasis">
              { t("editProfileForm.successMessage") }
            </span>
          </p>

          {this.getEmailChangedMessage()}
        </div>

        <Divider />
        <div className="edit-form-button-group">
          <Button
            size="large"
            aria-label="Close"
            content="Close"
            primary
            onClick={() => {
              this.props.dispatch(toggleProfile(false));
            }}
          />
        </div>
      </Container>
    );
  }

  render() {
    const container =
      this.props.onProfileSubmit && !this.props.userError
        ? this.getConfirmationMessage()
        : this.getProfileForm();
    return <Container className="reset-form">{container}</Container>;
  }
}

const mapStateToProps = (store) => {
  return {
    userProfile: store.userProfile.userData,
    userError: store.userProfile.userError,
    onProfileSubmit: store.userProfile.onProfileSubmit,
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(EditProfileForm)));

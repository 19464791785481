import React, { Component } from "react";
import StyledText from "./StyledText";
import { withTranslation } from 'react-i18next';

class StatementText extends Component {
  statementColumn(content) {
    return (
      <div className="column statement">
        <StyledText {...content.headerText} />
      </div>
    );
  }

  supportingTextColumn(content) {
    return (
      <div className="column supporting-text">
        <StyledText {...content.body} />
      </div>
    );
  }

  renderColumns() {
    const { content } = this.props;

    if ((!this.props.useMobileAlignment && content.statementAlignment !== "RIGHT") ||
        (this.props.useMobileAlignment && content.mobileStatementAlignment && content.mobileStatementAlignment !== "BOTTOM")) {
      return (
        <React.Fragment>
          {this.statementColumn(content)}
          {this.supportingTextColumn(content)}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {this.supportingTextColumn(content)}
          {this.statementColumn(content)}
        </React.Fragment>
      );
    }
  }

  render() {
    return <div className="row">{this.renderColumns()}</div>;
  }
}

export default withTranslation()(StatementText);
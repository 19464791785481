import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import ParsedHtml from "../HelperComponents/ParsedHtml";
import { getCustomData } from "../../utils/HelperFunctions";

import "./LeadInText.scss";

export class LeadInText extends Component {

  render() {
    const text = getCustomData(this.props.event, 'description', '');
    if (!text)
      return(<span/>);
    return(
      <Grid className="leader-text-wrapper">
        <Grid.Row className="leader-text-row">
          <Grid.Column>
            <ParsedHtml html={text} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default LeadInText;

import { contentClient } from "../utils/axiosClients";

export function search(searchObject) {
  return (dispatch) => {
    dispatch({
      type: "SEARCH",
      payload: contentClient().get("/search", {
        params: searchObject,
      }),
    });
  };
}

export function checkFacet(filter, facet) {
  return {
    type: `${filter}_CHECK_FACET`,
    payload: facet,
  };
}

export function checkFacets(facets) {
  return {
    type: "CHECK_FACETS",
    payload: facets,
  };
}

export function resetFacets() {
  return {
    type: "RESET_FACETS",
  };
}

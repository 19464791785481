import React, { Component } from "react";
import StyledText from "./StyledText";
import { withTranslation } from 'react-i18next';

import './CenteredText.scss';

class CenteredText extends Component {
  render() {
    const { content } = this.props;
    const { supportingText } = content;

    if (supportingText) {
      return <StyledText {...supportingText} />;
    } else {
      return null;
    }
  }
}

export default withTranslation()(CenteredText);

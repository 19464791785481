import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Container, Form, Grid, Input } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';
import { confirmPhoneNumber, displayMfaRegistration, verifyMfa } from "../../actions/loginActions";
import ModalError from "../ModalError/ModalError";
import { helpdeskLink } from "../../utils/ExternalLinks";


export class Response2FaScreen extends Component {
  state = {
    code: '',
  };

  handleRef = (component) => (this.ref = component);

  handleCodeChange = (e) => {
    this.setState({ code: e.target.value });
  }

  handleSubmit = (e) => {
    var message = { code: this.state.code }

    if (this.props.isUserLoggedIn) {
      this.props.dispatch(confirmPhoneNumber(message));
    } else {
      this.props.dispatch(verifyMfa(message));
    }
    
  }

  handlePhoneReentry = (e) => {
    this.props.dispatch(displayMfaRegistration());
  }

  
  displaySupportMessage = () => {
    const { t } = this.props;

    const helpDeskLink = (
      <a
        className="help-desk-link"
        href={helpdeskLink()}
        target="_blank"
        rel="noopener noreferrer"
      >
        { t('response2FaScreen.helpDeskLink') }
      </a>
    );


    if (!this.props.mfaEnabled) {
      return(
        <div>
          { t('response2FaScreen.didNotReceiveCode') } {this.helpDeskLink}
        </div>
      )
    } else {
      return(
        <div>
          { t('response2FaScreen.didNotReceiveCode') } <a onClick={this.handlePhoneReentry.bind(this)}>{ t('response2FaScreen.checkPhoneNumber') }</a> { t('response2FaScreen.needMoreAssistance') } {this.helpDeskLink}
        </div>
      )
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Container className="login-screen">
        <ModalError error={this.props.userError} />
        <Grid className="middle aligned">
          <Grid.Row>
            <Grid.Column width={16}>
              { t('response2FaScreen.messages.success') }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Field required>
            <label>{ t('response2FaScreen.fields.mfaCode') }:</label>

            <Input type="text"
                   name="code"
                   placeholder={ t('response2FaScreen.fields.mfaCode') }
                   value={this.state.code}
                   aria-label={ t('response2FaScreen.fields.mfaCode') }
                   onChange={this.handleCodeChange.bind(this)} />
            { this.displaySupportMessage() }
          </Form.Field>
          <Grid centered>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  className="primary"
                  aria-label={ t('response2FaScreen.buttons.submit') }
                  type="submit"
                >
                  { t('response2FaScreen.buttons.submit') }
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    )
  }
}

const mapStateToProps = store => {
  return {
    userError: store.login.userError,
    mfaEnabled: store.login.mfaEnabled,
    isUserLoggedIn: store.login.isUserLoggedIn,
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Response2FaScreen)));

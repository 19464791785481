function defaultState() {
  return {
    durationFilter: [],
    mediaFilter: [],
    subjectFilter: [],
    searchLoading: false,
    searchError: null,
    searchResults: [],
    searchMetadata: []
  }
}

function checkFacet(arr, facet) {
  if(arr.indexOf(facet) > -1) {
    arr = arr.filter(function(value){
      return value !== facet;
    });
  } else {
    arr.push(facet);
  }

  return arr.slice(0);
}

function paramToArray(arr) {
  if(arr) {
    if(Array.isArray(arr)) {
      return arr;
    }

    return [arr];
  }

  return [];
}

export default function reducer(state=defaultState(), action) {
  switch(action.type) {
    case 'RESET':
      return defaultState();
    case 'SEARCH_PENDING':
      return {...state, searchResults: [], searchMetadata: [], searchLoading: true, searchError: null};
    case 'SEARCH_REJECTED':
      return {...state, searchResults: [], searchMetadata: [], searchLoading: false, searchError: action.payload};
    case 'SEARCH_FULFILLED':
      return {...state, searchResults: action.payload.data.data, searchMetadata: action.payload.data.meta, searchLoading: false, searchError: null};
    case 'DURATION_CHECK_FACET':
      return {...state, durationFilter: checkFacet(state.durationFilter, action.payload)}
    case 'MEDIA_CHECK_FACET':
      return {...state, mediaFilter: checkFacet(state.mediaFilter, action.payload)}
    case 'SUBJECT_CHECK_FACET':
      return {...state, subjectFilter: checkFacet(state.subjectFilter, action.payload)}
    case 'CHECK_FACETS':
      return {
        ...state,
        subjectFilter: paramToArray(action.payload['subject']),
        durationFilter: paramToArray(action.payload['time']),
        mediaFilter: paramToArray(action.payload['media_types'])
      }
    case 'RESET_FACETS':
      return {...state, subjectFilter: [], durationFilter: [], mediaFilter: []}
    default:
      break;
  }

  return state;
}
import React, { Component } from "react";
import InfographicBlocks from "../InfographicBlocks";
import { withTranslation } from 'react-i18next';

class Section extends Component {
  render() {
    const { content } = this.props;

    return <InfographicBlocks blocks={content.blocks} />;
  }
}

export default withTranslation()(Section);
import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "semantic-ui-react";
import DynamicImage from "../HelperComponents/DynamicImage";

export class CardContent extends Component {
  render() {
    return(
      <Card
        className="card-grid-card"
      >
        <DynamicImage
          src={this.props.content.image}
          size={this.props.isMobile ? "large" : "small"}
          centered
          alt={this.props.content.image_alt}
        />
        <Card.Content className="card-grid-card-content">
          <Card.Header as="h3" className="card-grid-card-header">{this.props.content.title}</Card.Header>
          <Card.Description className="card-grid-card-description">
            {this.props.content.description}
          </Card.Description>
        </Card.Content>
      </Card>
    )
  }
}

const mapStateToProps = store => {
  return {
    isMobile: store.global.isMobile
  };
};

export default connect(mapStateToProps)(CardContent);

import React, { Component } from "react";
import { Grid, Progress } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

export class CardProgress extends Component {
  progressPercent = (complete, total) => {
    if (total === 0) {
      return 0
    } else {
      return (complete / total) * 100;
    }
  }

  eventsCompleted = ( complete, total ) => {
    const { t } = this.props;
    //const term = this.props.pathTerm.toLowerCase();
    //return complete + ` out of ` + total + ` ${term}s completed!`;
    return t('journeyProgress.message', { complete: complete, total: total })
  }

  render() {
    const complete = this.props.complete;
    const total = this.props.total;

    return (
      <Grid className="card-progress">
        <Grid.Row className="card-progress-bar-row">
          <Grid.Column>
            <Progress
              color="blue"
              size="tiny"
              percent={this.progressPercent(complete, total)}
              className="card-progress-bar"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="card-completed-row">
          <Grid.Column>
            <span>{ this.eventsCompleted(complete, total) }</span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

const mapStateToProps = store => {
  return {
    pathTerm: store.json.pathTerm
  };
};

export default withTranslation()(connect(mapStateToProps)(CardProgress));

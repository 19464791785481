import React, { Component } from "react";
import { Grid, Button } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';
//import "./RegistrationComplete.scss";
import DynamicImage from "../HelperComponents/DynamicImage";

class DemographicSuccess extends Component {
  render() {
    const { t } = this.props;
    
    return (
      <Grid id="registration-complete" centered columns={16}>
        <Grid.Row centered columns={10}>
          <DynamicImage
            className="logo-congrats"
            src="static_content/modal-graphic-2x.png"
            alt="MySBA Learning"
            centered
          />
        </Grid.Row>
        <Grid.Row centered columns={16}>
          <Grid.Column width={12} className="verify-your-email">
            <Button
              aria-label={ t('demographicSuccess.buttons.success') }
              className="primary"
              onClick={this.props.closeModal.bind(this)}
            >
              { t('demographicSuccess.buttons.success') }
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withTranslation()(DemographicSuccess);
import React, { Component } from "react";
import mime from "../../utils/MimeTypes";
import LearningEventPage from "./LearningEventPage";
import LearningEventDocument from "./LearningEventDocument";
import LearningEventVideo from "./LearningEventVideo";
import LearningEventPodcast from "./LearningEventPodcast";
import LearningEventQuiz from "./LearningEventQuiz/LearningEventQuiz";
import InfographicTemplate from "./InfographicTemplate/InfographicTemplate";

export default class LearningEventManager extends Component {
  getMimeType(url) {
    if (mime.lookup(url)) {
      return mime.lookup(url).split("/")[0];
    } else {
      return "NONE";
    }
  }

  eventFileManager(event) {
    const fileType = event.eventContent.mime_class;
    const url = event.eventContent.url;

    switch (fileType) {
      case "video":
        return <LearningEventVideo url={url} event={event} />;
      case "audio":
        return <LearningEventPodcast url={url} event={event} />;
      default:
        return null;
    }
  }

  eventURLManager(event) {
    const url = event.external_url;
    const mimeType = this.getMimeType(url);

    switch (mimeType) {
      case "video":
        return <LearningEventVideo url={url} event={event} />;
      case "audio":
        return <LearningEventPodcast url={url} event={event} />;
      default:
        return null;
    }
  }

  renderEventContentByType() {
    const { event, courseId, journeyHasCertificate } = this.props;

    switch (event.type) {
      case "Page":
        return <LearningEventPage
                  event={event}
                  courseId={courseId}
                  journeyHasCertificate={journeyHasCertificate}
                />;
      case "File":
        return this.eventFileManager(event);
      case "Quiz":
        return (
          <LearningEventQuiz event={event} courseId={this.props.courseId} />
        );
      case "InfographicTemplate":
        return (
          <InfographicTemplate
            pathId={event.id}
            blocks={event.template.blocks}
            body={event.eventContent.body}
          />
        );
      case "ExternalUrl":
        return this.eventURLManager(event);
      case "Document":
        return <LearningEventDocument event={event} />;
      default:
        return null;
    }
  }

  render() {
    return this.renderEventContentByType();
  }
}

import React from "react";
import { Button, Icon } from "semantic-ui-react";

const VolumeButton = props => {
  const ariaLabelText = props.muted ? "un-mute" : "mute";
  return (
    <Button icon basic onClick={props.toggleMuted} aria-label={ariaLabelText}>
      {props.muted ? (
        <Icon name="volume off" size="big" inverted />
      ) : (
        <Icon name="volume up" size="big" inverted />
      )}
    </Button>
  );
};

export default VolumeButton;

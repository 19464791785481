import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enAscentTranslations } from './locales/en/Ascent/translations';
import { enLearningCenterTranslations } from './locales/en/Ascent/translations';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  debug: true,
  resources: {
    en: {
      translations: enAscentTranslations
    },
    es: {
      translations: require('./locales/es/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['en', 'es'];

export default i18n;
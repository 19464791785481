import React from "react";
import { Button, Grid, Icon, Modal } from 'semantic-ui-react'
import { connect } from "react-redux";
import { continueSession, logoutUserAndReset } from "../../actions/registrationActions";
import { withRouter } from "react-router-dom";
import FocusLock from "react-focus-lock";
import { toggleLogin } from "../../actions/navbarActions";

import "./TimeoutWarning.scss"
import DynamicImage from "../HelperComponents/DynamicImage";

export class TimeoutWarning extends React.Component {
  state = {
    visible: false,
    seconds: 0,
    interval: null,
    lastResetTime: null,
    autoLoggedOut: false
  };

  hideModal() {
    this.setState({visible: false})
  }

  showModal() {
    if (this.props.isUserLoggedIn) {
      this.setState({visible: true})
    }
  }

  componentDidMount() {
      this.reset();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isUserLoggedIn !== prevProps.isUserLoggedIn ||
      this.props.location !== prevProps.location
    ) {
      if (this.props.isUserLoggedIn) {
        this.reset();
      }
    }
  }

  logout = () => {
    this.props.dispatch(logoutUserAndReset(this.props.accessToken));
    this.setState({autoLoggedOut: true});
  }

  getClock() {
    return this.props.clock || window;
  }

  currentSeconds() {
    return (this.state.lastResetTime !== null) ? Math.floor(this.props.timeout - (new Date().getTime() - this.state.lastResetTime) / 1000) : this.props.timeout
  }

  decrement() {
    this.setState({seconds: this.currentSeconds()});
    if (this.state.seconds <= 0){
      this.getClock().clearInterval(this.state.interval);
      this.logout();
    }
  }

  clearTimer() {
    this.getClock().clearInterval(this.state.interval);
    this.setState({
      interval: null
    })
  }

  startTimer() {
    this.setState({
      seconds: this.props.timeout || 1800,
      interval: this.getClock().setInterval(this.decrement.bind(this), 1000)
    });
  }

  clearTimeout() {
    this.getClock().clearTimeout(this.state.timeout);
    this.setState({timeout: null});
  }

  setTimeout() {
    const warning = this.props.warning || 1740;
    this.setState({
      timeout: this.getClock().setTimeout(this.showModal.bind(this), warning * 1000),
      lastResetTime: new Date().getTime()
    });
  }

  reset = () => {
    this.props.dispatch(continueSession());
    this.setState({autoLoggedOut: false});
    this.hideModal();
    this.clearTimeout();
    this.clearTimer();

    if (this.props.isUserLoggedIn){
      this.setTimeout();
      this.startTimer();
    }
  }

  pad(number) {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  }

  warning() {
    const minutes = Math.floor(this.state.seconds / 60);
    const seconds = this.state.seconds % 60;
    return <React.Fragment>
      <p>Due to inactivity, you're about to be logged out of your session. Do you wish to continue working?</p>
      <h1 id="countdown">{this.pad(minutes)}:{this.pad(seconds)}</h1>
      <Grid centered>
        <Grid.Row centered columns={16}>
          <Grid.Column width={3}/>
          <Grid.Column width={5}>
            <Button aria-label="Log me out" id="logout-button" onClick={this.logout.bind(this)}>Log me out</Button>
          </Grid.Column>
          <Grid.Column width={6}>
            <Button aria-label="I'm still working" className="primary" onClick={this.reset.bind(this)}>I'm still working</Button>
          </Grid.Column>
          <Grid.Column width={2}/>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  }

  message() {
    return <Grid id="logged-out">
      <Grid.Row centered columns={2}>
        <Grid.Column>
          <DynamicImage id="timeout-warning-image" src='static_content/Warning.png' alt='Warning'/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered columns={2}>
        <Grid.Column>
          Please sign in to your ASCENT account to resume
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered columns={8}>
        <Grid.Column width={2}/>
        <Grid.Column centered width={2}>
          <Button aria-label="Login" className="primary" onClick={() => this.props.dispatch(toggleLogin(true))}>Login</Button>
        </Grid.Column>
        <Grid.Column width={4}/>
      </Grid.Row>
    </Grid>
  }

  loggedOutTitle() {
    return "Your session has expired.";
  }

  warningTitle() {
    return "Are you still there?";
  }

  render() {
    return <Modal open={this.state.visible} id="timeout-warning" size='tiny'>
             <FocusLock returnFocus={true} className="content">
               <Grid>
                <Grid.Row columns={16}>
                  <Grid.Column width={12}>
                    <h1>{this.state.autoLoggedOut ? this.loggedOutTitle() : this.warningTitle()}</h1>
                  </Grid.Column>
                  <Grid.Column width={4} textAlign="right">
                    <Button aria-label="Close" className="eli-modal-close-button" onClick={this.reset} icon><Icon link name='close' size='large'/></Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <hr/>
              {this.state.autoLoggedOut ? this.message() : this.warning()}
             </FocusLock>
            </Modal>
  }
}

const mapStateToProps = store => {
  return {
    isUserLoggedIn: store.login.isUserLoggedIn,
    accessToken: store.login.userData.access_token
  };
};

export default withRouter(connect(mapStateToProps)(TimeoutWarning));

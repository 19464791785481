export function reset() {
  return {
    type: "RESET",
  };
}

export function setMobile(isMobile) {
  return {
    type: "MOBILE",
    payload: isMobile,
  };
}

export function setTablet(isTablet) {
  return {
    type: "TABLET",
    payload: isTablet,
  };
}

export function externalSite(isModalOpen, externalUrl) {
  return {
    type: "EXTERNAL_SITE",
    payload: {
      isModalOpen: isModalOpen,
      externalUrl: externalUrl,
    },
  };
}

export function toggleDropdown(isDrawerOpen) {
  return {
    type: "DISABLE_SCROLL",
    payload: isDrawerOpen,
  };
}

const viewPorts = {
  smallMobile: {
    default: 300,
    mini: 35,
    tiny: 80,
    small: 150,
    medium: 300,
    large: 300,
    big: 300,
    huge: 300,
    massive: 300,
  },

  mobile: {
    default: 600,
    mini: 35,
    tiny: 80,
    small: 150,
    medium: 300,
    large: 450,
    big: 600,
    huge: 600,
    massive: 600,
  },

  tablet: {
    default: 960,
    mini: 35,
    tiny: 80,
    small: 150,
    medium: 300,
    large: 450,
    big: 600,
    huge: 800,
    massive: 960,
  },

  computer: {
    default: 1200,
    mini: 35,
    tiny: 80,
    small: 150,
    medium: 300,
    large: 450,
    big: 600,
    huge: 800,
    massive: 960,
  },

  largeScreen: {
    default: 1920,
    mini: 35,
    tiny: 80,
    small: 150,
    medium: 300,
    large: 450,
    big: 600,
    huge: 800,
    massive: 960,
  },

  wideScreen: {
    default: 1920,
    mini: 35,
    tiny: 80,
    small: 150,
    medium: 300,
    large: 450,
    big: 600,
    huge: 800,
    massive: 960,
  },
};

export function changeActiveImageViewPort() {
  if (window.innerWidth <= 319) {
    return setActiveImageViewPort(viewPorts.smallMobile);
  } else if (window.innerWidth <= 767) {
    return setActiveImageViewPort(viewPorts.mobile);
  } else if (window.innerWidth <= 991) {
    return setActiveImageViewPort(viewPorts.tablet);
  } else if (window.innerWidth <= 1199) {
    return setActiveImageViewPort(viewPorts.computer);
  } else if (window.innerWidth <= 1919) {
    return setActiveImageViewPort(viewPorts.largeScreen);
  } else {
    return setActiveImageViewPort(viewPorts.wideScreen);
  }
}

export function setActiveImageViewPort(activeViewPort) {
  return {
    type: "SET_ACTIVE_IMAGE_VIEWPORT",
    payload: activeViewPort,
  };
}

import React, { Component } from "react";
import { Message} from "semantic-ui-react";
import { helpdeskLink } from "../../utils/ExternalLinks";

import "./ModalError.scss"

export default class ModalError extends Component {

  helpDeskLink(text) {
    const helpDeskLink = (
      <a
        className="help-desk-link"
        href={helpdeskLink()}
        target="_blank"
        rel="noopener noreferrer"
      >
        Support Center
      </a>
    );

    if (text === "Account is disabled.") {
      return (
        <span>
          Please contact the {helpDeskLink} to re-enable your account.
        </span>
      );
    }
  }

  getErrorMessageIfNeeded() {
    if (this.props.error) {
      const text = this.props.error.join(" ");
      return (
        <div className="login-error-message">
          <Message negative>
            {text}
            {this.helpDeskLink(text)}
          </Message>
        </div>
      );
    }
  }

  render() {
    return(
      <div>
        {this.getErrorMessageIfNeeded()}
      </div>
    );
  } 
}
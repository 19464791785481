import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import SearchFacet from "./SearchFacet";

import { checkFacet, resetFacets } from "../../actions/searchActions"
import { staticTimeFilters, staticMediaFilters } from "./SearchFilterLabels"
// There is also a tag for 'key-takeaway' which is mapped to the conclusion paragraph in a Path
// this is currently not being surfaced on the search although it is tagged in the service layer

export class SearchFacets extends Component {

  constructor() {
    super();

    this.handleCheckFacet = this.handleCheckFacet.bind(this);
    this.handleApplyClick = this.handleApplyClick.bind(this);
  }

  handleCheckFacet(filter, facet) {
    this.props.dispatch(checkFacet(filter, facet));
  }

  handleApplyClick() {
    let params = queryString.parse(this.props.location.search);
    let searchTerm = params.searchTerm;

    let query = {
      searchTerm: searchTerm,
      media_types: this.props.mediaFilter,
      time: this.props.durationFilter,
      subject: this.props.subjectFilter
    }

    this.props.history.push("/search?" + queryString.stringify(query));
    this.props.handleToggle();
  }

  render() {
    return (
      <div id="search-facets">
        <div className="facets-header">
          <h2>Filter Options</h2>
        </div>

        <div className="div-hr ui divider" id="top-hr"/>
        <SearchFacet title="Media Type" filter="MEDIA" right-border checkedValues={this.props.mediaFilter} data={staticMediaFilters()} handleCheckFacet={this.handleCheckFacet} />
        <SearchFacet title="Duration" filter="DURATION" checkedValues={this.props.durationFilter} data={staticTimeFilters} handleCheckFacet={this.handleCheckFacet} />
        <div className="div-hr ui divider"/>

        <div className="facets-footer">
          <div className="div-hr ui divider" id="bottom-hr"/>

          <div id="footer-buttons">
            <Button onClick={() => this.props.dispatch(resetFacets())}>Reset</Button>
            <Button onClick={this.handleApplyClick} primary>Apply</Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    durationFilter: store.search.durationFilter,
    subjectFilter: store.search.subjectFilter,
    mediaFilter: store.search.mediaFilter
  };
};

export default withRouter(connect(mapStateToProps)(SearchFacets));

import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';

import './TitledModule.scss';

class TitledModule extends Component {
  getTitleRow() {
    if (!this.props.title) {
      return null;
    }
    return (
      <Grid.Row centered>
        <Grid.Column computer={16} mobile={14} textAlign="center">
          <Header as="h2">{this.props.title}</Header>
        </Grid.Column>
      </Grid.Row>
    );
  }

  getSubtitleRow() {
    if (!this.props.subtitle) {
      return null;
    }
    return (
      <Grid.Row centered>
        <Grid.Column width={14} textAlign="center">
          {this.props.subtitle}
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    return (
      <div className="titled-module">
        <Grid>
          { this.getTitleRow() }
          { this.getSubtitleRow() }
        </Grid>
        {this.props.children}
      </div>
    );
  }
}

export default TitledModule;

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Grid, Header, Icon, Modal } from "semantic-ui-react";
import FocusLock from "react-focus-lock";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import LoginScreen from "./LoginScreen";
import { toggleLoginModal } from "../../actions/loginActions";
import Enable2FaScreen from "./Enable2FaScreen";
import Response2FaScreen from "./Response2FaScreen";

import { verifyMfa, registerMfa, submitCredentials } from "../../actions/loginActions";

export class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    const ref = this.modalRef;
    if (ref && ref.current) {
      const node = ReactDOM.findDOMNode(ref.current);
      node.scrollIntoView({ block: "start" });
    }
  };

  modalContent = (type) => {
    switch(type) {
      case types.credentials:
        return <LoginScreen formSubmit={this.sendCredentials} />;
      case types.enable2fa:
        return <Enable2FaScreen formSubmit={this.enroll2fa} />;
      case types.response2fa:
        return <Response2FaScreen formSubmit={this.verify2fa} />;
      default:
        break;
    }
  }

  getTitle = (type) => {
    const { t } = this.props;

    switch(type) {
      case types.credentials:
        return t('loginModal.titles.welcome');
      case types.enable2fa:
        return this.getTwoFaTitle();
      case types.response2fa:
        return t('loginModal.titles.verifyCode');
      default:
        break;
    }
  }

  getTwoFaTitle = () => {
    const { t } = this.props;

    if (this.props.twoFaTitle !== undefined) {
      return this.props.twoFaTitle;
    } else {
      return t('loginModal.enable2Fa');
    }
  }

  sendCredentials = () => {
    this.props.dispatch(submitCredentials(this.props.credentials));
  }

  enroll2fa = () => {
    this.props.dispatch(registerMfa());
  }

  verify2fa = () => {
    this.props.dispatch(verifyMfa());
  }

  close = () => {
    if (this.props.stayOnScreen !== true) {
      this.props.history.push("/dashboard");  
    }
    
    this.props.dispatch(toggleLoginModal(false));
  }

  render() {
    const { t } = this.props;
    
    return (
      <Modal
        id="login-modal"
        open={this.props.open}
        closeOnDimmerClick={false}
        aria-modal="true"
        tabIndex="-1"
        size={"tiny"}
      >
        <Modal.Content>
          <div ref={this.modalRef}>
            <Grid>              
              <Grid.Row columns={16}>
                <Grid.Column width={12}>
                  <Header id="edit-profile-header">
                    {this.getTitle(this.props.type)}
                  </Header>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  <Button
                    className="eli-modal-close-button"
                    aria-label={ t('loginModal.buttons.close') }
                    onClick={this.close}
                    icon
                  >
                    <Icon link name="close" size="large" />
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <hr />
            <div
              onMouseDown={ e => e.preventDefault() }
              onClick={e => e.target.focus()}
            >
              <FocusLock returnFocus={true}>
                {this.modalContent(this.props.type)}
              </FocusLock>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    )
  }
}

const types = {
  credentials: "credentials",
  enable2fa: "enable2fa",
  response2fa: "response2fa",
};

LoginModal.propTypes = { type: PropTypes.oneOf(Object.keys(types)) };
LoginModal.types = types;

const mapStateToProps = (store) => {
  return {
    credentials: store.login.credentials,
    mfaEnrollment: store.login.mfaEnrollment,
    mfaVerification: store.login.mfaVerification,
  }
}

const Extended = withTranslation()(withRouter(connect(mapStateToProps)(LoginModal)));

Extended.propTypes = { type: PropTypes.oneOf(Object.keys(types)) };
Extended.types = types

export default Extended;

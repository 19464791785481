import React, { Component } from "react";
import { Button, Container, Divider } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';

import "../SignUpForm/SignUpForm.scss";

class DeleteSuccess extends Component {
  handleRef = (component) => (this.ref = component);

  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const { t } = this.props;

    return (
      <Container
        className="profile-modal-container"
        onKeyDown={this.handleKeyPress}
      >
        <div className="profile-modal-messaging">
          <p>
            <span className="profile-modal-emphasis">
              { t('deleteSuccess.paragraphOne') }
            </span>
          </p>

          <p>
            { t('deleteSuccess.paragraphTwo') }<br />
            { t('deleteSuccess.paragraphThree') }
          </p>
        </div>

        <Divider />
        <div className="profile-modal-button-container">
          <Button
            aria-label={ t('deleteSuccess.close') }
            className="profile-modal-button"
            size="large"
            onClick={this.props.close}
            primary
            content={ t('deleteSuccess.close') }
          />
        </div>
      </Container>
    );
  }
}

export default withTranslation()(DeleteSuccess);

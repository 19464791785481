import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getLatestUserProgress, getProgressForJourneys, getJourneys } from "../../actions/journeyActions";

import Journeys from "./Journeys";

const mapStateToProps = store => {
  return {
    isUserLoggedIn: store.login.isUserLoggedin,
    journeys: store.journey.journeys,
    journeysLoading: store.journey.journeysLoading,
    latestUserEnrollment: store.journey.latestUserEnrollment,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLatestUserProgress: () => dispatch(getLatestUserProgress()),
    getProgressForJourneys: () => { dispatch(getProgressForJourneys()) },
    getJourneys: () => { dispatch(getJourneys()) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Journeys));

export function navBarPathArrowDown() {
  return (dispatch, getState) => {
    return navBarPathNavigation(dispatch, getState(), incrementIndex);
  };
}

export function navBarPathArrowUp() {
  return (dispatch, getState) => {
    return navBarPathNavigation(dispatch, getState(), decrementIndex);
  };
}

function navBarPathNavigation(dispatch, state, indexDirection) {
  const { journeys } = state.journey;
  if (journeys) {
    const { activePathNavIndex } = state.accessibility.mainNav;

    const activeIndex = indexDirection(
      activePathNavIndex,
      journeys.length
    );

    const pathId = journeys[activeIndex].id;
    return dispatch(setPathNavActiveIndex(activeIndex, pathId));
  }
}

export function navBarTopicArrowDown() {
  return (dispatch, getState) => {
    return navBarTopicNavigation(dispatch, getState(), incrementIndex);
  };
}

export function navBarTopicArrowUp() {
  return (dispatch, getState) => {
    return navBarTopicNavigation(dispatch, getState(), decrementIndex);
  };
}

function navBarTopicNavigation(dispatch, state, indexDirection) {
  const { mainNav } = state.accessibility;
  const journeys = state.journey.journeys;
  const topics = journeys[mainNav.activePathNavIndex]
    ? journeys[mainNav.activePathNavIndex].excursions
    : [];
  const activeTopicNavIndex = state.accessibility.mainNav.activeTopicIndex;

  const activeIndex = indexDirection(activeTopicNavIndex, topics.length);
  const topicId = topics[activeIndex].id;
  return dispatch(setTopicNavActiveIndex(topicId, activeIndex));
}

export function navBarPathArrowRight() {
  return (dispatch, getState) => {
    const activeIndex = 0;
    const {
      activePathId,
      activePathNavIndex
    } = getState().accessibility.mainNav;

    const journeys = getState().journey.journeys;
    const topics = journeys[activePathNavIndex]
      ? journeys[activePathNavIndex].excursions
      : [];

    if (activePathId !== null && topics[activeIndex]) {
      return dispatch(
        setTopicNavActiveIndex(topics[activeIndex].id, activeIndex)
      );
    }
  };
}

export function setPathNavActiveIndex(activeIndex, activeId) {
  return {
    type: "SET_PATH_NAV_ACTIVE_INDEX",
    activeIndex,
    activeId
  };
}

export function resetMainNav() {
  return {
    type: "RESET_MAIN_NAV"
  };
}

export function resetTopicNavActiveIndex() {
  return {
    type: "RESET_TOPIC_NAV_ACTIVE_INDEX"
  };
}

export function setTopicNavActiveIndex(activeTopicId, activeIndex) {
  return {
    type: "SET_TOPIC_NAV_ACTIVE_INDEX",
    activeTopicId,
    activeIndex
  };
}

function incrementIndex(currentActiveIndex, arraylength) {
  let activeIndex = 0;

  if (currentActiveIndex !== null && currentActiveIndex + 1 < arraylength) {
    activeIndex = currentActiveIndex + 1;
  }

  return activeIndex;
}

function decrementIndex(currentActiveIndex, arraylength) {
  let activeIndex = arraylength - 1;

  if (currentActiveIndex !== null && currentActiveIndex - 1 >= 0) {
    activeIndex = currentActiveIndex - 1;
  }

  return activeIndex;
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Container, Form, Grid, Message } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2'
import { registerMfa } from "../../actions/loginActions";

import 'react-phone-input-2/lib/style.css'
import "./Enable2FaScreen.scss";

import ModalError from "../ModalError/ModalError";

export class Enable2FaScreen extends Component {
  state = {
    phone_number: ''
  };

  handleRef = component => (this.ref = component);

  handleOnChange(value, data, event, formattedValue) {
    this.setState({ phone_number: value })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => {
      this.props.dispatch(registerMfa({ phone_number: `+${this.state.phone_number}` }));
    }, 100);
  }

  componentDidMount() {
    if (this.ref) {
      this.ref.focus();
    }
  }

  submitEnabled() {
    return(
      this.state.phone_number.length > 10
    )
  }

  render() {
    const { t } = this.props;

    return (
      <Container>
        <ModalError error={this.props.userError} />
        <Grid className="middle aligned">
          <Grid.Row>
            <Grid.Column width={16}>
              { t('enable2FaScreen.instructions') }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Field required>
            <label>{ t('enable2FaScreen.fields.phoneNumber') }:</label>
            <PhoneInput
              name="phone_number"
              country="us"
              className="phone-input"
              onlyCountries={['us']}
              aria-label={ t('enable2FaScreen.fields.phoneNumber') }
              placeholder="(555) 555-5555"
              disableDropdown={true}
              onChange={this.handleOnChange.bind(this)}
            /> <br />
          </Form.Field>
          <Grid centered>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  className="primary"
                  aria-label="Sign In"
                  type="submit"
                  disabled={!this.submitEnabled()}
                >
                  { t('enable2FaScreen.buttons.confirm') }
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    )
  }
}

const mapStateToProps = store => {
  return {
    userError: store.login.userError,
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Enable2FaScreen)));
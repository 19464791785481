import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import MetaTags from "../SEO/MetaTags";
import { connect } from "react-redux";
import {
  getJourneys,
  getProgressForJourneys
} from "../../actions/journeyActions";
import CardContainer from "../CardContainer";
import JourneyResumeWrapper from "../JourneyResumeComponent/JourneyResumeWrapper";
import BreadcrumbHeader from "../BreadcrumbHeader/BreadcrumbHeader";
import { linkToDashboard } from "../../utils/InternalLinks";
import { Grid } from "semantic-ui-react";
import { toggleTouring } from "../../actions/touringActions";
import Cookies from "js-cookie";
import { isHomepageDisabled } from "../../utils/EnvironmentFunctions";

import "./Dashboard.scss";

export class Dashboard extends Component {
  componentDidMount() {
    this.props.getJourneys();
    if (this.props.isUserLoggedIn) {
      this.props.getProgressForJourneys();
    }
    if ((this.props.match && this.props.match.path === "/touring") ||
        !Cookies.get('touring')) {
      this.props.toggleTouring(true);
    }
  }

  render() {
    const { t, isMobile, journeysLoading, journeyProgress } = this.props;
    const journeys = this.props.journeys || [];
    const scrollToRef = React.createRef();
    const headerText = isHomepageDisabled() ? process.env.REACT_APP_SITE_NAME : t('dashboard');

    return (
      <div id="dashboard" className="dashboard">
        <MetaTags
          metaTitle={this.props.dashboardTitle}
          metaDescription={this.props.dashboardDescription}
          canonicalUrl={`${
            process.env.REACT_APP_SITE_ROOT
          }${linkToDashboard()}`}
        />
        <BreadcrumbHeader headerText={headerText} isMobile={isMobile} />
        <Grid id="dashboard-grid">
          <Grid.Row id="progress-row">
            <Grid.Column>
              <JourneyResumeWrapper scrollToRef={scrollToRef} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="dashboard-scroll-ref" ref={scrollToRef}>
          <CardContainer
            cardHeader={t(this.props.journeysTerm)}
            cardItems={journeys}
            progressItems={journeyProgress}
            loading={journeysLoading}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  console.log(store.json)
  return {
    isUserLoggedIn: store.login.isUserLoggedIn,
    journeys: store.journey.journeys,
    journeyProgress: store.journey.journeyProgress,
    journeysLoading: store.journey.journeysLoading,
    isMobile: store.global.isMobile,
    dashboardTitle: store.json.dashboardTitle,
    dashboardDescription: store.json.dashboardDescription,
    journeysTerm: store.json.journeysTerm
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getJourneys: () => dispatch(getJourneys()),
    getProgressForJourneys: () => dispatch(getProgressForJourneys()),
    toggleTouring: () => dispatch(toggleTouring(true))
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Dashboard));

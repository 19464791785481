import React, { Component } from "react";
import { Button } from "semantic-ui-react";

import "./ExpandCollapseText.scss";

class ExpandCollapseText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      readMore: false
    };
  }

  renderExpandableText() {
    const { readMore } = this.state;
    const { defaultTextLength } = this.props;
    let { text } = this.props;

    // Check if the text is larger than the default length needed for read
    // more button or show full text without button
    if (text.length > defaultTextLength) {
      //If we clicked read more show the full text,
      //if we clicked read less or default view trim the text
      text = readMore ? text : text.slice(0, defaultTextLength) + " ...";
      return (
        <React.Fragment>
          {text}
          {
            <Button
              className="read-more-button"
              onClick={() => this.readMoreToggle()}
              content={this.state.readMore ? "Read Less" : "Read More"}
            />
          }
        </React.Fragment>
      );
    } else {
      return text;
    }
  }

  readMoreToggle() {
    this.setState({ readMore: !this.state.readMore });
  }

  render() {
    return this.renderExpandableText();
  }
}

export default ExpandCollapseText;

import React, { Component } from "react";
import ContentBlock from "./ContentBlock";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

export class MobileDesktopContent extends Component {
  getContent() {
    const content = this.props.isMobile ? this.props.content.mobile : this.props.content.desktop;
    return <ContentBlock content={content}/>
  }
  render() {
    return <React.Fragment>
      {this.getContent()}
    </React.Fragment>
  }
}

const mapStateToProps = store => {
  return {
    isMobile: store.global.isMobile || store.global.isTablet,
  };
};

export default withRouter(connect(mapStateToProps)(MobileDesktopContent));
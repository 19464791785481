import { userClient } from "../utils/axiosClients";

export function toggleLoginModal(show) {
  return {
    type: "TOGGLE_LOGIN_MODAL",
    payload: { show },
  };
}

export function displayMfaRegistration() {
  return {
    type: "ENROLL_MFA"
  }
}

export function submitCredentials(credentials) {
  const { validated, errorMessages } = validationCheck(credentials);

  if (validated) {
    const url = "/session";
    return (dispatch, getState) => {
      return dispatch({
        type: "SUBMIT_CREDENTIALS",
        payload: userClient(getState()).post(url, credentials),
      });
    };
  } else {
    return {
      type: "SUBMIT_CREDENTIALS_VALIDATION_FAILED",
      error: errorMessages,
    };
  }

}

export function registerMfa(mfaEnrollment) {
  const url = "/enable_mfas";

  return(dispatch, getState) => {
    return dispatch({
      type: "ENROLL_MFA",
      payload: userClient(getState()).post(url ,mfaEnrollment)
    })
  }
}

export function verifyMfa(mfaVerification) {
  const url = "/mfa_responses";

  return(dispatch, getState) => {
    return dispatch({
      type: "VERIFY_MFA",
      payload: userClient(getState()).post(url ,mfaVerification)
    })
  }
}

export function confirmPhoneNumber(phoneConfirmation) {
  const url = "/phone_responses";

  return(dispatch, getState) => {
    return dispatch({
      type: "CONFIRM_PHONE_NUMBER",
      payload: userClient(getState()).put(url ,phoneConfirmation)
    })
  }
}

export function completeDemographics() {
  return {
    type: "LOGIN_COMPLETE_DEMOGRAPHICS",
  }
}

function validationCheck(userData) {
  const fields = ["password", "email"];
  let validated = true;
  let errorMessages = [];

  // Generate error msg based on field affected
  fields.forEach((element) => {
    if (!userData[element] || userData[element].trim() === "") {
      validated = false;
    }
  });

  if (!validated) {
    errorMessages.push("Please enter a valid email and/or password.");
  }
  return { validated, errorMessages };
}
import { userClient } from "../utils/axiosClients";

import { profileModalTypes } from "../components/Profile/ProfileModal";

export function getProfile() {
  const url = "/profile";

  return (dispatch, getState) => {
    dispatch({
      type: "GET_USER_PROFILE",
      payload: userClient(getState()).get(url),
    }).then(() => {
      dispatch(requestVerificationCode());
    });
  };
}

export function deleteUserAccount() {
  const url = "/delete_user";

  return (dispatch, getState) => {
    dispatch({
      type: "DELETE_USER_ACCOUNT",
      payload: userClient(getState()).delete(url),
    }).then(() => {
      dispatch(toggleProfile(true, profileModalTypes.deleteSuccess));
    });
  };
}

export function editProfile(userProfileData) {
  const { validated, errorMessages } = validationCheck(userProfileData);

  if (validated) {
    const url = "/profile";

    return (dispatch, getState) => {
      dispatch({
        type: "EDIT_USER_PROFILE",
        payload: userClient(getState()).put(url, userProfileData),
      }).then(() => {
        dispatch(requestVerificationCode());
      });
    };
  } else {
    return {
      type: "EDIT_PROFILE_VALIDATION_FAILED",
      error: errorMessages,
    };
  }
}

export function confirmEmail(userData) {
  const url = "/confirm_email_change";
  return (dispatch, getState) => {
    dispatch({
      type: "PROFILE_CONFIRM_EMAIL",
      payload: userClient(getState()).post(url, userData),
    });
  };
}

export function sendVerificationCode() {
  const url = "/verification_codes";

  return (dispatch, getState) => {
    dispatch({
      type: "SEND_VERIFICATION_CODE",
      payload: userClient(getState()).post(url, {}),
    });
  };
}

export function requestVerificationCode() {
  const url = "/verification_codes";

  return (dispatch, getState) => {
    dispatch({
      type: "REQUEST_VERIFICATION_CODE",
      payload: userClient(getState()).get(url),
    });
  };
}

export function toggleProfile(show, type) {
  return {
    type: "TOGGLE_PROFILE_MODAL",
    payload: { show, type },
  };
}

export function resetUserProfileErrors() {
  return {
    type: "RESET_USER_PROFILE_ERRORS",
  };
}

const humanize = (s) => {
  if (typeof s !== "string") {
    return "";
  } else {
    s = s.replace("_", " ");
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
};

function validationCheck(userProfileData) {
  const fields = ["first_name", "last_name", "zip_code"];
  let validated = true;
  let errorMessage = " can't be blank.";
  let errorMessages = [];

  // Generate error msg based on field affected
  fields.forEach((element) => {
    if (!userProfileData[element] || userProfileData[element].trim() === "") {
      errorMessages.push(humanize(element) + errorMessage);
      validated = false;
    } else if (
      element === "zip_code" &&
      !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(userProfileData[element])
    ) {
      errorMessages.push("Zip code field is not valid.");
      validated = false;
    }
  });

  return { validated, errorMessages };
}

import React, { Component } from "react";
import DynamicImage from "../HelperComponents/DynamicImage";

export default class ImageContent extends Component {
  render() {
    return <DynamicImage src={this.props.content.imageUrl}
                         alt={this.props.content.altText}
                         aria-describedby={this.props.content.describedBy}
                         size={this.props.size}
                         className={this.props.className}/>
  }
}
import React, { Component } from "react";
import ReactPlayer from "react-player";
import { Button, Grid, Icon } from "semantic-ui-react";
import VideoScrubber from "./VideoScrubber";
import VideoDuration from "./VideoDuration";
import PlayPauseButton from "./PlayPauseButton";
import VolumeButton from "./VolumeButton";
import VolumeSlider from "./VolumeSlider";
import FullscreenButton from "./FullscreenButton";
import { findDOMNode } from "react-dom";
import screenfull from "screenfull";
import "./LearningEventVideo.scss";
import {
  getCustomData,
  rewriteUrlWithCDN,
  rewriteUrlWithServiceHost,
} from "../../../utils/HelperFunctions";
import { completeLearningEvent } from "../../../actions/learningEventActions";
import { linkToVtt } from "../../../utils/InternalLinks";

export default class LearningEventVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      volume: 80,
      muted: false,
      pip: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbbackRate: 1.0,
      loop: false,
      enableCaptions: false,
      height: "100%",
      width: "100%",
      isFullscreen: false,
      isUserActive: false,
      hasStartedPlaying: false,
    };

    this.timeout = null;
    if (screenfull.enabled) {
      screenfull.on("change", () => {
        this.setState({ isFullscreen: screenfull.isFullscreen });
      });
    }
  }

  componentDidMount() {
    window.addEventListener("keydown", this.videoKeyDown);
  }

  componentDidUpdate(prevProps, prevState) {
    this.captionsToggle();
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.videoKeyDown);
  }

  playPause = () => {
    if (!this.state.hasStartedPlaying && !this.state.enableCaptions) {
      this.setCaptionTrackMode("hidden");
    }
    this.setState({ playing: !this.state.playing, hasStartedPlaying: true });
  };

  setVolume = (values) => {
    this.setState({ volume: values[0] });
  };

  toggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  onSliderChange = (values) => {
    this.setState({ seeking: true });
    this.setState({ played: parseFloat(values[0]) });
    this.player.seekTo(parseFloat(values[0]));
    this.setState({ seeking: false });
  };

  onProgress = (state) => {
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  onDuration = (duration) => {
    this.setState({ duration });
  };

  onClickFullscreen = () => {
    if (screenfull.enabled) {
      screenfull.toggle(findDOMNode(this));
    }
  };

  toggleCaptions = () => {
    if (this.captionTrackUrl() !== null) {
      this.setState({ enableCaptions: !this.state.enableCaptions });
    }
  };

  captionsToggle() {
    if (this.state.enableCaptions) {
      this.setCaptionTrackMode("showing");
    } else {
      this.setCaptionTrackMode("hidden");
    }
  }

  setCaptionTrackMode = (mode) => {
    const videoElement = document.querySelector("video");
    if (videoElement && videoElement.textTracks[0]) {
      videoElement.textTracks[0].mode = mode;
    }
  };

  captionTrackUrl = () => {
    const captionUrl = linkToVtt(this.props.event.id);
    return rewriteUrlWithServiceHost(captionUrl);
  };

  videoUrl = () => {
    const url = this.props.url
    return rewriteUrlWithCDN(url)
  }

  posterImageUrl = () => {
    return getCustomData(this.props.event, "video_preview_image", rewriteUrlWithCDN("static_content/static_video.png?width=1920"));
  }

  onEnded = () => {
    const { event, isUserLoggedIn, match } = this.props;

    if (isUserLoggedIn && !event.completed) {
      const { id: path_id, topicId: objective_id } = match.params;

      this.props.dispatch(
        completeLearningEvent(path_id, objective_id, event.id)
      );
    }
  };

  reactPlayerConfig = () => ({
    file: {
      tracks: this.trackArray(),
      attributes: {
        poster: this.posterImageUrl(),
        preload: "metadata",
      },
    },
  });

  trackArray = () => {
    return [
      {
        kind: "captions",
        src: this.captionTrackUrl(),
        srcLang: "en",
        mode: "hidden",
        default: true,
      },
    ];
  };

  ref = (player) => {
    this.player = player;
  };

  captionButton = () => {
    const ariaLabelText = this.captionButtonAriaLabel(
      this.captionTrackUrl(),
      this.state.enableCaptions
    );
    return (
      <Button
        icon
        basic
        disabled={this.captionTrackUrl() === null ? true : false}
        onClick={this.toggleCaptions}
        aria-label={ariaLabelText}
      >
        {this.state.enableCaptions === true ? (
          <Icon name="closed captioning" size="big" />
        ) : (
          <Icon name="closed captioning outline" size="big" />
        )}
      </Button>
    );
  };

  captionButtonAriaLabel(captionTrackUrl, enableCaptions) {
    if (captionTrackUrl) {
      const captionsToggleText = enableCaptions ? "disable" : "enable";
      return `${captionsToggleText} closed-captioning`;
    } else {
      return "closed-captioning is not available";
    }
  }

  playerWrapper() {
    const {
      pip,
      playing,
      volume,
      muted,
      playbackRate,
      loop,
      height,
      width,
    } = this.state;

    return (
      <Grid.Row className="video-player-wrapper" aria-label="video">
        <ReactPlayer
          ref={this.ref}
          url={this.videoUrl()}
          pip={pip}
          playing={playing}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume / 100}
          muted={muted}
          height={height}
          width={width}
          onProgress={this.onProgress}
          onDuration={this.onDuration}
          onEnded={this.onEnded}
          config={this.reactPlayerConfig()}
          onClick={this.playPause}
        />
      </Grid.Row>
    );
  }

  videoKeyDown = (_) => {
    this.setUserActive();
  };

  setUserActive() {
    if (this.state.isFullscreen) {
      this.setState({ isUserActive: true });

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setState({ isUserActive: false });
      }, 2000);
    }
  }

  stackedPlayerControls() {
    const {
      playing,
      muted,
      volume,
      played,
      duration,
      isUserActive,
    } = this.state;

    const isUserActiveClass = isUserActive ? "user-active " : "";

    return (
      <Grid
        className={`${isUserActiveClass}video-player-controls stacked-controls`}
      >
        <Grid.Row id="video-spacer-row" className="video-row">
          <Grid.Column stretched>
            <div className="video-duration-wrapper">
              <VideoDuration duration={duration} played={played} />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row id="video-scrubber-row" className="video-row">
          <Grid.Column mobile={16}>
            <VideoScrubber
              played={played}
              onSliderChange={this.onSliderChange}
              duration={duration}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row id="video-controls-row" className="video-row">
          <Grid.Column className="video-buttons-column">
            <div className="video-buttons-left">
              <PlayPauseButton playPause={this.playPause} playing={playing} />
              <VolumeButton toggleMuted={this.toggleMuted} muted={muted} />
              <VolumeSlider volume={volume} setVolume={this.setVolume} />
            </div>
            {this.rightVideoPlayerButtons()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  flattenedPlayerControls() {
    const {
      playing,
      muted,
      volume,
      played,
      duration,
      isUserActive,
    } = this.state;

    const isUserActiveClass = isUserActive ? "user-active " : "";
    return (
      <div
        className={`${isUserActiveClass}video-player-controls flat-controls`}
      >
        <div className="video-buttons-left">
          <PlayPauseButton playPause={this.playPause} playing={playing} />
          <VolumeButton toggleMuted={this.toggleMuted} muted={muted} />
        </div>
        <VolumeSlider volume={volume} setVolume={this.setVolume} />
        <div className="video-scrubber-wrapper">
          <VideoScrubber
            played={played}
            onSliderChange={this.onSliderChange}
            duration={duration}
          />
        </div>
        <div className="video-duration-wrapper">
          <VideoDuration duration={duration} played={played} />
        </div>
        {this.rightVideoPlayerButtons()}
      </div>
    );
  }

  rightVideoPlayerButtons() {
    if (screenfull.enabled) {
      return (
        <div className="video-buttons-right">
          {this.captionButton()}
          <FullscreenButton onClickFullscreen={this.onClickFullscreen} />
        </div>
      );
    } else {
      return <div className="video-buttons-right">{this.captionButton()}</div>;
    }
  }

  render() {
    const { isFullscreen } = this.state;
    const fullscreenClass = isFullscreen ? "fullscreen" : "";

    if (!this.props.isLoading) {
      return (
        <Grid
          id="video-player"
          className={`${fullscreenClass}`}
          onMouseMove={() => this.setUserActive()}
        >
          {this.playerWrapper()}
          {isFullscreen
            ? this.flattenedPlayerControls()
            : this.stackedPlayerControls()}
        </Grid>
      );
    } else {
      return null;
    }
  }
}
